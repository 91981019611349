import React from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo-white.svg'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { TiSocialFacebook } from 'react-icons/ti'
import { RiInstagramLine } from 'react-icons/ri'
import { TiSocialLinkedin } from 'react-icons/ti'
import { TiSocialTwitter } from 'react-icons/ti'

const Footer = ({ loggedIn }) => {
    const navigate = useNavigate();
    return (
        <div className='footer' >
            <div className='footer-wrap' style={{ display: 'flex', justifyContent: 'space-between', gap: '50px' }}>
                <div>
                    <img src={Logo} alt="logo" />
                </div>
                <div className='social-icon' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <a href="https://www.linkedin.com/company/ocular-manufacturing/" target="_blank" rel="noreferrer">
                        <TiSocialLinkedin
                            fontSize={30}
                            style={{ fill: '#fff', border: '1px solid #fff', borderRadius: '50%', padding: '5px' }}
                        />
                    </a>

                    <a href="https://instagram.com/ocularmanufacturing?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                        <RiInstagramLine
                            fontSize={25}
                            style={{ fill: '#fff', border: '1px solid #fff', borderRadius: '50%', padding: '8px' }}
                        />
                    </a>

                    <a href="https://www.facebook.com/Ocularmanufacturing?mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                        <TiSocialFacebook
                            fontSize={30}
                            style={{ fill: '#fff', border: '1px solid #fff', borderRadius: '50%', padding: '5px' }}
                        />
                    </a>

                    <a href="https://twitter.com/OcularManufachttps://twitter.com/OcularManufact1?s=20" target="_blank" rel="noreferrer">
                        <TiSocialTwitter
                            fontSize={30}
                            style={{ fill: '#fff', border: '1px solid #fff', borderRadius: '50%', padding: '5px' }}
                        />
                    </a>
                </div>
            </div>
            <div className="footer-main footer-wrap">
                <div >
                    <h3 style={{ cursor: 'pointer' }} onClick={() => navigate('/Services')}>Services</h3>
                    <p>On Demand Manufacturing</p>
                    <p>Contract Manufacturing</p>
                    <p onClick={() => !loggedIn ? navigate('/login') : navigate('/buyer/dashboard')}>Book My Machine</p>
                    <p>Spare Parts Purchase</p>
                </div>
                <div className="vertical-line-footer"></div>
                <div >
                    <h3>Company</h3>
                    <p onClick={() => navigate('/')}>Home</p>
                    <p onClick={() => navigate('/AboutUs')}>About Us</p>
                    <a target="_blank" rel="noreferrer" href="https://ocular-manufacturing-company-docs.s3.ap-south-1.amazonaws.com/Ocular+-+Privacy+Policy.pdf"><p>Privacy Policy</p> </a>
                    <a target="_blank" rel="noreferrer" href="https://ocular-manufacturing-company-docs.s3.ap-south-1.amazonaws.com/Ocular+-+Terms+%26+Conditions.pdf"><p>Terms & Conditions</p> </a>
                </div>
                <div className="vertical-line-footer"></div>
                <div>
                    <h3>Contact Us</h3>
                    <p><a href="tel:+917768007070">+91 77680 07070</a></p>
                    <p><a href="mailto:info@ocularmanufacturing.com">info@ocularmanufacturing.com</a></p>
                    <p>Sr No 2/3, 616/1, Kondhave Dhawade,<br /> Nr Khadkeshwar Mandir, Pune, <br />Maharashtra, India, 411023</p>
                </div>
                <div className="vertical-line-footer"></div>
                <div>
                    <h3>Subscribe</h3>
                    <div style={{ display: 'flex', alignItems: 'center' }} className='emailSubscribe'>
                        <input type="email" name="emailSubscribe" id="emailSubscribe" placeholder='Email address' />
                        <label htmlFor='emailSubscribe'><ArrowForwardOutlinedIcon /></label>
                    </div>
                    <h4>Become A Supplier</h4>
                    <p>Ⓒ {new Date().getFullYear()} Ocular Manufacturing <br />  Private Limited - All Rights Are <br /> Reserved</p>                </div>
            </div>
        </div >
    )
}

export default Footer

