import React, { useState } from 'react'
import '../../pages/BookMyMachineHome.css'
import { AiOutlineWhatsApp } from 'react-icons/ai';
import Qr from '../../assets/QRCode.png'
import axios from 'axios';
import Footer from '../layout/Footer';



const ContactUs = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.firstName.trim()) {
            errors.firstName = 'First Name is required';
        }
        if (!formData.lastName.trim()) {
            errors.lastName = 'Last Name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone Number is required';
        } else if (!isValidPhone(formData.phone)) {
            errors.phone = 'Invalid phone number';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            try {
                await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/contact-submit-form", formData);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setErrors({});
            } catch (error) {
                console.error('Error submitting form:', error);
                alert('Error submitting form. Please try again later.');
            }
        } else {
            console.log('Form validation failed');
        }
    };


    return (
        <>
            <div>
                <div className="home-head-container" style={{ position: 'relative', top: '100px' }}>
                    <h1 style={{ marginLeft: '15vw' }}>Experience the future of Manufacturing !</h1>
                </div>
                <div className='Main-contact-page'>
                    <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px' }}>
                        <h2>Chat to our team</h2>
                        <p>Our friendly team would love to hear from you!</p>
                    </div>

                    <div className='bookMyMachine-home-subContainer' style={{ marginTop: '50px' }}>
                        <form onSubmit={handleSubmit} className='contactUs-form'>
                            <div className="contactUs-flex">
                                <div className='input-container'>
                                    <label className="label" htmlFor="firstName">
                                        First Name<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        className='input-field'
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="First Name"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.firstName && <p>{errors.firstName}</p>}
                                </div>
                                <div className='input-container'>
                                    <label className="label" htmlFor="lastName">
                                        Last Name<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        className='input-field'
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Last Name"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.lastName && <p>{errors.lastName}</p>}
                                </div>
                            </div>

                            <div className="contactUs-flex">
                                <div className='input-container'>
                                    <label className="label" htmlFor="email">
                                        Email<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        className='input-field'
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.email && <p>{errors.email}</p>}
                                </div>

                                <div className='input-container'>
                                    <label className="label" htmlFor="phone">
                                        Phone Number<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        className='input-field'
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        placeholder="Phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.phone && <p>{errors.phone}</p>}
                                </div>
                            </div>


                            <div className='input-container' style={{ width: '102%' }}>
                                <label className="label" htmlFor="message">Message</label>
                                <textarea
                                    className='input-message input-field '
                                    id="message"
                                    name="message"
                                    placeholder="Message"
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </div>

                            <button className='btn-fill' style={{ marginLeft: '0' }} type="submit">Submit</button>
                        </form>
                    </div >

                    <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px' }}>
                        <h2>Our Locations</h2>
                        <div className='flex our-locations' style={{ justifyContent: 'space-between', width: '80%' }}>
                            <span>Narhe, Pune</span>
                            <span>Bhosari, Pune</span>
                            <span>Chakan, Pune</span>
                        </div>
                    </div>

                    <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px', marginBottom: '200px' }}>
                        <h2>We’re Hiring.</h2>
                        <p><a href="mailto:info@ocularmanufacturing.com">info@ocularmanufacturing.com</a></p>
                    </div>
                </div>


                <div className="social-section" style={{
                    background: 'radial-gradient(210.31% 78.87% at 50.00% 50.00%, #FFF 22.28%, #FBFBFB 40.60%, #EEEEEF 60.15%, #D9DADB 80.19%, #BCBEC0 100%)', textAlign: 'center', minWidth: '300px'
                }}>
                    <h2>Join Our Suppliers Network</h2>
                    <h5 className='flex' style={{ color: '#4AC959', fontSize: '25px', fontWeight: '400px' }}><AiOutlineWhatsApp fill='#4AC959' style={{ fontSize: '35px', width: '35px', height: '35px' }} />WhatsApp</h5>
                    <p style={{ maxWidth: '750px', margin: 'auto', minWidth: '300px' }}>Book My Machine Rental Hub: WhatsApp and Facebook group Open Community for Manufacturing Buyers & Suppliers. Simply post your requirements and get access to various suppliers with Spare machine capacities. </p>
                    <p style={{ color: '#4AC959', fontWeight: 'bold' }}> Free Signup Today!</p>
                    <img src={Qr} alt='qr' style={{ width: '150px' }} />
                </div>

                <Footer />
            </div >

        </>

    )
}

export default ContactUs
