import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Home.css'
import RequestParts from './components/services/RequestParts';
import RequestManufacturing from './components/services/RequestManufacturing';
import SearchSupplier from './components/services/SearchSupplier';
import ExpandReach from './components/services/ExpandReach';
import c1 from './assets/c1.png'
import c2 from './assets/c2.png'
import c3 from './assets/c3.png'
import c4 from './assets/c4.png'
import Qr from './assets/QRCode.png'
import { AiOutlineWhatsApp } from 'react-icons/ai';
import Footer from './components/layout/Footer';

export default function Home({setProfile}) {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios.get("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/loggedIn", { withCredentials: true })
      .then(res => {
        setLoggedIn(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <div>
        <div className='home' style={{ marginTop: '90px' }}>
          <div className="main-container">
            <div className="home-head-container" >
              <h1>Experience the future of Manufacturing !</h1>
            </div>
            <ExpandReach />
            <div className="service-section" style={{
              background: 'radial-gradient(210.31% 78.87% at 50.00% 50.00%, #FFF 22.28%, #FBFBFB 40.60%, #EEEEEF 60.15%, #D9DADB 80.19%, #BCBEC0 100%)'
            }}>
              <SearchSupplier loggedIn={loggedIn} setProfile={setProfile}/>
              <div className="sub-container" style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <RequestManufacturing />
                </div>
                <div className="vertical-line"></div>
                <div style={{ flex: 1 }}>
                  <RequestParts />
                </div>
              </div>
            </div>
            <div className='certificate-section'>
              <h2>Our Suppliers are Certified</h2>
              <div className='flex certificate-wrap'>
                <div>
                  <img src={c1} alt="avatar" style={{ width: "300px" }} />
                </div>
                <div>
                  <img src={c2} alt="avatar" style={{ width: "300px" }} />
                </div>
                <div>
                  <img src={c3} alt="avatar" style={{ width: "300px" }} />
                </div>
                <div>
                  <img src={c4} alt="avatar" style={{ width: "300px" }} />
                </div>
              </div>
            </div>
            <div className="social-section" style={{
              background: 'radial-gradient(210.31% 78.87% at 50.00% 50.00%, #FFF 22.28%, #FBFBFB 40.60%, #EEEEEF 60.15%, #D9DADB 80.19%, #BCBEC0 100%)', textAlign: 'center'
            }}>
              <h2>Join Our Suppliers Network</h2>
              <h5 className='flex' style={{ color: '#4AC959', fontSize: '25px', fontWeight: '400px' }}><AiOutlineWhatsApp fill='#4AC959' style={{ fontSize: '35px', width: '35px', height: '35px' }} />WhatsApp</h5>
              <p style={{ maxWidth: '750px', margin: 'auto', minWidth: '300px' }}>Book My Machine Rental Hub: WhatsApp and Facebook group Open Community for Manufacturing Buyers & Suppliers. Simply post your requirements and get access to various suppliers with Spare machine capacities. </p>
              <p style={{ color: '#4AC959', fontWeight: 'bold' }}> Free Signup Today!</p>
              <img src={Qr} alt='qr' style={{ width: '150px' }} />
            </div>
          </div>
          <Footer loggedIn={loggedIn} />
        </div>
    </div >
  );
}
