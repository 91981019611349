import React, { useState } from 'react'
import SearchMachine from '../../components/services/SearchMachine';
import ShowMachines from '../../components/buyer/ShowMachines';

const BuyerDashboard = () => {
  const [searchClicked, setSearchClicked] = useState(false)
  const [allMachines, setAllMachines] = useState([]);
  const [requiredMachineType, setRequiredMachineType] = useState('');
  const [selectedDate, setSelectedDate] = useState([]);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [filteredMachines, setFilteredMachines] = useState([]);

// Returning all machines that meets the required machineType, date, time, and availability criteria 
  const handleSearchFilter = () => {
    const fromTimeValue = new Date(fromTime).getHours();
    const toTimeValue = new Date(toTime).getHours();
    const fromDate = selectedDate[0];
    const toDate = selectedDate[1];

    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const fromDay = fromDate.getDate();

    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const toDay = toDate.getDate();

    setFilteredMachines(allMachines.filter((machineData) => {
      const availableFromDateTime = new Date(machineData.machine.availableFromDateTime);
      const availableToDateTime = new Date(machineData.machine.availableToDateTime);
      const availableFromTime = availableFromDateTime.getHours();
      const availableToTime = availableToDateTime.getHours();

      const availableFromYear = availableFromDateTime.getFullYear();
      const availableFromMonth = availableFromDateTime.getMonth();
      const availableFromDay = availableFromDateTime.getDate();

      const availableToYear = availableToDateTime.getFullYear();
      const availableToMonth = availableToDateTime.getMonth();
      const availableToDay = availableToDateTime.getDate();

      const availableMachineType = machineData.machine.machineType;
      const availabilityStatus = machineData.machine.availabilityStatus;

      const criteriaSatisfied =
        fromYear <= toYear &&
        fromMonth <= toMonth &&
        fromDay <= toDay &&
        fromYear >= availableFromYear &&
        fromMonth >= availableFromMonth &&
        fromDay >= availableFromDay &&
        toYear <= availableToYear &&
        toMonth <= availableToMonth &&
        toDay <= availableToDay &&
        fromTimeValue >= availableFromTime &&
        toTimeValue <= availableToTime &&
        availabilityStatus === 'Available' &&
        availableMachineType === requiredMachineType;

      return criteriaSatisfied;
    }));
  };


  return (
    <div className='right-container buyer-dashboard' style={{ position: 'relative', width: '80%', top: '0px', left: '20%' }}>
      <div style={{ marginLeft: '20px', marginBottom: '140px' }}>
        <SearchMachine profile={'buyer'} setSearchClicked={setSearchClicked} searchClicked={searchClicked} allMachines={allMachines} setAllMachines={setAllMachines} selectedDate={selectedDate} setSelectedDate={setSelectedDate} fromTime={fromTime} setFromTime={setFromTime} toTime={toTime} setToTime={setToTime} duration={duration} setDuration={setDuration} requiredMachineType={requiredMachineType} setRequiredMachineType={setRequiredMachineType} handleSearchFilter={handleSearchFilter} />
        {searchClicked &&
          < ShowMachines allMachines={filteredMachines} setAllMachines={setAllMachines} duration={duration} selectedDate={selectedDate} requiredMachineType={requiredMachineType} fromTime={fromTime} toTime={toTime} setSelectedDate={setSelectedDate} setFromTime={setFromTime} setToTime={setToTime} setDuration={setDuration} setRequiredMachineType={setRequiredMachineType} setSearchClicked={setSearchClicked}/>
        }
      </div>
    </div>
  )
}

export default BuyerDashboard
