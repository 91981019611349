import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import '../../App.css'

function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setShowButton(scrollTop > 250); // Show button when scrolling down 100 pixels
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {children}
      {showButton && (
        <button className="back-to-top" onClick={handleScrollToTop}>
            <KeyboardDoubleArrowUpOutlinedIcon style={{ fill: '#0D4C7A'}}/>
        </button>
      )}
    </div>
  );
}

export default ScrollToTop;
