import React, { useState } from 'react';
import Select from "react-select";
import attach from '../../assets/attach.svg'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const MachineForm = ({ formData, setFormData, machines, setMachines, handleEdit }) => {
  const [machineType, setMachineType] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [machineName, setMachineName] = useState("");
  const [machineCapacity, setMachineCapacity] = useState("");
  const [tolerance, setTolerance] = useState("");
  const [purchaseYear, setPurchaseYear] = useState("");
  const [machineCost, setMachineCost] = useState("");
  const [machineDetails, setMachineDetails] = useState("");
  const [machinePictures, setMachinePictures] = useState([]);


  const machineTypeOptions = [
    { value: '', label: 'Select a Type' },
    { value: 'Laser Cutting', label: 'Laser Cutting' },
    { value: 'CNC Milling', label: 'CNC Milling' },
    { value: 'CNC Turning', label: 'CNC Turning' },
    { value: 'CNC Bending', label: 'CNC Bending' },
    { value: 'Moulding', label: 'Moulding' },
    { value: '3D Printing', label: '3D Printing' },
    { value: 'Die Casting', label: 'Die Casting' },
    { value: 'EDM Wirecut', label: 'EDM Wirecut' },
    { value: 'Lathe', label: 'Lathe' },
    { value: 'EDM', label: 'EDM' },
  ];

  const purchaseYears = [];
  const currentYear = new Date().getFullYear();
  const startYear = 1900; 
  for (let i = currentYear; i >= startYear; i--) {
    purchaseYears.push(i);
  }

  const purchaseYearOptions = purchaseYears.map((purchaseYearOption) => ({
    value: purchaseYearOption,
    label: purchaseYearOption.toString()
  }));


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDropMachine = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const uploadedPictures = Array.from(files).filter(
      (file) => file.type === "image/png" || file.type === "image/jpeg"
    );
    setMachinePictures(uploadedPictures);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const uploadedPictures = Array.from(files).filter(
      (file) => file.type === "image/png" || file.type === "image/jpeg"
    );
    setMachinePictures(uploadedPictures);

    // Reset the input field value if no images are present
    if (machinePictures.length === 0) {
      e.target.value = "";
    }
  };

  const removeImage = (index, event) => {
    event.preventDefault();
    const updatedPictures = [...machinePictures];
    updatedPictures.splice(index, 1);
    setMachinePictures(updatedPictures);
  };


  // Render uploaded pictures
  const renderMachinePictures = () => {
    return machinePictures.map((picture, index) => (
      <div key={index} className="image-container">
        <button className="remove-button" onClick={(e) => removeImage(index, e)}>
          <CloseRoundedIcon />
        </button>
        <img
          src={URL.createObjectURL(picture)}
          alt={`${index + 1}`}
          style={{ width: '100px', height: '100px' }}
        />
      </div>
    ));
  };

  const handleAddMachine = (event) => {
    event.preventDefault();

    // Create a new machine object with the current form data
    const newMachine = {
      machineType,
      machineModel,
      machineName,
      machineCapacity,
      tolerance,
      purchaseYear,
      machineCost,
      machineDetails,
      machinePictures
    };

    // Add the new machine to the list of machines
    setMachines([...machines, newMachine]);

    // Reset the form fields
    setMachineType("");
    setMachineModel("");
    setMachineName("");
    setMachineCapacity("");
    setTolerance("");
    setPurchaseYear("");
    setMachineCost("");
    setMachineDetails("");
    setMachinePictures([]);
  };

  return (
    <div>
      <h2>Add Machines</h2>
      <p>Fill Machine Details</p>
      <form className="addMachinesForm" style={{ marginTop: '6%' }} encType="multipart/form-data">
        <div className="flex detail-row">
          <div className="input-container">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}>Machine Type<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
            <Select
              className='machineselect'
              id='machineType'
              name='machineType'
              options={machineTypeOptions}
              onChange={(selectedOption) => setMachineType(selectedOption.value)}
              value={machineTypeOptions.find((option) => option.value === machineType)}
            />
          </div>
          <div className="input-container">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}>Machine Model<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
            <input
              className='input-field'
              type='text'
              placeholder='Text'
              name='machineModel'
              onChange={(e) => setMachineModel(e.target.value)}
              value={machineModel}
            />
          </div>
          <div className="input-container">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}>Machine Name<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
            <input
              className='input-field'
              type='text'
              placeholder='Text'
              name='machineName'
              onChange={(e) => setMachineName(e.target.value)}
              value={machineName}
            />
          </div>
        </div>

        <div className="flex detail-row">
          <div className="input-container">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}>Machine Capacity<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
            <input
              className='input-field'
              type='text'
              placeholder='a * b * c'
              name='machineCapacity'
              onChange={(e) => setMachineCapacity(e.target.value)}
              value={machineCapacity}
            />
          </div>
          <div className="input-container">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}>Tolerance<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
            <input
              className='input-field'
              type='text'
              placeholder='0.00'
              name='tolerance'
              onChange={(e) => setTolerance(e.target.value)}
              value={tolerance}
            />
          </div>
          <div className="input-container selectReact">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}>Purchase Year<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
            <Select
              className='machineselect'
              name='purchaseYear'
              options={purchaseYearOptions}
              onChange={(selectedOption) => setPurchaseYear(selectedOption.value)}
              value={purchaseYearOptions.find((option) => option.value === purchaseYear)}
            />
          </div>
        </div>

        <div className="flex detail-row">
          <div className="input-container">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}>Machine Cost(per hour cost)<span style={{ color: 'red', marginLeft: '5px' }}>*</span></label>
            <input
              className='input-field'
              type='number'
              placeholder='0'
              name='machineCost'
              onChange={(e) => setMachineCost(e.target.value)}
              value={machineCost}
            />
          </div>
          <div className="input-container machine-double-size">
            <label className="label" style={{ display: 'flex', alignItems: 'center' }}> Machine Details</label>
            <input
              className='input-field'
              type='text'
              placeholder='Text'
              name='machineDetails'
              onChange={(e) => setMachineDetails(e.target.value)}
              value={machineDetails}
            />
          </div>
        </div>

        <div className="flex detail-row " style={{ alignItems: "start" }}>
          <div className='flex'>
            <div className="input-container">
              <label className="label" htmlFor="Machine-image-input" style={{ display: 'flex', alignItems: 'center' }}>
                Upload Machine Pictures
              </label>
              <div
                className="profile-picture-container"
                style={{ width: '235px' }}
                onDragOver={handleDragOver}
                onDrop={handleDropMachine}
              >
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={handleFileUpload}
                  id="Machine-image-input"
                  className="profile-picture-input"
                  multiple
                  name='machinePictures'
                  style={{ display: "none" }} // Hide the file input element
                />
                <label htmlFor="Machine-image-input" className="profile-picture-placeholder flex" style={{ gap: '5px' }}>
                  <img src={attach} alt="Attached" />
                  <p>Or</p>
                  <p>Drag & Drop image (Png, Jpeg) here</p>
                </label>
              </div>
            </div>
          </div>
          <div className="input-container" style={{ justifyContent: 'space-between', marginLeft: '1.2%', width: '55%', minHeight: '15vh' }}>
            {(machinePictures.length !== 0) && <label className="label" htmlFor="Machine-image-input">Uploaded Pictures</label>}
            <div className="uploaded-pictures-container flex" style={{ gap: '2px', marginBottom: '5%' }}>
              {renderMachinePictures()}
            </div>
            <button className="btn-fill" style={{ margin: "1%", alignSelf: 'end', justifySelf: 'end' }} onClick={handleAddMachine}>Add Machine</button>
          </div>
        </div>
      </form>


    </div>
  )
}

export default MachineForm
