import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Navbar from './components/layout/Navbar';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import SupplierDashboard from './pages/Supplier/SupplierDashboard';
import PasswordReset from './components/auth/PasswordReset';
import PasswordResetConfirm from './components/auth/PasswordResetConfirm';
import MyProfile from './pages/Supplier/MyProfile';
import MyMachines from './pages/Supplier/MyMachines';
import BuyerDashboard from './pages/Buyer/BuyerDashboard';
import Bookings from './pages/Supplier/Bookings';
import ScrollToTop from './components/helper/ScrollToTop';
import BuyerBookingPage from './pages/Buyer/BuyerBookingPage';
import Notifications from './pages/Supplier/Notifications';
import BuyerNotifications from './pages/Buyer/BuyerNotifications';
import PaymentComplete from './pages/Buyer/PaymentComplete';
import ContactUs from './components/services/ContactUsPages';
import ContactUsPage from './pages/ContactUs';
import Profile from './components/auth/Profile';
import axios from 'axios';
import ServicesPage from './pages/ServicesPage';
import AboutUsPage from './pages/AboutUsPage';

export default function Router() {
  const [profile, setProfile] = useState('');
  const [checked, setChecked] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios.get("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/loggedIn", { credentials: 'include' })
      .then(res => {
        setLoggedIn(res.data);
        localStorage.setItem('loggedIn', res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const storedProfile = localStorage.getItem('profile');
    const storedLoggedIn = localStorage.getItem('loggedIn');
    if (storedProfile) {
      setProfile(storedProfile);
      storedProfile === 'supplier' ? setChecked(true) : setChecked(false)
    }
    if (storedLoggedIn) {
      setLoggedIn(storedLoggedIn);
    }
  }, [profile]);

  const updateProfile = (newProfile) => {
    setProfile(newProfile);
    localStorage.setItem('profile', newProfile);
  };


  return (
    <BrowserRouter>
      <ScrollToTop>
        <Navbar profile={profile} setProfile={updateProfile} checked={checked} setChecked={setChecked} />
        <Routes>
          <Route exact path="/" element={<Home setProfile={updateProfile} />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Services" element={<ServicesPage setProfile={updateProfile} />} />
          <Route path="/AboutUs" element={<AboutUsPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login setProfile={updateProfile} setChecked={setChecked} />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/password-reset-confirm" element={<PasswordResetConfirm />} />
          <Route path="/selectProfile" element={<Profile setProfile={updateProfile} setChecked={setChecked} />} />
          {loggedIn && <>
            <Route path="/buyer/dashboard" element={<BuyerDashboard />} />
            <Route path="/buyer/buyerProfile" element={<BuyerDashboard />} />
            <Route path="/buyer/buyerBooking" element={<BuyerBookingPage />} />
            <Route path="/payment-complete" element={<PaymentComplete />} />
            <Route path="/buyer/buyerNotifications" element={<BuyerNotifications />} />
            <Route path="/supplier/dashboard" element={<SupplierDashboard />} />
            <Route path="/supplier/Myprofile" element={<MyProfile />} />
            <Route path="/supplier/MyMachines" element={<MyMachines />} />
            <Route path="/supplier/Bookings" element={<Bookings />} />
            <Route path="/supplier/Notifications" element={<Notifications />} />
            <Route path="/ContactUsPage" element={<ContactUsPage />} />
          </>
          }
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
