import React from 'react';
import m1 from '../../assets/m1.png';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './BuyerBookingCard.css'
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';

const BuyerBookingCard = ({ allMachines, CalculateDistance, setShowAcceptModal, setAcceptedBooking }) => {
    const carouselSettings = {
        dots: true,
        dotsClass: 'slick-dots',
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const openAcceptModal = (bookingRequest) => {
        setShowAcceptModal(true);
        setAcceptedBooking(bookingRequest);
    }

    const handlePayCharges = (Amount, purpose, urlPrefix, bookingRequest) => {
        const userName = bookingRequest.booking.buyer.firstName + ' ' + bookingRequest.booking.buyer.lastName;
        const bookingId = bookingRequest.booking._id;

        const data = {
            purpose: purpose,
            amount: Amount,
            buyer_name: userName,
            email: bookingRequest.booking.buyer.email,
            phone: bookingRequest.booking.buyer.phone,
            booking_id: bookingId,
            redirect_url: `https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/${urlPrefix}/callBack?booking_id=${bookingId}`,
            webhook_url: '/webhook/',
        };
        axios.post('https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/payCharges/', data)
            .then(res => {
                window.location.href = res.data;

            })
            .catch((error) => console.log(error.response.data));

    }
    return (
        <div className='main-buyer-card-container'>
            {allMachines.map((bookingRequest, index) => {
                const fromDateTimeUTC = new Date(bookingRequest.booking.machine.availableFromDateTime);
                const toDateTimeUTC = new Date(bookingRequest.booking.machine.availableToDateTime);
                const options = { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' };
                const fromDateTimeIST = fromDateTimeUTC.toLocaleString('en-IN', options);
                const toDateTimeIST = toDateTimeUTC.toLocaleString('en-IN', options);

                return (

                    <div className='buyer-booking-card flex-wrap' key={index} >

                        {bookingRequest.booking.bookingStatus === 'accepted' && bookingRequest.booking.machine.machinePictures && bookingRequest.booking.machine.machinePictures.length > 0 ? (
                            <Slider {...carouselSettings}>
                                {bookingRequest.booking.machine.machinePictures.map((picture, pictureIndex) => (
                                    <div className='buyer-card-img-container flex-wrap' key={pictureIndex}>
                                        <img src={picture} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            bookingRequest.booking.bookingStatus === 'accepted' && (
                                <Slider {...carouselSettings}>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                </Slider>
                            )
                        )}

                        <div className='buyer-card-detail-container flex-wrap' style={{ padding: bookingRequest.booking.bookingStatus === 'pending' && '10px 18px ', width: '90%' }}>
                            <div className='detail-section-1'>
                                <div style={{ display: 'flex', gap: '2px', color: '#0D4C7A' }}>
                                    {bookingRequest.booking.bookingStatus === 'pending' && <p> Request -</p>}
                                    <h4 className='machineSubtype '>{bookingRequest.booking.machine.machineType ? bookingRequest.booking.machine.machineType : 'Machine Type'} </h4>
                                </div>
                                {bookingRequest.booking.bookingStatus === 'accepted' &&
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {[1, 2, 3, 4, 5].map((star) => {
                                            return (
                                                <span key={star}>
                                                    <StarIcon style={{ color: (star <= bookingRequest.averageRating) ? '#5ECE7B' : '#E5E0EB' }} />
                                                </span>
                                            );
                                        })}
                                        <span> ({bookingRequest.bookingCount}) </span>
                                    </div>
                                }
                            </div>
                            <div className='detail-section-2'>
                                <div className='prop-details'>
                                    <p className='propName'>Tolerance</p>
                                    <p className='propValue'>{bookingRequest.booking.machine.tolerance && bookingRequest.booking.machine.tolerance} mm Tolerance</p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Bed Size</p>
                                    <p className='propValue'>{bookingRequest.booking.machine.machineCapacity && bookingRequest.booking.machine.machineCapacity}mm</p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Available Time</p>
                                    <p className='propValue' > <b>
                                        {fromDateTimeIST} - {toDateTimeIST} ({bookingRequest.booking.machine.totalAvailableHrs && bookingRequest.booking.machine.totalAvailableHrs}hrs) </b>
                                    </p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Price</p>
                                    <p className='propValue'>{bookingRequest.booking.machine.machineCost && bookingRequest.booking.machine.machineCost} Rs/hr</p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Location</p>
                                    <p className='location-distance propValue'>
                                        <PinDropIcon style={{ fill: '#585858' }} />
                                        <p>{CalculateDistance(bookingRequest.coordinates)} km Away {bookingRequest.address}</p>
                                    </p>
                                </div>
                            </div>
                            {bookingRequest.booking.bookingStatus === 'accepted' ? <><p style={{ fontSize: '12px', margin: '5px auto', color: '#212121' }}>Bookings can be canceled within 2 hours of acceptance.</p>
                                <div className='prop-details' style={{ alignItems: 'center', justifyContent: 'space-between', gap: '12px' }}>
                                    <p> Cancellation </p>
                                    <p className='location-distance propValue'>
                                        {(bookingRequest.remainingTime !== 'NaN' && bookingRequest.remainingTime >= 0) ? bookingRequest.remainingTime + ' minutes left' : '0 minutes left'}
                                    </p>
                                    <button className='btn-card' onClick={() => openAcceptModal(bookingRequest)}>Continue to Pay</button>
                                </div>
                            </> :
                                <>
                                    <div className='prop-details'> <p > Auto cancellation in</p>
                                        <p className='location-distance propValue'>
                                            {bookingRequest.timeRemaining} Minutes
                                        </p>
                                    </div>
                                </>
                            }
                        </div>

                        {bookingRequest.booking.bookingStatus === 'pending' && <>
                            <div className="vertical-line-buyer"></div>
                            <div className='cancel-container'>
                                <div className='fetch' style={{ width: '170px' }}>
                                    <b>Stay Tuned!</b>
                                    <p>We are finding the best machine for you!</p>
                                </div>
                                <button className='btn-card cancel' onClick={() => handlePayCharges(10, 'Booking Cancellation Charges', 'cancel', bookingRequest)} style={{ position: 'relative', bottom: '-60px', right: '10px' }}>Cancel</button>
                            </div>
                        </>
                        }
                    </div>
                )
            })}

        </div>
    );
};

export default BuyerBookingCard;