import React, { useEffect } from 'react'
import m1 from '../../assets/m1.png'
import m2 from '../../assets/m2.png'
import m3 from '../../assets/m3.png'
import m4 from '../../assets/m4.png'
import m5 from '../../assets/m5.png'
import m6 from '../../assets/m6.png'
import m7 from '../../assets/m7.png'
import m8 from '../../assets/m8.png'
import './searchSupplier.css'
import DateDropdown from './DateDropdown'
import axios from 'axios';

const SearchMachine = ({ profile, setSearchClicked, searchClicked, setAllMachines, allMachines, requiredMachineType, setRequiredMachineType, fromTime, setFromTime, setToTime, toTime, selectedDate, setSelectedDate, duration, setDuration, handleSearchFilter }) => {

    const handleSearchMachine = (profile) => {
        if (profile === "buyer") {
            setSearchClicked(true);
            axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/allMachines')
                .then(response => {
                    setAllMachines(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            setSearchClicked(false);
        }
    };

    useEffect(() => {
        if (searchClicked && selectedDate.length > 0) {
            handleSearchFilter();
        }
        // eslint-disable-next-line 
    }, [allMachines, searchClicked, handleSearchFilter]);

    const handleRequiredMachine = (value) => {
        setRequiredMachineType(value);
    }

    return (
        <div className='searchSupplier'>
            <div style={{ display: 'flex', flexDirection: searchClicked ? 'row' : 'column', flexWrap: 'wrap', justifyContent: searchClicked ? 'center' : 'flex-start', gap: '10px' }}>
                <h3>Book My Machine</h3>
                <h5 style={{ padding: searchClicked ? '5px' : '0' }}>Book Available Spare Machine Capacity</h5>
            </div>

            <p>Select Machining Process and Get available Suppliers </p>

            <div className='grid-section' style={{ display: 'flex', gap: '10px', marginBottom: '20px', flexWrap: 'wrap' }}>
                <button className={requiredMachineType === 'Laser Cutting ' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('Laser Cutting')}> <img src={m1} alt="m1" style={{ height: "100px" }} /> </button>
                <button className={requiredMachineType === 'CNC Milling' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('CNC Milling')}> <img src={m2} alt="m2" style={{ height: "100px" }} /> </button>
                <button className={requiredMachineType === 'CNC Turning' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('CNC Turning')}> <img src={m3} alt="m3" style={{ height: "100px" }} /> </button>
                <button className={requiredMachineType === 'EDM' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('EDM')}> <img src={m4} alt="m4" style={{ height: "100px" }} /> </button>
                <button className={requiredMachineType === 'Moulding' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('Moulding')}> <img src={m5} alt="m5" style={{ height: "100px", width: "100px" }} /> </button>
                <button className={requiredMachineType === '3D Printing' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('3D Printing')}> <img src={m6} alt="m6" style={{ height: "100px" }} /> </button>
                <button className={requiredMachineType === 'Die Casting' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('Die Casting')}> <img src={m7} alt="m7" style={{ height: "100px" }} /> </button>
                <button className={requiredMachineType === 'EDM Wirecut' ? 'btn req-machine-btn req-active' : 'btn req-machine-btn'} onClick={() => handleRequiredMachine('EDM Wirecut')}> <img src={m8} alt="m8" style={{ height: "100px" }} /> </button>
            </div>
            <DateDropdown profile={profile} selectedDate={selectedDate} setSelectedDate={setSelectedDate} fromTime={fromTime} setFromTime={setFromTime} toTime={toTime} setToTime={setToTime} duration={duration} setDuration={setDuration} searchClicked={searchClicked} />
            <button className='main-btn' onClick={() => handleSearchMachine(profile)}>{(profile === 'buyer') ? 'Search Suppliers' : 'Search Machine'}</button>
        </div>
    )
}

export default SearchMachine
