import React, { useState, useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DateDropdown = ({ profile, selectedDate, setSelectedDate, fromTime, setFromTime, toTime, setToTime, duration, setDuration, searchClicked }) => {
  // eslint-disable-next-line
  const [recentDates, setRecentDates] = useState([
    new Date(),
    new Date(Date.now() + 86400000),
    new Date(Date.now() + 172800000),
    new Date(Date.now() + 259200000),
  ]);

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleDateChange = (dates) => {
    if (dates[0] && dates[1]) {
      const from_date = dates[0].toDate();
      const to_date = dates[1].toDate();
      setSelectedDate([from_date, to_date]);
    }
  };

  const handleRecentDateClick = (date) => {
    setSelectedDate([date, date]);
  };

  const dateOptions = {
    weekday: 'short',
    day: 'numeric',
  };

  const currentDate = new Date();
  const formattedCurrentDate = `${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}`;

  useEffect(() => {
    if (fromTime && toTime) {
      const diffInMilliseconds = toTime.getTime() - fromTime.getTime();
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
      setDuration(diffInHours.toFixed(2)); // Set duration to 2 decimal places
    }
  }, [fromTime, toTime, setDuration]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="buyer-date-time-container" style={{ display: 'flex', flexDirection: searchClicked ? 'row' : 'column', gap: searchClicked ? '10vw' : '30px', flexWrap: 'wrap' }}>
        <div>
          <div style={{ display: 'flex' }}>
            {!openDatePicker && !selectedDate ?
              (
                <div onClick={() => setOpenDatePicker(true)} style={{ color: '#222B45', display: 'flex', gap: '10px', justifyContent: 'center' }}>
                  Select Date <span className="formatted-date">{formattedCurrentDate}</span>
                  <ArrowDropDownOutlinedIcon />
                </div>
              )
              : (
                <> {selectedDate && <span style={{ width: '110px' }}>Selected Date </span>}
                  <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    placeholder="select from and to date"
                    format="DD/MM/YYYY"
                    minDate={new Date()} // assuming you want to disable past dates
                    range
                  />
                </>
              )}
          </div>
          <div className="date-btn-container">
            {recentDates.map((date) => (
              <div key={date.toISOString()}>
                <button className="date-btn" onClick={() => handleRecentDateClick(date)}>
                  {date.toLocaleDateString(undefined, dateOptions)}
                </button>
              </div>
            ))}
          </div>
        </div>

        {(profile === 'buyer') && <div style={{ display: 'flex', flexDirection: 'column' }}> <p style={{ margin: '0' }}> <b>Select Booking Time </b> </p>
          <div className="flex" style={{ gap: '20px', alignItems: 'start', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            <div className="label" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '130px' }}>
              <span style={{ width: '150px' }}>From Time </span>
              <TimePicker
                className="input-field"
                value={fromTime}
                onChange={(newTime) => setFromTime(newTime)}
              />
            </div>

            <div className="label" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '130px' }}>
              <span style={{ width: '150px' }}>To Time </span>
              <TimePicker
                className="input-field"
                value={toTime}
                onChange={(newTime) => setToTime(newTime)}
              />
            </div>
            <div className="label" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <span style={{ width: '150px' }}>Duration </span>
              <input
                className="input-field"
                type="text"
                placeholder="Hours"
                value={duration ? `${duration} hours` : ''}
                readOnly
                style={{ width: '110px', padding: '18px 10px' }}
              />
            </div>
          </div>
        </div>
        }
      </div>
    </LocalizationProvider>
  );
};

export default DateDropdown;
