import React, { useState } from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Login.css'
import { Link, useNavigate } from 'react-router-dom';

const Login = ({ setProfile, setChecked}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [rememberMe, setRememberMe] = useState(false);

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe)
  };

  const isValidEmail = (email) => {
    const regEx = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regEx.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};

    if (!email || !isValidEmail(email)) {
      errors.email = 'Please enter a valid email';
    }

    if (!password) {
      errors.password = 'Please enter your password';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const loginData = {
          email,
          password,
          rememberMe,
        };
        await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/login", loginData);
        setProfile('supplier')
        setChecked(true);
        navigate('/supplier/dashboard');
      } catch (err) {
        setErrorMessage(err.response.data.errorMessage);
      }
    }
  }

  return (
    <div className="login-page">
      <h1 className="heading">Log In</h1>
      <form onSubmit={handleSubmit} className='login-form'>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className="label">Email Address<span style={{ color: 'red' }}>*</span></label>
          <input
            className='input-field'
            type='text'
            placeholder='Email'
            name='email'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          {formErrors.email && <div style={{ color: 'red' }}>{formErrors.email}</div>}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className="label">Password<span style={{ color: 'red' }}>*</span></label>
          <input
            className='input-field'
            type={showPassword ? 'text' : 'password'}
            placeholder='Text'
            name='password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          {formErrors.password && <div style={{ color: 'red' }}>{formErrors.password}</div>}
          <button type="button" className="password-toggle-btn" onClick={() => setShowPassword(!showPassword)}>
            {!showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <div className='flex' style={{ justifyContent: 'space-between', width: "120%", marginTop: "-40px" }}>
          <div>
            <input
              type="checkbox"
              id="remember-checkbox"
              checked={rememberMe}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="remember-checkbox" className='agreement-checkbox'>
              Remember Me
            </label>
          </div>
          <p style={{ color: "blue", fontSize: "12px", cursor: "pointer" }}><Link to={`/password-reset?previous=${encodeURIComponent(window.location.pathname)}`}>Forgot Password?</Link></p>
        </div>
        {errorMessage && <div style={{ color: "red", fontSize: "13px" }}> {errorMessage}</div>}
        <input type='submit' value='Login' className='btn-fill' style={{ margin: '0 30px 0 70px' }} />
      </form>
      <Link to="/register" style={{ textDecoration: 'none' }}>
        <button className='btn-outline'>
          Sign Up
        </button>
      </Link>
    </div>
  )
}

export default Login
