import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from "axios";
import { useState } from "react";
import MessageModal from './MessageModal';


const PasswordResetConfirm = () => {
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);



  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setSuccessMessage("");
      return;
    }
    try {
      // Retrieve emailOrPhone from local storage
      const emailOrPhone = localStorage.getItem("emailOrPhone");
      // Verify otp from user's email or phone number
      const response = await axios.post('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/forget-password-verify', { otp, password: password, emailOrPhone }, {
        withCredentials: true,
      });
      const { message } = response.data;
      if (message === 'Password Reset Successful!') {
        openModal();
        // navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };


  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <>
      {showModal &&
        <MessageModal
          showModal={showModal}
          closeModal={closeModal}
          message={"Password Reset Successful !"}
          detail={""}
          navigateto={"/login"}
        />
      }
      <div className="login-page" style={{ justifyContent: 'start' }}>
        <h2>Reset Password</h2>
        <p className="reset-form-p" style={{ fontWeight: '400', textAlign: 'center', color: '#585858', width: '18%', fontSize: '12px' }}>
          Set the new password for your account so you can login and access ocular manufacturing</p>
        <form onSubmit={handleFormSubmit} className='reset-form'>
          <div>
            <label className="label">OTP</label>
            <input
              className='input-field'
              type="text"
              placeholder="Text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              style={{ marginBottom: '0' }}

            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="label">New Password</label>
            <input
              className='input-field'
              type={showNewPassword ? 'text' : 'password'}
              placeholder="Text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ marginBottom: '0' }}
            />
            <button type="button" className="password-toggle-btn" style={{ transform: 'translateY(-185%)translateX(60%)' }} onClick={() => setShowNewPassword(!showNewPassword)}>
              {!showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="label">Confirm Password</label>
            <input
              className='input-field'
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Text"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={{ marginBottom: '0' }}
            />
            <button type="button" className="password-toggle-btn" style={{ transform: 'translateY(-185%)translateX(60%)' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          {successMessage && (
            <div style={{ color: "green" }}>{successMessage}</div>
          )}
          <button type="submit" className='reset-form-btn' style={{ alignSelf: "center" }}>Reset Password</button>
        </form>
      </div>
    </>
  );
};

export default PasswordResetConfirm;
