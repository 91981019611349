import React from 'react'
import { useNavigate } from 'react-router-dom';
import './ExpandReach.css'
import expandReachImg from '../../assets/expandReach.png'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const ExpandReach = () => {
    const navigate = useNavigate();
    return (
        <div className='expandReach'>
            <div className='expandReachDetails'>
                <h2>Expand Your Reach</h2>
                <p>Ocular Manufacturing is a digital platform that is transforming the manufacturing industry with innovative solutions. We simplifies the manufacturing process, making it digital, stress-free, and user-friendly.</p>
                <button onClick={() => navigate('/AboutUs')}><ArrowCircleRightOutlinedIcon />Know More</button>
            </div>
            <div className="expandReachImg">
                <img src={expandReachImg} alt="imageExpand"></img>
            </div>
        </div>
    )
}

export default ExpandReach
