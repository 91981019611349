import React, { useState, useEffect } from "react";
import './AcceptRequestModal.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import StarIcon from '@mui/icons-material/Star';

const CarouselSlider = ({ acceptedBooking }) => {
    const { machinePictures } = acceptedBooking.booking.machine;

    const numImages = machinePictures.length;
    const slidesToShow = numImages >= 3 ? 3 : numImages;

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '100px',
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    let imagesToDisplay = machinePictures;

    if (numImages === 1 || numImages === 2) {
        imagesToDisplay = [...imagesToDisplay, machinePictures[0]];
    }

    return (
        <Slider {...settings}>
            {imagesToDisplay.map((image, index) => (
                <div key={index} style={{ width: '130px' }}>
                    <img src={image} alt="imgs" style={{ width: '80%', height: '125px' }} />
                </div>
            ))}
        </Slider>
    );
};

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: '#3e64ff', borderRadius: '50%' }}
            onClick={onClick}
        />
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: '#3e64ff', borderRadius: '50%' }}
            onClick={onClick}
        />
    );
};

const AcceptRequestModal = ({ acceptedBooking, CalculateDistance }) => {
    const fromDateTimeUTCSupplier = new Date(acceptedBooking.booking.machine.availableFromDateTime);
    const toDateTimeUTCSupplier = new Date(acceptedBooking.booking.machine.availableToDateTime);
    const options = { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' };
    const fromDateTimeISTSupplier = fromDateTimeUTCSupplier.toLocaleString('en-IN', options);
    const toDateTimeISTSupplier = toDateTimeUTCSupplier.toLocaleString('en-IN', options);
    const [recentDates, setRecentDates] = useState([]);

    const [totalDays, setTotalDays] = useState(0);

    // calculating total days for booking
    useEffect(() => {
        const fromDateISO = new Date(acceptedBooking.booking.fromDate);
        const toDateISO = new Date(acceptedBooking.booking.toDate);

        const fromDate = new Date(
            fromDateISO.getFullYear(),
            fromDateISO.getMonth(),
            fromDateISO.getDate()
        );
        const toDate = new Date(
            toDateISO.getFullYear(),
            toDateISO.getMonth(),
            toDateISO.getDate()
        );

        toDate.setDate(toDate.getDate() + 1);

        const timeDiff = toDate.getTime() - fromDate.getTime();
        const totalDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        setTotalDays(totalDays)
        // eslint-disable-next-line
    }, []);

    // calculating dates for booking
    useEffect(() => {
        const fromDate = new Date(acceptedBooking.booking.fromDate);
        const toDate = new Date(acceptedBooking.booking.toDate);

        const dates = [];
        const currentDate = new Date(fromDate);

        while (currentDate <= toDate) {
            const date = new Date(currentDate);
            dates.push(date);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        if (dates.length < 5) {
            if (dates.length === 0 || dates[0] >= fromDate) {
                const prevDate = new Date(dates[dates.length - 1]);
                const nextDate = new Date(prevDate.getTime() + 86400000);
                dates.unshift(new Date(fromDate.getTime() - 86400000));
                dates.push(nextDate);
            }
        }

        setRecentDates((prevDates) => {
            if (!areDatesEqual(prevDates, dates)) {
                return dates;
            }
            return prevDates;
        });
        // eslint-disable-next-line 
    }, []);


    const areDatesEqual = (dates1, dates2) => {
        if (dates1.length !== dates2.length) {
            return false;
        }
        for (let i = 0; i < dates1.length; i++) {
            if (dates1[i].getTime() !== dates2[i].getTime()) {
                return false;
            }
        }
        return true;
    };

    const dateOptions = {
        weekday: 'short',
        day: 'numeric',
    };

    //Calculating Duration of time
    const calculateDuration = (fromTime, toTime) => {
        const fromTimeObj = new Date(fromTime);
        const toTimeObj = new Date(toTime);

        const fromTimeMinutes = fromTimeObj.getHours() * 60 + fromTimeObj.getMinutes();
        const toTimeMinutes = toTimeObj.getHours() * 60 + toTimeObj.getMinutes();
        const durationMinutes = Math.max(toTimeMinutes - fromTimeMinutes, 0);
        const durationHours = Math.floor(durationMinutes / 60);
        return durationHours;
    };

    const perHourCost = acceptedBooking.booking.machine.machineCost;
    const bookingTimeHours = calculateDuration(acceptedBooking.booking.fromTime, acceptedBooking.booking.toTime)
    const totalBookingCost = bookingTimeHours * totalDays * perHourCost;
    const gstAmount = (totalBookingCost * 12) / 100
    const totalCost = totalBookingCost + gstAmount + 50;

    const handlePayCharges = (Amount, purpose, urlPrefix) => {
        const userName = acceptedBooking.booking.buyer.firstName + ' ' + acceptedBooking.booking.buyer.lastName;
        const bookingId = acceptedBooking.booking._id;

        const data = {
            purpose: purpose,
            amount: Amount,
            buyer_name: userName,
            email: acceptedBooking.booking.buyer.email,
            phone: acceptedBooking.booking.buyer.phone,
            booking_id: bookingId,
            redirect_url: `https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/${urlPrefix}/callBack?booking_id=${bookingId}`,
            webhook_url: '/webhook/',
        };
        axios.post('https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/payCharges/', data)
            .then(res => {
                window.location.href = res.data;

            })
            .catch((error) => console.log(error.response.data));

    }

    return (
        <div className='modal-full'>
            <h3 style={{ padding: '1% 5% 0%', color: '#585858' }}>Accepted Requests</h3>
            <div className='accept-main-flex'>
                <div className="details-side">
                    <h2>Accepted your Request</h2>
                    <div className="accepted-machine-details">
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Machine Model:</p>
                            <p className='propValue' style={{ color: '#0D4C7A', fontSize: '15px' }}> {acceptedBooking.booking.machine.machineModel}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Establishment Year:</p>
                            <p className='propValue' > {acceptedBooking.establishedYear}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Capacity:</p>
                            <p className='propValue' > {acceptedBooking.booking.machine.machineCapacity}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Accuracy:</p>
                            <p className='propValue' > {acceptedBooking.booking.machine.tolerance}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Address:</p>
                            <p className='propValue'> {acceptedBooking.booking.supplier.registeredAddress} ({CalculateDistance(acceptedBooking.coordinates)}kms away)
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Price:</p>
                            <p className='propValue' > {acceptedBooking.booking.machine.machineCost}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Available Time:</p>
                            <p className='propValue' > {fromDateTimeISTSupplier}: {toDateTimeISTSupplier}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Ratings:</p>
                            <p className='propValue' >  {[1, 2, 3, 4, 5].map((star) => {
                                return (
                                    <span key={star}>
                                        <StarIcon style={{ color: (star <= acceptedBooking.averageRating) ? '#5ECE7B' : '#E5E0EB' }} />
                                    </span>
                                );
                            })}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Reviews:</p>
                            <p className='propValue' > ({acceptedBooking.bookingCount})
                            </p>
                        </div>
                    </div>

                    <h3>{acceptedBooking.booking.machine.machineName} Photos</h3>
                    <CarouselSlider acceptedBooking={acceptedBooking} />
                </div>
                <div className="book-side">
                    <h2>Book Machine And Get Details</h2>
                    <h4>Date</h4>
                    <div className="date-btn-container">
                        {recentDates.map((date) => {
                            const isFromDate = date.toISOString() === acceptedBooking.booking.fromDate;
                            const isToDate = date.toISOString() === acceptedBooking.booking.toDate;
                            const isBetweenDates = date > new Date(acceptedBooking.booking.fromDate) && date < new Date(acceptedBooking.booking.toDate);

                            const btnClassName = isFromDate || isToDate || isBetweenDates ? "date-btn recent-date" : "date-btn";

                            return (
                                <div key={date.toISOString()}>
                                    <button className={btnClassName}>
                                        {date.toLocaleDateString(undefined, dateOptions)}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                    <h4>Booking Time</h4>
                    <div style={{ display: 'flex', gap: '30px' }}>
                        <div>
                            <p style={{ color: '#8A8894', fontSize: '13px' }}>From Time</p>
                            <p className="time-box">{new Date(acceptedBooking.booking.fromTime).toLocaleString('en-IN', options)}</p>
                        </div>
                        <div>
                            <p style={{ color: '#8A8894', fontSize: '13px' }}>To Time</p>
                            <p className="time-box">{new Date(acceptedBooking.booking.toTime).toLocaleString('en-IN', options)}</p>
                        </div>
                        <div>
                            <p style={{ color: '#8A8894', fontSize: '13px' }}>Duration</p>
                            <p className="time-box"> {calculateDuration(acceptedBooking.booking.fromTime, acceptedBooking.booking.toTime)}  hrs</p>
                        </div>
                    </div>
                    <h4>Charges Payable to Machine Owner</h4>
                    <div className="machine-Owner-charges-details">
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Booking Time:</p>
                            <p className='propValue' > {calculateDuration(acceptedBooking.booking.fromTime, acceptedBooking.booking.toTime)}  hrs
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>per Hr Cost:</p>
                            <p className='propValue' > {acceptedBooking.booking.machine.machineCost}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Gst:</p>
                            <p className='propValue' > {gstAmount}
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Booking Charges:</p>
                            <p className='propValue' > 50
                            </p>
                        </div>
                        <div className='accepted-machine-detail'>
                            <p className='propName'>Total Number of Days:</p>
                            <p className='propValue' > {totalDays}
                            </p>
                        </div>

                        <div className='accepted-machine-detail' style={{ paddingTop: '0', paddingBottom: '0' }}>
                            <p className='propName' style={{ fontSize: '12px', color: '#000' }}>Total Booking Time:</p>
                            <p className='propValue' style={{ fontSize: '12px', color: '#000' }}> Total Cost </p>
                        </div>

                        <div className='accepted-machine-detail' style={{ paddingTop: '0', paddingBottom: '0' }}>
                            <p className='propName' style={{ fontWeight: '600', color: '#000' }}>{bookingTimeHours}Hrs,{totalDays}Days</p>
                            <p className='propValue' style={{ fontWeight: '600', color: '#000' }}> {totalCost} Rs </p>
                        </div>
                    </div>
                    <div className='booking-charges'>
                        <div className='accepted-machine-detail'>
                            <p className='charge' style={{ fontWeight: '600', color: '#000' }}>Booking Charges</p>
                            <p className='charge' style={{ fontWeight: '600', color: '#000' }}> 112 Rs </p>
                        </div>
                        <button className="btn-fill" onClick={() => handlePayCharges(112, 'Booking Payment', 'Booking')}>Book</button>
                        <p style={{ fontSize: '12px', margin: '0' }}>Bookings can be canceled within 2 hours of acceptance.</p>
                        <p style={{ color: '#0D4C7A', fontWeight: '600', fontSize: '12px', margin: '0', cursor: 'pointer', marginBottom: '30px', }}>More about booking</p>
                        {(acceptedBooking.remainingTime !== 'NaN' && acceptedBooking.remainingTime >= 0) ?
                            <p style={{ color: '#0D4C7A', fontWeight: '600', fontSize: '12px', marginBottom: '30px', cursor: 'pointer' }} onClick={() => handlePayCharges(10, 'Booking Cancellation Charges', 'cancel')}>Cancel Booking (10 Rs cancellation charges )</p>
                            :
                            <p style={{ fontWeight: '600', fontSize: '12px', margin: '30px' }}>
                                You can no longer cancel this booking
                            </p>
                        }
                    </div>


                </div>
            </div>
        </div >
    )
}

export default AcceptRequestModal
