import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../auth/MessageModal.css'
import axios from 'axios';

const DualMessageModal = ({ showModal, closeModal, message, detail, navigateto, iconName, Booking }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        closeModal();
        navigate(navigateto);
    }

    const onDeclineConfirm = (booking) => {
        const bookingId = booking._id;
        axios.put(`https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/booking/${bookingId}/reject`)
            .then((response) => {
                closeModal();
                navigate(navigateto);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onAcceptConfirm = (booking) => {
        const bookingId = booking._id;
        const requestId = booking.requestId;
        const machineId = booking.machine;

        axios
            .put(`https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/booking/${bookingId}/accept`, { requestId, machineId })
            .then((response) => {
                closeModal();
                navigate(navigateto);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className={showModal ? 'modal display-block' : 'modal display-none'}>
            <div className='msg-modal-content'>
                <div style={{ padding: '5%' }}>
                    <span className='flex'>
                        {iconName}
                    </span>
                    <h3 style={{ textAlign: 'center' }}>{message}</h3>
                    <p style={{ fontSize: '13px', textAlign: 'center', color: '#0D4C7A' }}>
                        {detail}</p>
                    <div className='flex' style={{ justifyContent: 'space-between' }} >
                        <button className='btn-outline' style={{ marginTop: '2px' }} onClick={(e) => handleClick(e)}>
                            cancel
                        </button>
                        <button className='btn-fill' style={{ fontWeight: 'normal' }} onClick={() => message === 'Accept Request' ? onAcceptConfirm(Booking) : onDeclineConfirm(Booking)}>
                            confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DualMessageModal

