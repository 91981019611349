import React from 'react'
import { useNavigate } from 'react-router-dom';
import m1 from '../../assets/m1.png'
import m2 from '../../assets/m2.png'
import m3 from '../../assets/m3.png'
import m4 from '../../assets/m4.png'
import m5 from '../../assets/m5.png'
import m6 from '../../assets/m6.png'
import m7 from '../../assets/m7.png'
import m8 from '../../assets/m8.png'
import './searchSupplier.css'

const SearchSupplier = ({ loggedIn, setProfile }) => {
    const navigate = useNavigate();

    const handleBookMachine = () => {
        if (!loggedIn) {
            navigate('/login');
        } else {
            setProfile('buyer');
            navigate('/buyer/dashboard');
        }
    };

    return (
        <div className='searchSupplier'>
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }}>
                <h3>Book My Machine</h3>
                <p style={{ textAlign: 'center' }}>Select Machining Process <b> & Rent Spare Machine Capacity </b> </p>
            </div>


            <div className='grid-section' style={{ display: 'flex', gap: '10px', marginBottom: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>
                <div className='flex' style={{ flexWrap: 'wrap' }}>
                    <div>
                        <button className='btn req-machine-btn'> <img src={m1} alt="m1" style={{ height: "100px" }} /> </button>
                        <button className='btn req-machine-btn'> <img src={m2} alt="m2" style={{ height: "100px" }} /> </button>
                    </div>
                    <div>
                        <button className='btn req-machine-btn'> <img src={m3} alt="m3" style={{ height: "100px" }} /> </button>
                        <button className='btn req-machine-btn'> <img src={m4} alt="m4" style={{ height: "100px" }} /> </button>
                    </div>
                </div>
                <div className='flex' style={{ flexWrap: 'wrap' }}>
                    <div>
                        <button className='btn req-machine-btn'> <img src={m5} alt="m5" style={{ height: "100px", width: "100px" }} /> </button>
                        <button className='btn req-machine-btn'> <img src={m6} alt="m6" style={{ height: "100px" }} /> </button>
                    </div>
                    <div>
                        <button className='btn req-machine-btn'> <img src={m7} alt="m7" style={{ height: "100px" }} /> </button>
                        <button className='btn req-machine-btn'> <img src={m8} alt="m8" style={{ height: "100px" }} /> </button>
                    </div>
                </div>
            </div>
            <button className='main-btn' onClick={handleBookMachine}>Book My Machine </button>
        </div>
    )
}

export default SearchSupplier
