import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo-white.svg'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sidebar from '../layout/Sidebar';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import avtar from '../../assets/avtar.svg'
import axios from 'axios';
import './Navbar.css'

export default function Navbar({ profile, setProfile, checked, setChecked }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [showNav, setShowNav] = useState(false)
  const [profilePicture, setProfilePicture] = useState(null);
  const [fullName, setFullName] = useState("");
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleNavItems = () => {
    setShowNav(!showNav)
  }

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const res = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/loggedIn', { withCredentials: true });
        setLoggedIn(res.data);
        localStorage.setItem('loggedIn', res.data);
      } catch (err) {
        console.error(err);
      }
    };
    checkLoggedIn();
  }, [location, loggedIn]);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/details');
        const user = response.data;
        if (user) {
          if (user.profilePicture) {
            setProfilePicture(user.profilePicture);
          }
          if (user.firstName && user.lastName) {
            const name = user.firstName + " " + user.lastName
            setFullName(name);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [loggedIn, profile]);

  const handleMyProfile = () => {
    if (profile === "supplier")
      navigate("/supplier/MyProfile");
    else if (profile === "buyer")
      navigate("/buyer/buyerProfile");
  }

  return (
    <>
      {profile !== "" &&
        <div className="mobile-view" >
          <Sidebar className="mobile-view" checked={checked} setChecked={setChecked} profile={profile} setProfile={setProfile} menuOpen={menuOpen} setMenuOpen={setMenuOpen} mobileView={true} />
        </div>
      }
      <div className={profile !== "" ? 'navbar flex nav-logged' : 'navbar flex'} style={{ padding: "1% 4%", justifyContent: "space-between", background: "#0D4C7A" }}>
        <Link
          to="/"
          onClick={() => setShowNav(false)}
          style={{ textDecoration: "none", color: "#fff", margin: "2px 30px" }}
        >
          <img src={Logo} alt='Ocular Manufacturing' className='logo' />
        </Link>

        <div style={{ display: "flex", alignItems: "center", marginRight: '12px' }}>

          {!loggedIn && <> <div className="menu-icon" onClick={toggleNavItems} style={{ marginRight: '12px' }}>
            {!showNav ? <MenuOutlinedIcon fontSize='large' style={{ color: "#fff" }} />
              : <CloseRoundedIcon fontSize='large' style={{ color: "#fff" }} />
            }
          </div>
            <div className={`nav-elements  ${showNav && 'active'}`}>
              <div className="nav-links">
                <NavLink
                  to="/Services"
                  activeclassname="active-navlink"
                  onClick={() => setShowNav(false)}
                  style={{ textDecoration: "none", color: "#fff", margin: "2px 30px" }}
                >
                  Services
                </NavLink>
                <NavLink
                  to="/AboutUs"
                  activeclassname="active-navlink"
                  onClick={() => setShowNav(false)}
                  style={{ textDecoration: "none", color: "#fff", margin: "2px 30px" }}
                >
                  About Us
                </NavLink>
                <NavLink
                  activeclassname="active-navlink"
                  to="/ContactUs"
                  onClick={() => setShowNav(false)}
                  style={{ textDecoration: "none", color: "#fff", margin: "20px" }}
                >
                  Contact Us
                </NavLink>
                <NavLink
                  to="/register"
                  activeclassname="active-navlink"
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "#2c6d9b")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
                  onClick={() => setShowNav(false)}
                  style={{
                    textDecoration: "none",
                    background: "transparent",
                    color: "#fff",
                    padding: "10px 30px",
                    borderRadius: "5px",
                    margin: "2px 30px",
                    fontWeight: "700",
                  }}
                >
                  Sign Up
                </NavLink>
                <NavLink
                  to="/login"
                  activeclassname="active-navlink"
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "#2c6d9b")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
                  onClick={() => setShowNav(false)}
                  style={{
                    textDecoration: "none",
                    background: "transparent",
                    color: "#fff",
                    padding: "10px 30px",
                    borderRadius: "5px",
                    fontWeight: "700",
                  }}
                >
                  Login
                </NavLink>
              </div>

            </div>
          </>}
          {fullName && profile !== "" && <div className='flex' style={{ gap: '10px' }}>
            <button className='sidebar-profile-btn'
              onClick={handleMyProfile}
            >
              <div className="avtar">
                <Stack direction="row" spacing={2}>
                  {(profilePicture) ? <>
                    <Avatar src={profilePicture} style={{ background: '#0D4C7A', height: '40px', width: '40px' }} />
                  </> :
                    <Avatar
                      src={avtar} />
                  }
                </Stack>
              </div>
              <div className='desktop-view-name' style={{ color: '#fff' }}>{fullName}</div>
            </button>
          </div>
          }
        </div>
      </div>
      {profile !== "" && loggedIn && <>
        <div className="desktop-view" >
          <Sidebar checked={checked} setChecked={setChecked} profile={profile} setProfile={setProfile} menuOpen={menuOpen} setMenuOpen={setMenuOpen} mobileView={false} />
        </div>
      </>}
    </>
  )
}
