import React, { useState, useEffect } from 'react'
import "./Sidebar.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMenuFold } from 'react-icons/ai'
import { AiOutlineMenuUnfold } from 'react-icons/ai'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Switch from '@mui/material/Switch';
import axios from 'axios';


const Sidebar = ({ checked, setChecked, profile, setProfile, menuOpen, setMenuOpen, mobileView }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const handleSwitchProfile = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setProfile(isChecked ? "supplier" : "buyer");
    navigate(isChecked ? '/supplier/dashboard' : '/buyer/dashboard');
    mobileView && setMenuOpen(false);
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const res = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/loggedIn', { withCredentials: true });
        setLoggedIn(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    checkLoggedIn();
  }, [location, loggedIn]);

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/logout', { withCredentials: true });
      setLoggedIn(false);
      localStorage.setItem('loggedIn', false);
      setProfile("");
      navigate('/');
    } catch (err) {
      console.error('Error during logout:', err);
    }
  };

  useEffect(() => {
    if (profile === 'supplier') {
      const fetchNotifications = async () => {
        try {
          const currentSupplierResponse = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/current');
          const { supplierId } = currentSupplierResponse.data;
          const response = await axios.get(`https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/supplierNotifications/${supplierId}`);
          const notifications = response.data;
          setUnreadNotifications(notifications.filter(n => !n.read).length);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };

      fetchNotifications();

      const intervalId = setInterval(fetchNotifications, 5000);

      return () => clearInterval(intervalId);
    }
  }, [profile]);

  useEffect(() => {
    if (profile === 'buyer') {
      const fetchNotifications = async () => {
        try {
          const response = await axios.get(`https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/buyerNotification`);
          const notifications = response.data;
          setUnreadNotifications(notifications.filter(n => !n.read).length);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };

      fetchNotifications();

      const intervalId = setInterval(fetchNotifications, 5000);

      return () => clearInterval(intervalId);
    }
  }, [profile]);

  const ChangeMenu = () => {
    setMenuOpen(!menuOpen);
  }
  return (
    <>
      {
        !mobileView ?
          <div className='sidebar' style={{ width: (menuOpen) ? '300px' : '100px', alignItems: (menuOpen) ? 'flex-start' : 'center', zIndex: '200' }}>
            <div className="flex" style={{ width: '100%', margin: '25px 0' }}>
              <div className="switch-profile flex" style={{ display: (menuOpen) ? "flex" : "none" }}>
                Buyer
                <Switch
                  checked={checked}
                  onChange={handleSwitchProfile} />
                Supplier
              </div>
              <div className="menu" onClick={ChangeMenu}>
                {
                  menuOpen ?
                    <AiOutlineMenuFold style={{ fill: '#0D4C7A', fontSize: '1.8em' }} />
                    :
                    <AiOutlineMenuUnfold style={{ fill: '#0D4C7A', fontSize: '1.8em' }} />
                }
              </div>
            </div>
            <div className='main-menu' style={{ width: (menuOpen) ? '100%' : '40%' }}>
              {checked && <Link to="/supplier/dashboard"> <p><HomeOutlinedIcon style={{ fill: '#8d8f8e' }} />{menuOpen && <span>Home</span>} </p> </Link>}
              {checked && <Link to="/supplier/MyMachines"> <p><SettingsApplicationsIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>My Machine</span>}</p> </Link>}
              {checked && <Link to="/supplier/Bookings"> <p><CalendarTodayOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Bookings</span>}</p></Link>}

              {!checked && <Link to="/buyer/dashboard"> <p><HomeOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Home</span>}</p> </Link>}
              {!checked && <Link to="/buyer/buyerBooking"> <p><CalendarTodayOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Bookings</span>}</p> </Link>}

            </div>
            <hr style={{ width: '75%', opacity: '0.3' }} />

            <div className='sub-menu' style={{ width: (menuOpen) ? '100%' : '40%' }}>
              {checked && <Link to="/supplier/Notifications"> <p><NotificationsNoneOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Notifications <span className='notification'>{unreadNotifications}</span></span>} {!menuOpen && <span className='notification' style={{ marginLeft: '-18px', marginBottom: '25px' }}>{unreadNotifications}</span>}</p> </Link>}
              {checked && <Link to="/supplier/dashboard" onClick={(e) => e.preventDefault()} style={{ fontWeight: '100' }}> <p className='disabled' style={{ color: '#AAAAAA' }}><ChatBubbleOutlineOutlinedIcon style={{ fill: '#AAAAAA' }} />{menuOpen && <span>Messages</span>}</p> </Link>}
              {checked && <Link to="/supplier/dashboard" onClick={(e) => e.preventDefault()} style={{ fontWeight: '100' }}> <p className='disabled' style={{ color: '#AAAAAA' }}><CreditCardOutlinedIcon style={{ fill: '#AAAAAA' }} />{menuOpen && <span>Finance</span>}</p> </Link>}
              {!checked && <Link to="/buyer/BuyerNotifications"> <p><NotificationsNoneOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Notifications <span className='notification'>{unreadNotifications}</span></span>} {!menuOpen && <span className='notification' style={{ marginLeft: '-18px', marginBottom: '25px' }}>{unreadNotifications}</span>}</p> </Link>}
              {!checked && <Link to="/buyer/dashboard" onClick={(e) => e.preventDefault()} style={{ fontWeight: '100' }}> <p className='disabled' style={{ color: '#AAAAAA' }}><ChatBubbleOutlineOutlinedIcon style={{ fill: '#AAAAAA' }} /> {menuOpen && <span>Messages</span>} </p> </Link>}
              {!checked && <Link to="/buyer/dashboard" onClick={(e) => e.preventDefault()} style={{ fontWeight: '100' }}> <p className='disabled' style={{ color: '#AAAAAA' }}><CreditCardOutlinedIcon style={{ fill: '#AAAAAA' }} /> {menuOpen && <span>Finance</span>} </p> </Link>}
            </div>
            <hr style={{ width: '75%', opacity: '0.3' }} />

            <div className='sub-menu' style={{ width: (menuOpen) ? '100%' : '40%' }}>
              <Link to="/ContactUsPage"> <p><HelpOutlineOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Contact Us</span>} </p> </Link>
            </div>

            <div className='logout' style={{ width: (menuOpen) ? '100%' : '40%' }}>
              {loggedIn && (
                <a href='/#' style={{ cursor: "pointer", justifyConten: 'center', padding: (menuOpen) ? '5% 30%' : '40% 25%', marginLeft: (menuOpen) ? '-30%' : '-30%' }} onClick={handleLogout}>
                  <LogoutOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Log Out</span>}</a>
              )}

            </div>
          </div>

          :

          <div className='sidebar' style={{ width: (menuOpen) ? '350px' : '20px', alignItems: (menuOpen) ? 'flex-start' : 'center', zIndex: '200', position: 'absolute', top: '90px' }}>
            <div className="flex" style={{ width: menuOpen ? '100%' : '400%', padding: '28px 0', background: '#EBECEC' }}>
              <div className="switch-profile flex" style={{ display: (menuOpen) ? "flex" : "none" }}>
                Buyer
                <Switch
                  checked={checked}
                  onChange={handleSwitchProfile} />
                Supplier
              </div>
              <div className="menu" onClick={ChangeMenu} style={{ marginLeft: (menuOpen) ? '-2px' : '26px', padding: (menuOpen) ? ' 0 18px' : ' 0 ' }}>
                {
                  menuOpen ?
                    <AiOutlineMenuFold style={{ fill: '#0D4C7A', fontSize: '1.8em' }} />
                    :
                    <AiOutlineMenuUnfold style={{ fill: '#0D4C7A', fontSize: '1.8em' }} />
                }
              </div>
            </div>
            {(menuOpen) &&
              <>
                <div className='main-menu' style={{ width: '100%' }}>
                  {checked && (
                    <Link to="/supplier/dashboard" onClick={() => setMenuOpen(false)}>
                      <p><HomeOutlinedIcon style={{ fill: '#8d8f8e' }} /><span>Home</span> </p>
                    </Link>
                  )}
                  {checked && (
                    <Link to="/supplier/MyMachines" onClick={() => setMenuOpen(false)}>
                      <p><SettingsApplicationsIcon style={{ fill: '#8d8f8e' }} /> <span>My Machine</span></p>
                    </Link>
                  )}
                  {checked && (
                    <Link to="/supplier/Bookings" onClick={() => setMenuOpen(false)}>
                      <p><CalendarTodayOutlinedIcon style={{ fill: '#8d8f8e' }} /> <span>Bookings</span></p>
                    </Link>
                  )}

                  {!checked && (
                    <Link to="/buyer/dashboard" onClick={() => setMenuOpen(false)}>
                      <p><HomeOutlinedIcon style={{ fill: '#8d8f8e' }} /> <span>Home</span></p>
                    </Link>
                  )}
                  {!checked && (
                    <Link to="/buyer/buyerBooking" onClick={() => setMenuOpen(false)}>
                      <p><CalendarTodayOutlinedIcon style={{ fill: '#8d8f8e' }} /> <span>Bookings</span></p>
                    </Link>
                  )}
                </div>

                <hr style={{ width: '75%', opacity: '0.3' }} />

                <div className='sub-menu' style={{ width: (menuOpen) ? '100%' : '40%' }}>
                  {checked && (
                    <Link to="/supplier/Notifications" onClick={() => setMenuOpen(false)}>
                      <p><NotificationsNoneOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Notifications <span className='notification'>{unreadNotifications}</span></span>} </p>
                    </Link>
                  )}
                  {checked && (
                    <Link to="/supplier/dashboard" onClick={(e) => e.preventDefault()}>
                      <p style={{ color: '#AAAAAA' }}><ChatBubbleOutlineOutlinedIcon style={{ fill: '#AAAAAA' }} /><span>Messages</span></p>
                    </Link>
                  )}
                  {checked && (
                    <Link to="/supplier/dashboard" onClick={(e) => e.preventDefault()}>
                      <p style={{ color: '#AAAAAA' }}><CreditCardOutlinedIcon style={{ fill: '#AAAAAA' }} /> <span>Finance</span></p>
                    </Link>
                  )}

                  {!checked && (
                    <Link to="/buyer/buyerNotifications" onClick={() => setMenuOpen(false)}>
                      <p><NotificationsNoneOutlinedIcon style={{ fill: '#8d8f8e' }} /> {menuOpen && <span>Notifications <span className='notification'>{unreadNotifications}</span></span>} </p>
                    </Link>
                  )}
                  {!checked && (
                    <Link to="/buyer/dashboard" onClick={(e) => e.preventDefault()}>
                      <p style={{ color: '#AAAAAA' }}><ChatBubbleOutlineOutlinedIcon style={{ fill: '#AAAAAA' }} /> <span>Messages</span> </p>
                    </Link>
                  )}
                  {!checked && (
                    <Link to="/buyer/dashboard" onClick={(e) => e.preventDefault()}>
                      <p style={{ color: '#AAAAAA' }}><CreditCardOutlinedIcon style={{ fill: '#AAAAAA' }} /> <span>Finance</span> </p>
                    </Link>
                  )}
                </div>

                <hr style={{ width: '75%', opacity: '0.3' }} />

                <div className='sub-menu' style={{ width: (menuOpen) ? '100%' : '40%' }}>
                  <Link to="/ContactUsPage" onClick={() => setMenuOpen(false)}>
                    <p><HelpOutlineOutlinedIcon style={{ fill: '#8d8f8e' }} /> <span>Contact Us</span> </p>
                  </Link>
                </div>

                <div className='logout' style={{ width: (menuOpen) ? '100%' : '40%' }}>
                  {loggedIn && (
                    <a href='/#' style={{ cursor: "pointer", justifyConten: 'center', padding: (menuOpen) ? '5% 30%' : '40% 25%', marginLeft: (menuOpen) ? '-30%' : '-30%' }} onClick={handleLogout}>
                      <LogoutOutlinedIcon style={{ fill: '#8d8f8e' }} /> <span>Log Out</span></a>
                  )}
                </div>
              </>
            }
          </div>
      }

    </>

  )
}

export default Sidebar
