import React, { useState } from 'react';
import DetailsForm from './DetailsForm';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link } from 'react-router-dom';
import DeleteOtpModal from './DeleteOtpModal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EditDetailsModal = ({ bg, showModal, setShowModal }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState(new FormData());
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleSaveChanges = async () => {
        try {
            // Save personal details
            await axios.post('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/Editdetails', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setShowModal(false);
            navigate('/supplier/Myprofile');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorMessageValidate) {
                alert(error.response.data.errorMessageValidate);
            } else {
                console.error(error);
            }
        }
    };

    const openModal = () => {
        setShowDeleteModal(true);
    };

    const closeModal = () => {
        setShowDeleteModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <>
            {showDeleteModal &&
                <DeleteOtpModal
                    showModal={openModal}
                    closeModal={closeModal}
                    email={formData.get('email')}
                    phone={formData.get('phone')}
                />
            }
            <div>
                <div style={{ background: bg, width: '80%', margin: '2%', padding: '1% 3%' }}>
                    <CloseRoundedIcon onClick={handleCloseModal} style={{ float: 'right', padding: '2%' }} />
                    <DetailsForm
                        formData={formData} setFormData={setFormData}
                    />
                    <div className='btn-container' style={{ margin: '0 0% 5% 2%' }}>
                        <button className="cancel-button btn-danger-outline" onClick={openModal} style={{ margin: '0 10px 0 0' }}>Delete Account</button>
                        <Link to={`/password-reset?previous=${encodeURIComponent(window.location.pathname)}`}><button className="save-button btn-outline">Reset Password</button></Link>
                    </div>
                </div>
                <div className='flex' style={{ background: '#E7EDF2', width: '125%', position: 'absolute', left: '-25%', justifyContent: 'end', alignItems: 'center' }}>
                    <button className="cancel-button btn-outline" style={{ margin: "1%" }} onClick={handleCloseModal}>Cancel</button>
                    <button className="save-button btn-fill" style={{ margin: "1%" }} onClick={handleSaveChanges}>Save Changes</button>
                </div>
            </div>
        </>
    );
};

export default EditDetailsModal;
