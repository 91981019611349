import React, { useEffect } from "react";
import './AcceptRequestModal.css'
import m1 from '../../assets/m1.png';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';

const CarouselSlider = ({ acceptedBooking }) => {
  const { machinePictures } = acceptedBooking.booking.machine;

  const numImages = machinePictures.length;
  const slidesToShow = numImages >= 3 ? 3 : numImages;

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '100px',
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  let imagesToDisplay = machinePictures;

  if (numImages === 1 || numImages === 2) {
    imagesToDisplay = [...imagesToDisplay, machinePictures[0]];
  }

  return (
    <Slider {...settings}>
      {imagesToDisplay.map((image, index) => (
        <div key={index} style={{ width: '130px' }}>
          <img src={image} alt="imgs" style={{ width: '80%', height: '125px' }} />
        </div>
      ))}
    </Slider>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#3e64ff', borderRadius: '50%' }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#3e64ff', borderRadius: '50%' }}
      onClick={onClick}
    />
  );
};

const ActiveBookingModal = ({ setShowActiveModal, acceptedBooking, CalculateDistance, rating, setRating }) => {
  const fromDateTimeUTCSupplier = new Date(acceptedBooking.booking.machine.availableFromDateTime);
  const toDateTimeUTCSupplier = new Date(acceptedBooking.booking.machine.availableToDateTime);
  const options = { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' };
  const fromDateTimeISTSupplier = fromDateTimeUTCSupplier.toLocaleString('en-IN', options);
  const toDateTimeISTSupplier = toDateTimeUTCSupplier.toLocaleString('en-IN', options);

  const formatDate = (date) => {
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      weekday: 'short',
    };
    const formattedDate = new Date(date).toLocaleString('en-IN', options);

    const dayStyle = { fontSize: '10px', color: '#585858' };

    return (
      <div>
        {formattedDate.slice(4, -5)}{' '}
        <span style={dayStyle}>{formattedDate.slice(0, 3)}</span>{' '}
        <span style={dayStyle}>{formattedDate.slice(-4)}</span>
      </div>
    );
  };

  const formatTime = (time) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    };
    return new Date(time).toLocaleTimeString('en-US', options);
  };

  const calculateNumberOfDays = (fromDate, toDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const start = new Date(fromDate);
    const end = new Date(toDate);
    const timeDifference = Math.abs(end - start);
    const numberOfDays = Math.ceil(timeDifference / oneDay);
    return numberOfDays === 0 ? 1 : numberOfDays;
  }

  const calculateNumberOfHours = (duration, fromDate, toDate) => {
    const days = calculateNumberOfDays(fromDate, toDate);
    const Totalhours = days * duration;
    return Totalhours;
  }

  const calculateRent = (cost, duration, fromDate, toDate) => {
    const hours = calculateNumberOfHours(duration, fromDate, toDate);
    const TotalRent = hours * cost;
    return TotalRent;
  }

  useEffect(() => {
    const getRating = async () => {
      try {
        const response = await axios.get(`https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/getRating/${acceptedBooking.booking._id}`);
        setRating(response.data.rating);
      } catch (error) {
        console.error(error);
      }
    }

    getRating();
  }, [setRating, acceptedBooking.booking._id]);

  const handleClick = async (rate, acceptedBooking) => {
    try {
      const response = await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/updateRating", {
        bookingId: acceptedBooking.booking._id,
        newRating: rate
      });

      if (response.status === 200) {
        setRating(rate);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(acceptedBooking);

  return (
    <div className='modal-active'>
      <div className='accept-main-flex'>
        <div className="book-side">
          <h2>Booking Details</h2>
          <div className="active-booking-details">
            <div className="active-booking-section-1">
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexGrow: '0.8' }}>
                <img
                  className="active-booking-machine-picture"
                  src={m1}
                  alt="m1"
                  style={{ width: '40px', height: '40px', borderRadius: '100%' }}
                />
                <div className="active-booking-machine-name">
                  <p>{acceptedBooking.booking.machineType}</p>
                  <p>{acceptedBooking.booking.machineName}</p>
                </div>
              </div>
              <div className="active-booking-prop-details" style={{ flexGrow: '1' }}>
                <div >
                  <div className="active-booking-prop">
                    <p>Request Date</p>
                  </div>
                  <div className="active-booking-value">
                    <p>{formatDate(acceptedBooking.booking.dateMade)}</p>
                  </div>
                </div>
              </div>
              {acceptedBooking.booking.bookingStatus === 'active' && <BsFillCheckCircleFill fill="#FFC700" />}
            </div>
            <div className="active-booking-section-2">
              <div className="flex-grow">
                <div className="active-booking-prop-details">
                  <div style={{ width: '50%' }}>
                    <div className="active-booking-prop">
                      <p>From Date</p>
                    </div>
                    <div className="active-booking-value">
                      <p>{formatDate(acceptedBooking.booking.fromDate)}</p>
                    </div>
                  </div>
                  <div style={{ width: '50%' }}>
                    <div className="active-booking-prop">
                      <p>To Date</p>
                    </div>
                    <div className="active-booking-value">
                      <p>{formatDate(acceptedBooking.booking.toDate)}</p>
                    </div>
                  </div>
                </div>
                <div style={{ width: '50%', margin: 'auto 10px' }}>
                  <div className="active-booking-prop">
                    <p>Duration</p>
                  </div>
                  <div className="active-booking-value">
                    <p>{calculateNumberOfHours(acceptedBooking.booking.availability, acceptedBooking.booking.fromDate, acceptedBooking.booking.toDate)}Hrs
                      <span style={{ fontSize: '11px' }}> ({acceptedBooking.booking.availability} hrs/ {calculateNumberOfDays(acceptedBooking.booking.fromDate, acceptedBooking.booking.toDate)}days)</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="vertical-line-active"></div>

              <div className="flex-grow">
                <div className="active-booking-prop-details">
                  <div style={{ width: '50%' }}>
                    <div className="active-booking-prop">
                      <p>From Time</p>
                    </div>
                    <div className="active-booking-value">
                      <p>{formatTime(acceptedBooking.booking.fromTime)}</p>
                    </div>
                  </div>
                  <div style={{ width: '50%' }}>
                    <div className="active-booking-prop">
                      <p>To Time</p>
                    </div>
                    <div className="active-booking-value">
                      <p>{formatTime(acceptedBooking.booking.toTime)}</p>
                    </div>
                  </div>
                </div>
                <div style={{ width: '50%', margin: '0 10px' }}>
                  <div className="active-booking-prop">
                    <p>Rental Cost</p>
                  </div>
                  <div className="active-booking-value">
                    <p>{calculateRent(acceptedBooking.booking.rentalCost, acceptedBooking.booking.availability, acceptedBooking.booking.fromDate, acceptedBooking.booking.toDate)} /- Rs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>Contact Details</h2>
          <div className="active-booking-section-3">
            <div className="contact-booking-prop-details" >
              <div className="contact-booking-prop">
                <p>Name:</p>
              </div>
              <div className="contact-booking-value">
                <p>{acceptedBooking.booking.supplier.user.firstName} {acceptedBooking.booking.supplier.user.lastName}</p>
              </div>
            </div>
            <div className="contact-booking-prop-details" >
              <div className="contact-booking-prop">
                <p>Contact:</p>
              </div>
              <div className="contact-booking-value">
                <p>{acceptedBooking.booking.supplier.user.phone}</p>
              </div>
            </div>
            <div className="contact-booking-prop-details" >
              <div className="contact-booking-prop">
                <p>Address:</p>
              </div>
              <div className="contact-booking-value">
                <a href={`https://www.google.com/maps/place/${acceptedBooking.booking.supplier.registeredAddress}`} target="_blank" rel="noreferrer"> {acceptedBooking.booking.supplier.registeredAddress}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="details-side">
          <h2>Machine Details</h2>
          <CarouselSlider acceptedBooking={acceptedBooking} />

          <div className="accepted-machine-details">
            <div className='accepted-machine-detail'>
              <p className='propName'>Machine Model:</p>
              <p className='propValue' style={{ color: '#0D4C7A', fontSize: '15px' }}> {acceptedBooking.booking.machine.machineModel}
              </p>
            </div>
            <div className='accepted-machine-detail'>
              <p className='propName'>Establishment Year:</p>
              <p className='propValue' > {acceptedBooking.establishedYear}
              </p>
            </div>
            <div className='accepted-machine-detail'>
              <p className='propName'>Capacity:</p>
              <p className='propValue' > {acceptedBooking.booking.machine.machineCapacity}
              </p>
            </div>
            <div className='accepted-machine-detail'>
              <p className='propName'>Accuracy:</p>
              <p className='propValue' > {acceptedBooking.booking.machine.tolerance}
              </p>
            </div>
            <div className='accepted-machine-detail'>
              <p className='propName'>Address:</p>
              <p className='propValue'> {acceptedBooking.booking.supplier.registeredAddress} ({CalculateDistance(acceptedBooking.coordinates)}kms away)
              </p>
            </div>
            <div className='accepted-machine-detail'>
              <p className='propName'>Price:</p>
              <p className='propValue' > {acceptedBooking.booking.machine.machineCost}
              </p>
            </div>
          </div><div className='accepted-machine-detail'>
            <p className='propName'>Available Time:</p>
            <p className='propValue' > {fromDateTimeISTSupplier}: {toDateTimeISTSupplier}
            </p>
          </div>
          <div className='accepted-machine-detail'>
            <p className='propName'>Ratings:</p>
            <p className='propValue' >  {[1, 2, 3, 4, 5].map((star) => {
              return (
                <span key={star}>
                  <StarIcon style={{ color: (star <= acceptedBooking.averageRating) ? '#5ECE7B' : '#E5E0EB' }} />
                </span>
              );
            })}
            </p>
          </div>
          <div className='accepted-machine-detail'>
            <p className='propName'>Reviews:</p>
            <p className='propValue' > ({acceptedBooking.bookingCount})
            </p>
          </div>
        </div>
      </div >

      <div className="accepted-machine-detail" style={{ alignItems: 'center', display: 'flex', position: 'absolute', bottom: '90px', right: '20px' }}>
        <p style={{ padding: '12px 0px', margin: 0, color: '#585858', fontSize: '14px' }}>
          Give Rating
        </p>
        <div>
          {[1, 2, 3, 4, 5].map((star) => {
            return (
              <span key={star} onClick={() => handleClick(star, acceptedBooking)}>
                <StarIcon style={{ color: (star <= rating || star <= rating) ? '#5ECE7B' : '#E5E0EB' }} />
              </span>
            );
          })}
        </div>
        <button className='btn-fill' onClick={() => setShowActiveModal(false)}>Back</button>
      </div>
    </div>
  )
}

export default ActiveBookingModal
