import axios from "axios";
import { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import MessageModal from './MessageModal';

const PasswordReset = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send OTP to user's email or phone number
      const response = await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/otp-generate", {
        emailOrPhone: emailOrPhone,
      });
      const { message } = response.data;
      if (message === 'OTP sent') {
        localStorage.setItem("emailOrPhone", emailOrPhone);
        openModal();
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("wrong email or phone")
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const previousPage = new URLSearchParams(location.search).get("previous") || "/"; // Get the previous page URL from the query parameter or set it as "/" by default

  return (
    <>
      {showModal &&
        <MessageModal
          showModal={showModal}
          closeModal={closeModal}
          message={"Successful!"}
          detail={"OTP has been sent to your registered email and phone number."}
          navigateto={"/password-reset-confirm"}
        />
      }

      <div className="login-page" style={{ justifyContent: 'start' }}>
        <h2>Forgot Password</h2>
        <p className="reset-form-p " style={{ fontWeight: '400', textAlign: 'center', color: '#585858', width: '18%', fontSize: '12px' }}>
          Enter your phone number or email for the verification process. We will send a 6-digit code.</p>
        <form onSubmit={handleFormSubmit} className='reset-form'>
          <div>
            <label className="label">Email</label>
            <input
              className='input-field'
              type="text"
              placeholder="Enter Email"
              value={emailOrPhone}
              onChange={(e) => setEmailOrPhone(e.target.value)}
              required
            />
          </div>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <div className="flex" style={{ width: "120%", justifyContent: 'space-between' }}>
            <Link to={previousPage} style={{ textDecoration: 'none' }}>
              <button type="button" className='reset-form-btn'>cancel</button>
            </Link>
            <button type="submit" className='reset-form-btn'>Send OTP</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PasswordReset;
