import React from 'react'
import Router from './Router'
import axios from 'axios'
import './App.css'

axios.defaults.withCredentials = true;

function App() {
  return (
    <>
    <Router />
    </>
  )
}

export default App
