import React, { useState, useEffect } from 'react';
import noBooking from '../../assets/noBooking.svg';
import axios from 'axios';
import SupplierBookingCard from '../../components/supplier/SupplierBookingCard';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { Tab, Tabs } from '@mui/material';
import './css/Booking.css';

const Bookings = () => {
    const [supplierId, setSupplierId] = useState('');
    const [bookingRequests, setBookingRequests] = useState([]);
    const [selectedMachineType, setSelectedMachineType] = useState('');
    const [activeTab, setActiveTab] = useState(0);

    const machineTypeOptions = [
        { value: '', label: ' Filter By MachineType' },
        { value: 'Laser Cutting', label: ' Laser Cutting' },
        { value: 'CNC Milling', label: ' CNC Milling' },
        { value: 'CNC Turning', label: ' CNC Turning' },
        { value: 'CNC Bending', label: ' CNC Bending' },
        { value: 'Moulding', label: ' Moulding' },
        { value: '3D Printing', label: ' 3D Printing' },
        { value: 'Die Casting', label: ' Die Casting' },
        { value: 'EDM Wirecut', label: ' EDM Wirecut' },
        { value: 'Lathe', label: ' Lathe' },
        { value: 'EDM', label: ' EDM' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentSupplierResponse = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/current');
                setSupplierId(currentSupplierResponse.data.supplierId);
            } catch (error) {
                console.error('Error fetching supplier data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchBookingRequests = async () => {
            try {
                if (supplierId) {
                    const response = await axios.get(`https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/suppliers/${supplierId}/bookingRequests`);
                    setBookingRequests(response.data);
                }
            } catch (error) {
                console.error('Error fetching booking requests:', error);
            }
        };

        fetchBookingRequests();
    }, [supplierId, bookingRequests]);

    const handleMachineTypeChange = (selectedOption) => {
        setSelectedMachineType(selectedOption);
    };

    const filteredBookingRequests = selectedMachineType
        ? bookingRequests.filter((booking) => booking.machineType === selectedMachineType && booking.bookingStatus === 'pending')
        : bookingRequests.filter((booking) => booking.bookingStatus === 'pending')

    const filteredBookingHistory = selectedMachineType
        ? bookingRequests.filter((booking) => booking.machineType === selectedMachineType && (booking.bookingStatus === 'completed' || booking.bookingStatus === 'rejected'))
        : bookingRequests.filter((booking) => (booking.bookingStatus === 'completed' || booking.bookingStatus === 'rejected'))

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div className='right-container' style={{ position: 'relative', width: '80%', height: '100vh', top: '0px', left: '20%' }}>
            <h2 style={{ padding: '1% 5% 0%' }}>Booking Request</h2>
            {bookingRequests?.length !== 0 ? (
                <div style={{ padding: '0% 5%' }}>
                    <div>
                        <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth" style={{ width: '50%' }} className='tab-container'>
                            <Tab label="Received Booking Requests" style={{ textTransform: 'none', color: '#0D4C7A' }} />
                            <Tab label="Booking History" style={{ textTransform: 'none', color: '#0D4C7A' }} />
                        </Tabs>
                        <div>
                            {activeTab === 0 && (
                                <div style={{ paddingBottom: '5%' }} className='main-supplier-booking-container'>
                                    <div className='supplier-main-flex flex-wrap'>
                                        <div>
                                            <p> You have total {filteredBookingRequests.length} booking request </p>
                                            {selectedMachineType && <p> Machine Type Selected - <b>{selectedMachineType}</b></p>}
                                        </div>
                                        <div className='supplier-booking-filter-container'>
                                            <div className="custom-select">
                                                <select
                                                    value={selectedMachineType}
                                                    onChange={(e) => handleMachineTypeChange(e.target.value)}
                                                >
                                                    {machineTypeOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <FilterListOutlinedIcon className="custom-arrow" />
                                            </div>

                                        </div>
                                    </div>
                                    <SupplierBookingCard bookingRequests={filteredBookingRequests} Tab={"tab1"}/>
                                </div>
                            )}
                            {activeTab === 1 && (
                                <div style={{ paddingBottom: '5%' }} className='main-supplier-booking-container'>
                                    <div className='supplier-main-flex flex-wrap'>
                                        <div>
                                            <p> You have total {filteredBookingHistory.length} booking request </p>
                                            {selectedMachineType && <p> Machine Type Selected - <b>{selectedMachineType}</b></p>}
                                        </div>
                                        <div className='supplier-booking-filter-container'>
                                            <div className="custom-select">
                                                <select
                                                    value={selectedMachineType}
                                                    onChange={(e) => handleMachineTypeChange(e.target.value)}
                                                >
                                                    {machineTypeOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <FilterListOutlinedIcon className="custom-arrow" />
                                            </div>

                                        </div>
                                    </div>
                                    <SupplierBookingCard bookingRequests={filteredBookingHistory} Tab={"tab2"}/>
                                </div>)}
                        </div>
                    </div>


                </div>
            ) : (
                <div className="noMachinePage">
                    <p>You do not have any booking request</p>
                    <div className="flex" style={{ flexDirection: 'column', marginTop: '5%' }}>
                        <img src={noBooking} alt="noBooking" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Bookings;
