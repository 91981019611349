import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PeofileImg from '../../assets/profile.png';
import PeofileImg1 from '../../assets/profile1.png';
import AddDetailsModal from '../supplier/AddDetailsModal';
import '../supplier/AddDetailsModal.css';

const Profile = ({ setProfile, setChecked }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [hasSupplierSchema, setHasSupplierSchema] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios.get("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/loggedIn", { withCredentials: true })
      .then(res => {
        setLoggedIn(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/hasSupplierSchema');
        const user = response.data;
        if (user) {
          setHasSupplierSchema(true);
        }
      } catch (err) {
        console.error(err);
      }
    };
    setProfile("")
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const handleClick = (profilePassed) => {
    try {
      if (profilePassed === 'buyer') {
        setProfile('buyer')
        setChecked(false);
        navigate('/buyer/dashboard');
      } else if (profilePassed === 'supplier' && hasSupplierSchema) {
        setProfile('supplier')
        setChecked(true);
        navigate('/supplier/dashboard');
      } else {
        setShowModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {showModal && <AddDetailsModal bg={'#F8F8F8'} showModal={showModal} setShowModal={setShowModal} setProfile={setProfile} setChecked={setChecked} />}
      {loggedIn && <div className='flex profile-page' style={{ flexDirection: 'column', height: '80vh' }}>
        <h2>Select Your Profile</h2>
        <div
          className='flex profile-container'
          style={{ gap: '300px', margin: '50px 0', cursor: 'pointer', flexWrap: 'wrap' }}
        >
          <div onClick={() => handleClick('buyer')}>
            <img src={PeofileImg} alt='ProfileImg'></img>
            <h3
              className='flex'
              style={{
                color: '#0D4C7A',
                border: '1PX SOLID #0D4C7A',
                padding: '15px 0',
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
            >
              Buyer
            </h3>
          </div>
          <div onClick={() => handleClick('supplier')}>
            <img src={PeofileImg1} alt='ProfileImg'></img>
            <h3
              className='flex'
              style={{
                color: '#0D4C7A',
                border: '1PX SOLID #0D4C7A',
                padding: '15px 0',
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
            >
              Supplier
            </h3>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default Profile;
