import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import m1 from '../assets/m1.png'
import m2 from '../assets/m2.png'
import m3 from '../assets/m3.png'
import m4 from '../assets/m4.png'
import m5 from '../assets/m5.png'
import m6 from '../assets/m6.png'
import m7 from '../assets/m7.png'
import m8 from '../assets/m8.png'
import sample from '../assets/expandReach.png'
import '../components/services/searchSupplier.css'
import './BookMyMachineHome.css';
import Footer from '../components/layout/Footer'

const ServicesPage = ({ setProfile }) => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        axios.get("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/loggedIn", { withCredentials: true })
            .then(res => {
                setLoggedIn(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const navigate = useNavigate();

    const handleBookMachine = () => {
        if (!loggedIn) {
            navigate('/login');
        } else {
            setProfile('buyer');
            navigate('/buyer/dashboard');
        }
    };
    return (
        <div className='home'>
            <div style={{ position: 'relative', width: '90%', height: '100%', top: '40px', left: '10%', bottom: '200px', marginBottom: '200px' }}>
                <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px' }}>
                    <h2 style={{ marginBottom: '50px' }}>Our Services</h2>
                </div>

                <div className="service-container">
                    <div className='service-section-1'>
                        <div className='flex' style={{ flexWrap: 'wrap', width: 'fit-content' }}>
                            <div>
                                <button className='btn'> <img src={m1} alt="m1" style={{ height: "100px" }} /> </button>
                                <button className='btn'> <img src={m2} alt="m2" style={{ height: "100px" }} /> </button>
                            </div>
                            <div>
                                <button className='btn'> <img src={m3} alt="m3" style={{ height: "100px" }} /> </button>
                                <button className='btn'> <img src={m4} alt="m4" style={{ height: "100px" }} /> </button>
                            </div>
                        </div>
                        <div className='flex' style={{ flexWrap: 'wrap', width: 'fit-content' }}>
                            <div>
                                <button className='btn'> <img src={m5} alt="m5" style={{ height: "100px", width: "100px" }} /> </button>
                                <button className='btn'> <img src={m6} alt="m6" style={{ height: "100px" }} /> </button>
                            </div>
                            <div>
                                <button className='btn'> <img src={m7} alt="m7" style={{ height: "100px" }} /> </button>
                                <button className='btn'> <img src={m8} alt="m8" style={{ height: "100px" }} /> </button>
                            </div>
                        </div>
                    </div>

                    <div className='service-section-2'>
                        <h3 style={{ margin: '0' }}>Book My Machine</h3>
                        <p style={{ width: '85%' }}>If you need an extra machine capacity or have an urgent requirement to finish a job, look no where else but Book My Machine where you can either RENT spare machine capacity or BOOK machine space and earn extra either ways.</p>
                        <p>
                            1. Select Machining Process
                        </p>
                        <p>
                            2. Find spare machine capacities within your locality
                        </p>
                        <p>
                            3. Maximize machine utilization and reduce downtime
                        </p>
                        <button className='btn-fill' onClick={handleBookMachine}>Book My Machine </button>
                    </div>

                </div>
                <div class="thick-center-line"></div>

                <div className="service-container" style={{ marginTop: '50px' }}>
                    <div className="service-section-1">
                        <h3>On - Demand Manufacturing</h3>
                        <p style={{ width: '70%' }}>Do you want your manufacturing dream to become real without investing in infrastructure?</p>
                        <p>1. Give us the design</p>
                        <p>2. Accept suitable quote</p>
                        <p>3. Get Your Product</p>
                        <button className='btn-fill'>Upload Your Request</button>

                    </div>
                    <div className="service-section-2" style={{ alignItems: 'center' }}>
                        <img src={sample} alt="" className='service-img' style={{ width: '500px' }} />
                    </div>
                </div>

                <div class="thick-center-line"></div>

                <div className="service-container" style={{ marginTop: '50px' }}>
                    <div className="service-section-1" style={{ alignItems: 'center' }}>
                        <img src={sample} alt="" className='service-img' style={{ width: '500px' }} />
                    </div>
                    <div className="service-section-2">
                        <h3>Request for Spare Parts Purchase</h3>
                        <p style={{ width: '70%' }}>Here are the 4 simple steps to get your spare parts within time</p>
                        <p>1.Send Purchase Request</p>
                        <p>2. Get the Best Quotes from the best Suppliers</p>
                        <p>3. Accept the Quote</p>
                        <p>4. Get Spare parts delivered quickly</p>
                        <button className='btn-fill'>Upload your Requirements</button>

                    </div>
                </div>
            </div>

            <Footer loggedIn={loggedIn} />

        </div>

    )
}

export default ServicesPage
