import React from 'react'
import s1 from '../../assets/s1.png'
import s2 from '../../assets/s2.png'
import s3 from '../../assets/s3.png'
import './requestManufacturing.css'

const RequestParts = () => {
    return (
        <div className='requestManufacturing'>
            <h3>Industrial B2B Marketplace</h3>
            <h5>E-Commerce for industrial equipment and spare parts</h5>
            <div className="processes">
                <div className="process">
                    <div className='circular-div'>1</div>
                    <img src={s1} alt="avatar" style={{ width: "150px" }} />
                    <p>Send Request for <br /> Spare Parts  <br /> Purchase</p>
                </div>
                <div className="process">
                    <div className='circular-div'>2</div>
                    <img src={s2} alt="avatar" style={{ width: "150px" }} />
                    <p>Receive 3  <br />  Quotes from  <br />  Sellers</p>
                </div>
                <div className="process">
                    <div className='circular-div'>3</div>
                    <img src={s3} alt="avatar" style={{ width: "150px" }} />
                    <p>Accept Quote &  <br />  Enjoy Doorstep  <br />  Delivery</p>
                </div>
            </div>
            <button>Upload Your Requirement</button>
        </div>
    )
}

export default RequestParts
