import React, { useState, useEffect } from 'react';
import noMachine from '../../assets/noMachine.svg'
import noBooking from '../../assets/noBooking.svg';
import axios from 'axios';
import "./css/MyMachines.css"
import { useNavigate } from 'react-router-dom';
import AddMachinesModal from '../../components/supplier/AddMachinesModal';
import SupplierMachineCard from '../../components/supplier/SupplierMachineCard';
import EditMachineModal from '../../components/supplier/EditMachineModal';
import SupplierBookingCard from '../../components/supplier/SupplierBookingCard';

const SupplierDashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [myMachines, setMyMachines] = useState([]);
  const [machines, setMachines] = useState([]);
  const [supplier, setSupplier] = useState('');
  const [supplierId, setSupplierId] = useState('');
  const [bookingRequests, setBookingRequests] = useState([]);

  const navigate = useNavigate();

  const handleAddMachines = () => {
    setShowModal(true);
  };

  const handleMachineAdded = (newMachine) => {
    setMyMachines((prevMachines) => [...prevMachines, newMachine]);
    setShowModal(false);
  };

  const handleCompleteProfile = () => {
    navigate("/supplier/MyProfile");
  };

  const handleMachineUpdated = (updatedMachine) => {
    setMyMachines((prevMachines) =>
      prevMachines.map((machine) =>
        machine._id === updatedMachine._id ? updatedMachine : machine
      )
    );
    setShowEditModal(false);
  };

  const handleDelete = async (machine) => {
    try {
      const machineId = machine._id;
      await axios.delete(`https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/machines/${machineId}`);

      const updatedMachines = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/machines');
      setMyMachines(updatedMachines.data);
    } catch (error) {
      console.error('Failed to delete machine:', error);
    }
  };

  const handleEdit = (machine) => {
    setShowEditModal(true);
    setMachines((prevMachines) => [machine]);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const supplier_response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplierDetails');
        const supplier = supplier_response.data;
        if (
          supplier &&
          (!supplier.companyLegalName ||
            !supplier.industry ||
            !supplier.billingEmail ||
            !supplier.companyPhone ||
            !supplier.companyWebsite ||
            !supplier.documentFile ||
            !supplier.qualityCertification ||
            !supplier.registeredAddress ||
            !supplier.postalCode)
        ) {
          setSupplier('');
        }
        setSupplier('complete');
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/machines');
        const machines = response.data;
        setMyMachines(machines);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMachines();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentSupplierResponse = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/current');
        setSupplierId(currentSupplierResponse.data.supplierId);
      } catch (error) {
        console.error('Error fetching supplier data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchBookingRequests = async () => {
      try {
        if (supplierId) {
          const response = await axios.get(`https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/suppliers/${supplierId}/bookingRequests`);
          setBookingRequests(response.data);
        }
      } catch (error) {
        console.error('Error fetching booking requests:', error);
      }
    };

    fetchBookingRequests();
  }, [supplierId]);

  const filteredBookingRequests = bookingRequests.filter((booking) => booking.bookingStatus === 'pending');

  return (
    <>
      {(myMachines.length === 0) ?
        <div className='right-container' style={{ position: 'relative', width: '80%', top: '0px', left: '20%' }}>
          {
            showModal ? <>
              <AddMachinesModal bg={"#F8F8F8"} setShowModal={setShowModal} onMachineAdded={handleMachineAdded} />
            </> :
              <>
                <div className="noMachinePage" style={{ height: '100%' }}>
                  <h2>All Machine Details</h2>
                  <p>Please Add Your Machines</p>
                  <div className="flex" style={{ flexDirection: 'column', marginTop: '5%' }}>
                    <img src={noMachine} alt="machineImg" style={{ height: '200px' }} />
                    {supplier === '' ?
                      <>
                        <p style={{ marginTop: '5%' }}>To add Machines please complete your profile details</p>
                        <button onClick={handleCompleteProfile} className='btn-fill' style={{ marginTop: '2%' }}>
                          Complete Your Profile
                        </button>
                      </> :
                      <>
                        <button onClick={handleAddMachines} className='btn-fill' style={{ marginTop: '5%' }}>
                          Add Machines
                        </button>
                      </>
                    }
                  </div>
                  <h2>Booking Requests</h2>
                  <p>You do not have any booking request</p>
                  <div className="flex" style={{ flexDirection: 'column', marginTop: '5%' }}>
                    <img src={noBooking} alt="noBooking" style={{ height: '200px' }} />
                    {supplier === '' &&
                      <>
                        <p style={{ marginTop: '5%' }}>To receive Machine booking requests please complete your profile details</p>
                        <button onClick={handleCompleteProfile} className='btn-fill' style={{ marginTop: '2%' }}>
                          Complete Your Profile
                        </button>
                      </>}
                  </div>
                </div>
              </>
          }
        </div > :
        (showEditModal) ?
          <>
            <div className='right-container' style={{ position: 'relative', width: '75%', height: '100%', top: '0px', left: '20%', padding: '2%' }}>
              <EditMachineModal bg={"#F8F8F8"} setShowEditModal={setShowEditModal} onMachineAdded={handleMachineAdded} handleMachineUpdated={handleMachineUpdated} machines={machines} setMachines={setMachines} handleDelete={handleDelete} />
            </div>
          </> :
          <>
            <div className='right-container' style={{ position: 'relative', width: '75%', height: '100%', top: '0px', left: '20%', padding: '2%' }}>
              <div className="myMachinePage">
                <div className='myMachineHeader' style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                  <h2>All Machine Details</h2>
                </div>
                <p> You have recently added {myMachines.length} machines</p>
                <div className=' dashboard-flex'>
                  <div className="myMachinesCardContainer" style={{ flexGrow: '1' }}>
                    <SupplierMachineCard myMachines={myMachines.slice(0, 3)} handleDelete={handleDelete} handleEdit={handleEdit} />
                  </div>
                  <div className='total-machine-container'>
                    <div className='total-machine'>
                      <p style={{ background: '#0D4C7A' }}>Set Available Time (Pending Machines)</p>
                      <h2>{myMachines.filter(machine => machine.availabilityStatus === 'pending').length}</h2>
                      <button onClick={() => navigate('/supplier/MyMachines')}>View</button>
                    </div>
                    <div className='total-machine' style={{ background: 'rgba(15, 157, 88, 0.10)' }}>
                      <p style={{ background: '#0F9D58' }}>Available Machines</p>
                      <h2>{myMachines.filter(machine => machine.availabilityStatus === 'Available').length}</h2>
                      <button onClick={() => navigate('/supplier/MyMachines')}>View</button>
                    </div>
                    <div className='total-machine' style={{ background: 'rgba(205, 0, 0, 0.10)' }}>
                      <p style={{ background: '#CD0000' }}>Not Available Machines</p>
                      <h2>{myMachines.filter(machine => machine.availabilityStatus === 'not Available').length}</h2>
                      <button onClick={() => navigate('/supplier/MyMachines')}>View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='right-container' style={{ position: 'relative', width: '80%', height: '100vh', top: '0px', left: '20%' }}>
              <h2 style={{ padding: '1% 5% 0%' }}>Booking Request</h2>
              {bookingRequests?.length !== 0 ? (
                <div style={{ padding: '0% 5%' }}>
                  <div style={{ paddingBottom: '5%' }} className='main-supplier-booking-container'>
                    <div className='supplier-main-flex flex-wrap'>
                      <div>
                        <p> You have total {filteredBookingRequests.length} booking request </p>
                      </div>
                    </div>
                    <div className=' dashboard-flex'>
                      <div style={{ flexGrow: '1' }}>
                        <SupplierBookingCard bookingRequests={filteredBookingRequests.slice(0, 3)} Tab={"tab1"} />
                      </div>
                      <div>
                        <div className='total-machine'>
                          <p style={{ color: '#0D4C7A', fontSize: '14px' }}>Booking Requests</p>
                          {!filteredBookingRequests.length <= 10 ? <h2>{filteredBookingRequests.length}</h2> : <h2>10+</h2>}
                          <button onClick={() => navigate('/supplier/Bookings')}>View</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="noMachinePage">
                  <p>You do not have any booking request</p>
                  <div className="flex" style={{ flexDirection: 'column', marginTop: '5%' }}>
                    <img src={noBooking} alt="noBooking" />
                  </div>
                </div>
              )}
            </div>
          </>
      }
    </>
  );
};

export default SupplierDashboard;