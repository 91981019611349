import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import MachineForm from './MachineForm';
import Select from "react-select";
import './AddMachinesModal.css';
import axios from 'axios';

const AddMachinesModal = ({ bg, setShowModal, onMachineAdded }) => {
    const [machines, setMachines] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const removeMachine = (index) => {
        const updatedMachines = [...machines];
        updatedMachines.splice(index, 1);
        setMachines(updatedMachines);
        setValidationErrors({});
    };

    const handleEdit = (index) => {
        const updatedMachines = [...machines];
        updatedMachines[index] = { ...updatedMachines[index], isEdit: true };
        setMachines(updatedMachines);
    };

    const handleSaveEdit = (index) => {
        const updatedMachines = [...machines];
        updatedMachines[index] = { ...updatedMachines[index], isEdit: false };
        setMachines(updatedMachines);
    };


    const handleInputChange = (e, index, fieldName) => {
        const updatedMachines = [...machines];
        updatedMachines[index] = { ...updatedMachines[index], [fieldName]: e.target.value };
        setMachines(updatedMachines);
    };

    const handleOptionChange = (selectedOption, index, fieldName) => {
        const updatedMachines = [...machines];
        updatedMachines[index] = {
            ...updatedMachines[index],
            [fieldName]: selectedOption ? selectedOption.value : '', // Check if selectedOption is defined
        };
        setMachines(updatedMachines);
    };


    const machineTypeOptions = [
        { value: '', label: 'Select a Type' },
        { value: 'Laser Cutting', label: 'Laser Cutting' },
        { value: 'CNC Milling', label: 'CNC Milling' },
        { value: 'CNC Turning', label: 'CNC Turning' },
        { value: 'CNC Bending', label: 'CNC Bending' },
        { value: 'Moulding', label: 'Moulding' },
        { value: '3D Printing', label: '3D Printing' },
        { value: 'Die Casting', label: 'Die Casting' },
        { value: 'EDM Wirecut', label: 'EDM Wirecut' },
        { value: 'Lathe', label: 'Lathe' },
        { value: 'EDM', label: 'EDM' },
    ];

    const handleSaveChanges = async () => {
        const errors = {};
        machines.forEach((machine, index) => {
            if (!machine.machineType) errors[`machineType${index}`] = 'Machine Type is required';
            if (!machine.machineModel) errors[`machineModel${index}`] = 'Machine Model is required';
            if (!machine.machineName) errors[`machineName${index}`] = 'Machine Name is required';
            if (!machine.machineCapacity) errors[`machineCapacity${index}`] = 'Machine Capacity is required';
            if (!machine.tolerance) errors[`tolerance${index}`] = 'Tolerance is required';
            if (!machine.purchaseYear) errors[`purchaseYear${index}`] = 'Purchase Year is required';
            if (!machine.machineCost) errors[`machineCost${index}`] = 'Machine Cost is required';
        });

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        try {
            const apiUrl = 'https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/machines';
            const currentSupplierResponse = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/current');
            const { supplierId } = currentSupplierResponse.data;
            const machineIds = [];

            for (const machine of machines) {
                const formData = new FormData();
                formData.append('machineType', machine.machineType);
                formData.append('machineModel', machine.machineModel);
                formData.append('machineName', machine.machineName);
                formData.append('machineCapacity', machine.machineCapacity);
                formData.append('tolerance', machine.tolerance);
                formData.append('purchaseYear', machine.purchaseYear);
                formData.append('machineCost', machine.machineCost);
                formData.append('machineDetails', machine.machineDetails);
                machine.machinePictures.forEach((picture, index) => {
                    formData.append('machinePictures', picture);
                });


                const response = await axios.post(apiUrl, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                const { _id: machineId } = response.data;
                machineIds.push(machineId);
                onMachineAdded(response.data);
            }

            await axios.patch(`https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth//supplier/${supplierId}`, {
                machines: machineIds,
            });

            setShowModal(false);
        } catch (error) {
            setShowModal(false);
            console.error('Failed to save machines:', error);
        }
    };


    const purchaseYears = [];
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    for (let i = currentYear; i >= startYear; i--) {
        purchaseYears.push(i);
    }

    const purchaseYearOptions = purchaseYears.map((purchaseYearOption) => ({
        value: purchaseYearOption,
        label: purchaseYearOption.toString()
    }));


    const renderMachinePictures = (machinePictures, setMachinePictures) => {
        const handleDragOver = (e) => {
            e.preventDefault();
        };

        const handleDropMachine = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const uploadedPictures = Array.from(files).filter(
                (file) => file.type === "image/png" || file.type === "image/jpeg"
            );
            setMachinePictures(uploadedPictures);
        };

        const handleFileUpload = (e) => {
            const files = e.target.files;
            const uploadedPictures = Array.from(files).filter(
                (file) => file.type === "image/png" || file.type === "image/jpeg"
            );
            setMachinePictures(uploadedPictures);

            // Reset the input field value if no images are present
            if (uploadedPictures.length === 0) {
                e.target.value = "";
            }
        };

        const removeImage = (index, event) => {
            event.preventDefault();
            const updatedPictures = [...machinePictures];
            updatedPictures.splice(index, 1);
            setMachinePictures(updatedPictures);
        };
        return (
            <div>
                <div
                    className="drop-area"
                    onDragOver={handleDragOver}
                    onDrop={handleDropMachine}
                >
                    <input
                        type="file"
                        multiple
                        accept="image/png, image/jpeg"
                        onChange={handleFileUpload}
                    />
                    <p>Drag and drop images or click to upload</p>
                </div>
                <div className="image-container">
                    {machinePictures.map((picture, index) => (
                        <div key={index} className="image-item">
                            <button className="remove-button" onClick={(e) => removeImage(index, e)}>
                                <CloseRoundedIcon />
                            </button>
                            <img
                                src={URL.createObjectURL(picture)}
                                alt={`${index + 1}`}
                                style={{ width: '100px', height: '100px' }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderMachines = () => {
        return machines.map((machine, index) => (
            <div key={index} className="machine-container">
                <div style={{ width: '100%', background: '#E7EDF2', padding: '0.5% 1%', margin: '-1% 0 3% -1%' }}>
                    <button onClick={() => removeMachine(index)} style={{ float: 'right', margin: '-7px -12px 0 0' }} className='machine-btn'>
                        <DeleteForeverOutlinedIcon />
                    </button>
                    {!(machine.isEdit) ? (
                        <button onClick={() => handleEdit(index)} style={{ float: 'right', margin: '-7px 0px 0 0' }} className='machine-btn'>
                            <ModeEditOutlineOutlinedIcon />
                        </button>
                    ) : (
                        <button onClick={() => handleSaveEdit(index)} style={{ float: 'right', margin: '-7px 0px 0 0' }} className='machine-btn'>
                            <CheckOutlinedIcon />
                        </button>
                    )}
                    <p style={{ fontWeight: 'bold' }}>{machine.machineType} Machine</p>
                </div>
                <div className="machine-details">
                    <div className='flex' style={{ justifyContent: 'space-between' }}>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>
                                Selected Machine Type <span style={{ color: 'red' }}>*</span>
                            </p>
                            {machine.isEdit ? (
                                <Select
                                    value={{ value: machine.machineType, label: machine.machineType }}
                                    options={machineTypeOptions}
                                    onChange={(selectedOption) => handleOptionChange(selectedOption, index, 'machineType')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineType}</p>
                            )}
                            {validationErrors[`machineType${index}`] && (
                                <p style={{ color: 'red' }}>{validationErrors[`machineType${index}`]}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>
                                Machine Model <span style={{ color: 'red' }}>*</span>
                            </p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.machineModel}
                                    onChange={(e) => handleInputChange(e, index, 'machineModel')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineModel}</p>
                            )}
                            {validationErrors[`machineModel${index}`] && (
                                <p style={{ color: 'red' }}>{validationErrors[`machineModel${index}`]}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>
                                Machine Name <span style={{ color: 'red' }}>*</span>
                            </p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.machineName}
                                    onChange={(e) => handleInputChange(e, index, 'machineName')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineName}</p>
                            )}
                            {validationErrors[`machineName${index}`] && (
                                <p style={{ color: 'red' }}>{validationErrors[`machineName${index}`]}</p>
                            )}
                        </div>
                    </div>
                    <div className='flex' style={{ justifyContent: 'space-between' }}>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>
                                Machine Capacity <span style={{ color: 'red' }}>*</span>
                            </p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.machineCapacity}
                                    onChange={(e) => handleInputChange(e, index, 'machineCapacity')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineCapacity}</p>
                            )}
                            {validationErrors[`machineCapacity${index}`] && (
                                <p style={{ color: 'red' }}>{validationErrors[`machineCapacity${index}`]}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>
                                Tolerance <span style={{ color: 'red' }}>*</span>
                            </p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.tolerance}
                                    onChange={(e) => handleInputChange(e, index, 'tolerance')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.tolerance}</p>
                            )}
                            {validationErrors[`tolerance${index}`] && (
                                <p style={{ color: 'red' }}>{validationErrors[`tolerance${index}`]}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>
                                Purchase Year <span style={{ color: 'red' }}>*</span>
                            </p>
                            {machine.isEdit ? (
                                <Select
                                    value={{ value: machine.purchaseYear, label: machine.purchaseYear }}
                                    options={purchaseYearOptions}
                                    onChange={(selectedOption) => handleOptionChange(selectedOption, index, 'purchaseYear')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.purchaseYear}</p>
                            )}
                            {validationErrors[`purchaseYear${index}`] && (
                                <p style={{ color: 'red' }}>{validationErrors[`purchaseYear${index}`]}</p>
                            )}
                        </div>
                    </div>
                    <div className='flex' style={{ justifyContent: 'space-between' }}>
                        <div className="input-container" style={{ width: '545px' }}>
                            <p className='label' style={{ margin: '0px' }}>
                                Machine Details
                            </p>
                            {machine.isEdit ? (
                                <textarea
                                    className='input-field'
                                    style={{ height: '8%', margin: '10px' }}
                                    value={machine.machineDetails}
                                    onChange={(e) => handleInputChange(e, index, 'machineDetails')}
                                />
                            ) : (
                                <p className='input-field' style={{ height: '8%', margin: '10px' }}>{machine.machineDetails}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>
                                Machine Cost (per hour cost) <span style={{ color: 'red' }}>*</span>
                            </p>
                            {machine.isEdit ? (
                                <input
                                    type="number"
                                    className='input-field'
                                    value={machine.machineCost}
                                    onChange={(e) => handleInputChange(e, index, 'machineCost')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineCost}</p>
                            )}
                            {validationErrors[`machineCost${index}`] && (
                                <p style={{ color: 'red' }}>{validationErrors[`machineCost${index}`]}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="machine-pictures">
                    <p>Uploaded Machine Pictures</p>
                    {machine.isEdit ? (
                        renderMachinePictures(machine.machinePictures, (uploadedPictures) => {
                            const updatedMachines = [...machines];
                            updatedMachines[index] = { ...machine, machinePictures: uploadedPictures };
                            setMachines(updatedMachines);
                        })
                    ) : (
                        machine.machinePictures.map((picture, picIndex) => (
                            <img
                                key={picIndex}
                                src={URL.createObjectURL(picture)}
                                alt={"MachinePicture"}
                                style={{ width: '100px', height: '100px', margin: '1%' }}
                            />
                        ))
                    )}
                </div>
            </div>
        ));
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '120vh', justifyContent: 'space-between' }}>
            <div style={{ width: '80%', margin: '2%', padding: '1%' }}>
                <MachineForm
                    machines={machines} setMachines={setMachines} handleEdit={handleEdit}
                />
            </div>
            {machines.length !== 0 && <h2 style={{ padding: '0 2%' }}>Added Machines</h2>}
            {renderMachines()}
            <div className='flex' style={{ background: '#E7EDF2', width: '125%', left: '-25%', justifyContent: 'end', alignItems: 'center', alignSelf: 'end' }}>
                <button className="cancel-button btn-outline" style={{ margin: "1%" }} onClick={handleCloseModal}>Cancel</button>
                <button className="save-button btn-fill" style={{ margin: "1%" }} onClick={handleSaveChanges}>Save Changes</button>
            </div>
        </div>
    );
};

export default AddMachinesModal;
