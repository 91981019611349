import React from 'react';
import m1 from '../../assets/m1.png';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';

const BuyerBookingCard2 = ({ allMachines, Tab, setShowActiveModal, setActiveBooking, rating, setRating }) => {

    const handleClick = async (rate, bookingRequest) => {
        try {
            const response = await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/updateRating", {
                bookingId: bookingRequest.booking._id,
                newRating: rate
            });

            if (response.status === 200) {
                setRating(rate);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const formatDate = (date) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            weekday: 'short',
        };
        const formattedDate = new Date(date).toLocaleString('en-IN', options);

        const dayStyle = { fontSize: '10px', color: '#585858' };

        return (
            <div>
                {formattedDate.slice(4, -5)}{' '}
                <span style={dayStyle}>{formattedDate.slice(0, 3)}</span>{' '}
                <span style={dayStyle}>{formattedDate.slice(-4)}</span>
            </div>
        );
    };

    const formatTime = (time) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Kolkata',
        };
        return new Date(time).toLocaleTimeString('en-US', options);
    };

    const calculateNumberOfDays = (fromDate, toDate) => {
        const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const start = new Date(fromDate);
        const end = new Date(toDate);
        const timeDifference = Math.abs(end - start);
        const numberOfDays = Math.ceil(timeDifference / oneDay);
        return numberOfDays === 0 ? 1 : numberOfDays;
    }

    const calculateNumberOfHours = (duration, fromDate, toDate) => {
        const days = calculateNumberOfDays(fromDate, toDate);
        const Totalhours = days * duration;
        return Totalhours;
    }

    const calculateRent = (cost, duration, fromDate, toDate) => {
        const hours = calculateNumberOfHours(duration, fromDate, toDate);
        const TotalRent = hours * cost;
        return TotalRent;
    }

    const openActiveModal = (bookingRequest) => {
        setShowActiveModal(true);
        setActiveBooking(bookingRequest);
    }

    return (
        <>
            <div className="main-suplier-booking-card-container flex-wrap">
                {allMachines &&
                    allMachines.map((bookingRequest) => (
                        <div key={bookingRequest.booking._id} className="supplier-booking-card" style={{ height: Tab === 'tab1' && '480px' }}>
                            <div className="supplier-booking-section-1" style={{ background: (Tab === 'tab1') ? 'rgba(255, 199, 0, 0.10)' : 'rgba(15, 157, 88, 0.10)' }}>
                                <img
                                    className="supplier-booking-machine-picture"
                                    src={m1}
                                    alt="m1"
                                    style={{ width: '40px', height: '40px', borderRadius: '100%' }}
                                />
                                <div className="supplier-booking-machine-name">
                                    <p>{bookingRequest.booking.machineType}</p>
                                    <p>{bookingRequest.booking.machineName}</p>
                                </div>
                                {Tab === 'tab2' && <div style={{ marginLeft: '65px', fontSize: '20px' }}>
                                    {bookingRequest.booking.bookingStatus === 'accepted' && <BsFillCheckCircleFill fill="#238A27" />}
                                </div>}
                                {Tab === 'tab1' && <div style={{ marginLeft: '65px', fontSize: '20px' }}>
                                    {bookingRequest.booking.bookingStatus === 'active' && <BsFillCheckCircleFill fill="#FFC700" />}
                                </div>}
                            </div>

                            <div className="supplier-booking-section-2">
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Supplier</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{bookingRequest.booking.supplier.user.firstName} {bookingRequest.booking.supplier.user.lastName}</p>
                                        </div>
                                    </div>
                                    <div >
                                        <div className="supplier-booking-prop">
                                            <p>Location</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{bookingRequest.booking.buyerLocation}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Request Date</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatDate(bookingRequest.booking.dateMade)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ width: '90%', opacity: '0.3' }} />

                            <div className="supplier-booking-section-2">
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>From Date</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatDate(bookingRequest.booking.fromDate)}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>To Date</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatDate(bookingRequest.booking.toDate)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>From Time</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatTime(bookingRequest.booking.fromTime)}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>To Time</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatTime(bookingRequest.booking.toTime)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Duration</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{calculateNumberOfHours(bookingRequest.booking.availability, bookingRequest.booking.fromDate, bookingRequest.booking.toDate)}Hrs
                                                <br /> <span style={{ fontSize: '11px' }}> ({bookingRequest.booking.availability} hrs/ {calculateNumberOfDays(bookingRequest.booking.fromDate, bookingRequest.booking.toDate)}days)</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Rental Cost</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{calculateRent(bookingRequest.booking.rentalCost, bookingRequest.booking.availability, bookingRequest.booking.fromDate, bookingRequest.booking.toDate)} /- Rs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr style={{ width: '90%', opacity: '0.3' }} />

                            <div className="supplier-booking-section-3" style={{ alignItems: 'center' }}>
                                <p style={{ padding: '12px 0px', margin: 0, color: '#585858', fontSize: '12px' }} >
                                    Give Rating
                                </p>
                                <div>
                                    {[1, 2, 3, 4, 5].map((star) => {
                                        return (
                                            <span key={star} onClick={() => handleClick(star, bookingRequest)}>
                                                <StarIcon style={{ color: (star <= bookingRequest.booking.rating || star <= rating) ? '#5ECE7B' : '#E5E0EB' }} />
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>

                            {Tab === 'tab1' &&
                                <>
                                    <hr style={{ width: '90%', opacity: '0.3' }} />
                                    <div className="supplier-booking-section-3" style={{ justifyContent: 'center' }}>
                                        <button className="btn-fill" style={{ padding: '12px 25px', margin: 0 }} onClick={() => openActiveModal(bookingRequest)}>
                                            View Details
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    ))}
            </div>
        </>
    );
};

export default BuyerBookingCard2;
