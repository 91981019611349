import React, { useState, useEffect } from 'react';
import noBooking from '../../assets/noBooking.svg';
import axios from 'axios';
import { Tab, Tabs } from '@mui/material';
import BuyerBookingCard from '../../components/buyer/BuyerBookingCard';
import BuyerBookingCard2 from '../../components/buyer/BuyerBookingCard2';
import AcceptRequestModal from '../../components/buyer/AcceptRequestModal';
import ActiveBookingModal from '../../components/buyer/ActiveBookingModal';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const BuyerBookingPage = () => {
    const location = useLocation();
    const initialActiveTab = location.state?.activeTab || 0;
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const [bookingRequests, setBookingRequests] = useState([]);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showActiveModal, setShowActiveModal] = useState(false);
    const [acceptedBooking, setAcceptedBooking] = useState();
    const [activeBooking, setActiveBooking] = useState();
    const [rating, setRating] = useState(0);
    const [buyerCoordinates, setBuyerCoordinates] = useState({
        x: null,
        y: null
    });


    useEffect(() => {
        const fetchBookingRequests = async () => {
            try {
                const response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/booking/allBookings');
                const updatedBookingRequests = response.data.map(booking => {
                    const currentTime = moment();
                    const remainingTime = moment(booking.booking.acceptedAt).add(2, 'hour').diff(currentTime, 'minutes');
                    return {
                        ...booking,
                        remainingTime
                    };
                });
                setBookingRequests(updatedBookingRequests);
            } catch (error) {
                console.error(error);
            }
        };

        fetchBookingRequests();

        const intervalId = setInterval(() => {
            fetchBookingRequests();
        }, 5000); // Fetch the updated booking requests every minute

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
        };
    }, [rating]);


    useEffect(() => {
        axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/buyerInfo')
            .then(response => {
                setBuyerCoordinates(response.data.coordinates);
            })
            .catch(error => {
                console.error(error);
            });
    }, [])

    const CalculateDistance = (supplierCoordinates) => {
        const earthRadiusKm = 6371; // Earth's radius in kilometers
        const lat1 = degreesToRadians(buyerCoordinates.x);
        const lon1 = degreesToRadians(buyerCoordinates.y);
        const lat2 = degreesToRadians(supplierCoordinates.x);
        const lon2 = degreesToRadians(supplierCoordinates.y);

        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadiusKm * c;

        return Math.round(distance);
    };

    const degreesToRadians = (degrees) => {
        return (degrees * Math.PI) / 180;
    };

    const filteredInitiatedBookings = bookingRequests.filter((bookingRequest) => (bookingRequest.booking.bookingStatus === 'accepted' || bookingRequest.booking.bookingStatus === 'pending'))

    const filteredActiveBookings = bookingRequests.filter((bookingRequest) => (bookingRequest.booking.bookingStatus === 'active'))

    const filteredBookingHistory = bookingRequests.filter((bookingRequest) => (bookingRequest.booking.bookingStatus === 'completed' || bookingRequest.booking.bookingStatus === 'cancelled'))

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div className='right-container' style={{ position: 'relative', width: '80%', height: '100vh', top: '0px', left: '20%' }}>
            {showAcceptModal ?
                <AcceptRequestModal
                    showAcceptModal={showAcceptModal}
                    acceptedBooking={acceptedBooking}
                    CalculateDistance={CalculateDistance}
                />
                :
                showActiveModal ?
                    <ActiveBookingModal
                        setShowActiveModal={setShowActiveModal}
                        acceptedBooking={activeBooking}
                        CalculateDistance={CalculateDistance}
                        rating={rating}
                        setRating={setRating}
                    />
                    :
                    <>
                        <h2 style={{ padding: '1% 5% 0%' }}>Bookings</h2>
                        {bookingRequests?.length !== 0 ? (
                            <div style={{ padding: '0% 3%' }}>
                                <div>
                                    <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth" style={{ width: '60%', marginBottom: '40px' }} className='tab-container'>
                                        <Tab label="Initiated Bookings" style={{ textTransform: 'none', color: '#0D4C7A' }} />
                                        <Tab label="Active Bookings" style={{ textTransform: 'none', color: '#0D4C7A' }} />
                                        <Tab label="Booking History" style={{ textTransform: 'none', color: '#0D4C7A' }} />
                                    </Tabs>
                                    <div>
                                        {activeTab === 0 && (
                                            <div className='buyer-booking-card-container flex-wrap"'>
                                                {filteredInitiatedBookings.length !== 0 &&
                                                    < BuyerBookingCard allMachines={filteredInitiatedBookings} CalculateDistance={CalculateDistance} setShowAcceptModal={setShowAcceptModal} setAcceptedBooking={setAcceptedBooking} />
                                                }
                                            </div>
                                        )}
                                        {activeTab === 1 && (
                                            <div style={{ marginLeft: '20px', marginBottom: '140px' }}>
                                                {filteredActiveBookings.length !== 0 &&
                                                    < BuyerBookingCard2 allMachines={filteredActiveBookings} CalculateDistance={CalculateDistance} setShowActiveModal={setShowActiveModal} setActiveBooking={setActiveBooking} Tab={'tab1'} rating={rating} setRating={setRating} />
                                                }
                                            </div>)}
                                        {activeTab === 2 && (
                                            <div style={{ marginLeft: '20px', marginBottom: '140px' }}>
                                                {filteredBookingHistory.length !== 0 &&
                                                    < BuyerBookingCard2 allMachines={filteredBookingHistory} CalculateDistance={CalculateDistance} setShowActiveModal={setShowActiveModal} setActiveBooking={setActiveBooking} Tab={'tab2'} rating={rating} setRating={setRating} />
                                                }
                                            </div>)}
                                    </div>
                                </div>


                            </div>
                        ) : (
                            <div className="noMachinePage">
                                <p>You have no booking request</p>
                                <div className="flex" style={{ flexDirection: 'column', marginTop: '5%' }}>
                                    <img src={noBooking} alt="noBooking" />
                                </div>
                            </div>
                        )}
                    </>
            }
        </div>
    );
};

export default BuyerBookingPage;
