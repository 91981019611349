import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import noMachine from '../../assets/noMachine.svg'
import "./css/MyMachines.css"
import AddMachinesModal from '../../components/supplier/AddMachinesModal';
import axios from 'axios';
import SupplierMachineCard from '../../components/supplier/SupplierMachineCard';
import EditMachineModal from '../../components/supplier/EditMachineModal';

const MyMachines = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [myMachines, setMyMachines] = useState([]);
  const [machines, setMachines] = useState([]);
  const [supplier, setSupplier] = useState('');

  const handleAddMachines = () => {
    setShowModal(true);
  }

  const handleMachineAdded = (newMachine) => {
    setMyMachines((prevMachines) => [...prevMachines, newMachine]);
    setShowModal(false);
  }

  const handleCompleteProfile = () => {
    navigate("/supplier/MyProfile");
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const supplier_response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplierDetails');
        const supplier = supplier_response.data;
        if (supplier && (!supplier.companyLegalName || !supplier.industry || !supplier.billingEmail || !supplier.companyPhone || !supplier.companyWebsite ||
          !supplier.documentFile || !supplier.qualityCertification || !supplier.registeredAddress || !supplier.postalCode)) {
          setSupplier('')
        }
        setSupplier('complete')
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserData();
  }, []);


  const handleMachineUpdated = (updatedMachine) => {
    setMyMachines((prevMachines) =>
      prevMachines.map((machine) =>
        machine._id === updatedMachine._id ? updatedMachine : machine
      )
    );
    setShowEditModal(false);
  };

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/machines');
        const machines = response.data;
        setMyMachines(machines);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMachines();
  }, []);

  const handleDelete = async (machine) => {
    try {
      const machineId = machine._id;
      await axios.delete(`https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/machines/${machineId}`);

      const updatedMachines = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/machines');
      setMyMachines(updatedMachines.data);

    } catch (error) {
      console.error('Failed to delete machine:', error);
    }
  };

  const handleEdit = (machine) => {
    setShowEditModal(true);
    setMachines((prevMachines) => [machine]);
  };


  return (
    <>
      {(myMachines.length === 0) ? <>
        <div className='right-container' style={{ position: 'relative', width: '80%', top: '0px', left: '20%' }}>
          {
            showModal ? <>
              <AddMachinesModal bg={"#F8F8F8"} setShowModal={setShowModal} onMachineAdded={handleMachineAdded} />
            </> :
              <div className="noMachinePage">
                <h2>All Machine Details</h2>
                <p>No machines have been added by You</p>
                <div className="flex" style={{ flexDirection: 'column', marginTop: '5%' }}>
                  <img src={noMachine} alt="machineImg" />
                  {supplier === '' ?
                    <>
                      <p style={{ marginTop: '5%' }}>To add Machines please complete your profile details</p>
                      <button onClick={handleCompleteProfile} className='btn-fill' style={{ marginTop: '2%' }}>
                        Complete Your Profile
                      </button>
                    </> :
                    <>
                      <button onClick={handleAddMachines} className='btn-fill' style={{ marginTop: '5%' }}>
                        Add Machines
                      </button>
                    </>
                  }
                </div>
              </div>
          }
        </div>
      </> :
        (showEditModal) ?
          <>
            <div className='right-container' style={{ position: 'relative', width: '75%', height: '100%', top: '0px', left: '20%', padding: '2%' }}>
              <EditMachineModal bg={"#F8F8F8"} setShowEditModal={setShowEditModal} onMachineAdded={handleMachineAdded} handleMachineUpdated={handleMachineUpdated} machines={machines} setMachines={setMachines} handleDelete={handleDelete} />
            </div>
          </> :
          <div className='right-container' style={{ position: 'relative', width: '75%', height: '100%', top: '0px', left: '20%', padding: '2%' }}>
            {
              showModal ? <>
                <AddMachinesModal bg={"#F8F8F8"} setShowModal={setShowModal} onMachineAdded={handleMachineAdded} />
              </> :
                <div className="myMachinePage">
                  <div className='myMachineHeader' style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                    <h2> My Machines</h2>
                    <div>
                      <button className='btn-fill' onClick={handleAddMachines}>
                        Add Machine
                      </button>
                    </div>
                  </div>
                  <p> You have recently added {myMachines.length} machines</p>
                  <div className="myMachinesCardContainer">
                    < SupplierMachineCard myMachines={myMachines} handleDelete={handleDelete} handleEdit={handleEdit} />
                  </div>
                </div>
            }
          </div>
      }

    </>

  )
}

export default MyMachines
