import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import './MessageModal.css'

const OtpModal = ({ showModal, closeModal, message, detail, navigateto, setSearchClicked, setRequiredMachineType, setSelectedDate, setFromTime, setToTime, setDuration }) => {
    const navigate = useNavigate();

    const handleClick = async (e) => {
        e.preventDefault();
        setSearchClicked && setSearchClicked(false);
        setRequiredMachineType && setRequiredMachineType('');
        setSelectedDate && setSelectedDate([]);
        setFromTime && setFromTime(null);
        setToTime && setToTime(null);
        setDuration && setDuration(null);
        closeModal();
        navigate(navigateto);
    }

    return (
        <div className={showModal ? 'modal display-block' : 'modal display-none'}>
            <div className='msg-modal-content'>
                <div style={{ padding: '5%' }}>
                    <span className='flex'>
                        <BsFillCheckCircleFill fill="#238A27" size={25} />
                    </span>
                    <h3 style={{ textAlign: 'center' }}>{message}</h3>
                    <p style={{ fontSize: '13px', textAlign: 'center', color: '#0D4C7A' }}>
                        {detail}</p>
                    <div className='flex' >
                        <button className='submit-btn' onClick={(e) => handleClick(e)}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpModal;
