import React, { useState, useEffect } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Select from "react-select";
import './AddMachinesModal.css';
import axios from 'axios';

const EditMachineModal = ({ bg, setShowEditModal, onMachineAdded, handleMachineUpdated, machines, setMachines, handleDelete }) => {
    const [machinePictureUrl, setMachinePictureUrl] = useState([]);
    const [isValid, setIsValid] = useState(true);

    const validateForm = () => {
        let isValid = true;
        for (const machine of machines) {
            if (!machine.machineType || !machine.machineModel || !machine.machineName || !machine.machineCapacity ||
                !machine.tolerance || !machine.purchaseYear || !machine.machineCost) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };


    useEffect(() => {
        if (machines[0] && machines[0].machinePictures && machines[0].machinePictures.length > 0) {
            const urls = machines[0].machinePictures.map(picture => picture);
            setMachinePictureUrl(urls);
        }
    }, [machines])

    const handleCloseModal = () => {
        setShowEditModal(false);
    };

    const removeMachine = (index) => {
        handleDelete(machines[index]);
        setShowEditModal(false);
    };

    const handleEdit = (index) => {
        const updatedMachines = [...machines];
        updatedMachines[index] = { ...updatedMachines[index], isEdit: true };
        setMachines(updatedMachines);
    };

    const handleSaveEdit = (index) => {
        if (!validateForm()) {
            setIsValid(false);
            return;
        }
        const updatedMachines = [...machines];
        updatedMachines[index] = { ...updatedMachines[index], isEdit: false };
        setMachines(updatedMachines);
    };


    const handleInputChange = (e, index, fieldName) => {
        const updatedMachines = [...machines];
        updatedMachines[index] = { ...updatedMachines[index], [fieldName]: e.target.value };
        setMachines(updatedMachines);
        setIsValid(validateForm());
    };

    const handleOptionChange = (selectedOption, index, fieldName) => {
        const updatedMachines = [...machines];
        updatedMachines[index] = {
            ...updatedMachines[index],
            [fieldName]: selectedOption ? selectedOption.value : '',
        };
        setMachines(updatedMachines);
        setIsValid(validateForm());
    };


    const machineTypeOptions = [
        { value: '', label: 'Select a Type' },
        { value: 'Laser Cutting', label: 'Laser Cutting' },
        { value: 'CNC Milling', label: 'CNC Milling' },
        { value: 'CNC Turning', label: 'CNC Turning' },
        { value: 'CNC Bending', label: 'CNC Bending' },
        { value: 'Moulding', label: 'Moulding' },
        { value: '3D Printing', label: '3D Printing' },
        { value: 'Die Casting', label: 'Die Casting' },
        { value: 'EDM Wirecut', label: 'EDM Wirecut' },
        { value: 'Lathe', label: 'Lathe' },
        { value: 'EDM', label: 'EDM' },
    ];

    const handleSaveNewChanges = async () => {
        if (!validateForm()) {
            setIsValid(false);
            return;
        }
        try {
            const apiUrl = 'https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/machines';
            const currentSupplierResponse = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier/current');
            const { supplierId } = currentSupplierResponse.data;
            const machineIds = [];

            for (const machine of machines) {
                const formData = new FormData();
                formData.append('machineType', machine.machineType);
                formData.append('machineModel', machine.machineModel);
                formData.append('machineName', machine.machineName);
                formData.append('machineCapacity', machine.machineCapacity);
                formData.append('tolerance', machine.tolerance);
                formData.append('purchaseYear', machine.purchaseYear);
                formData.append('machineCost', machine.machineCost);
                formData.append('machineDetails', machine.machineDetails);
                machine.machinePictures.forEach((picture, index) => {
                    if (typeof picture !== "string")
                        formData.append('machinePictures', picture);
                    else {
                        formData.append(`machinePictures[${index}]`, machinePictureUrl[index]);
                    }
                });

                const response = await axios.post(apiUrl, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                const { _id: machineId } = response.data;
                machineIds.push(machineId);
                onMachineAdded(response.data);
            }

            await axios.patch(`https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth//supplier/${supplierId}`, {
                machines: machineIds,
            });

            setShowEditModal(false);
        } catch (error) {
            setShowEditModal(false);
            console.error('Failed to save machines:', error);
        }
    };


    const handleSaveChanges = async (machine) => {
        if (!validateForm()) {
            setIsValid(false);
            return;
        }
        try {
            const apiUrl = 'https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/machines';
            const machineId = machine._id;
            for (const machine of machines) {
                const formData = new FormData();
                formData.append('machineType', machine.machineType);
                formData.append('machineModel', machine.machineModel);
                formData.append('machineName', machine.machineName);
                formData.append('machineCapacity', machine.machineCapacity);
                formData.append('tolerance', machine.tolerance);
                formData.append('purchaseYear', machine.purchaseYear);
                formData.append('machineCost', machine.machineCost);
                formData.append('machineDetails', machine.machineDetails);
                machine.machinePictures.forEach((picture, index) => {
                    if (typeof picture !== "string")
                        formData.append('machinePictures', picture);
                    else {
                        formData.append(`machinePictures[${index}]`, machinePictureUrl[index]);
                    }
                });

                const response = await axios.put(`${apiUrl}/${machineId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                handleMachineUpdated(response.data)
            }
            setShowEditModal(false);
        } catch (error) {
            setShowEditModal(false);
            console.error('Failed to update machines:', error);
        }
    }

    const purchaseYears = [];
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    for (let i = currentYear; i >= startYear; i--) {
        purchaseYears.push(i);
    }

    const purchaseYearOptions = purchaseYears.map((purchaseYearOption) => ({
        value: purchaseYearOption,
        label: purchaseYearOption.toString()
    }));


    const renderMachinePictures = (machinePictures, setMachinePictures) => {
        const handleDragOver = (e) => {
            e.preventDefault();
        };

        const handleDropMachine = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const uploadedPictures = Array.from(files).filter(
                (file) => file.type === "image/png" || file.type === "image/jpeg"
            );
            setMachinePictures(uploadedPictures);
        };

        const handleFileUpload = (e) => {
            const files = e.target.files;
            const uploadedPictures = Array.from(files).filter(
                (file) => file.type === "image/png" || file.type === "image/jpeg"
            );
            setMachinePictures(uploadedPictures);

            // Reset the input field value if no images are present
            if (uploadedPictures.length === 0) {
                e.target.value = "";
            }
        };

        const removeImage = (index, event) => {
            event.preventDefault();
            const updatedPictures = [...machinePictures];
            updatedPictures.splice(index, 1);
            setMachinePictures(updatedPictures);
        };

        return (
            <div>
                <div
                    className="drop-area"
                    onDragOver={handleDragOver}
                    onDrop={handleDropMachine}
                >
                    <input
                        type="file"
                        multiple
                        accept="image/png, image/jpeg"
                        onChange={handleFileUpload}
                    />
                    <p>Drag and drop images or click to upload</p>
                </div>
                <div className="image-container">
                    {machinePictures.map((picture, index) => {
                        if (typeof picture === "string") {
                            return (
                                <div key={index} className="image-item">
                                    <button
                                        className="remove-button"
                                        onClick={(e) => removeImage(index, e)}
                                    >
                                        <CloseRoundedIcon />
                                    </button>
                                    <img
                                        src={picture}
                                        alt={"MachinePicture"}
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </div>
                            );
                        } else {
                            return (
                                <div key={index} className="image-item">
                                    <button
                                        className="remove-button"
                                        onClick={(e) => removeImage(index, e)}
                                    >
                                        <CloseRoundedIcon />
                                    </button>
                                    <img
                                        src={URL.createObjectURL(picture)}
                                        alt={"MachinePicture"}
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    };

    const renderMachines = () => {
        return machines.map((machine, index) => (
            <div key={index} className="machine-container">
                {!isValid && <p style={{ color: 'red' }}>All fields marked with * are required</p>}
                <div style={{ width: '100%', background: '#E7EDF2', padding: '0.5% 1%', margin: '-1% 0 3% -1%' }}>
                    <button onClick={() => removeMachine(index)} style={{ float: 'right', margin: '-7px -12px 0 0' }} className='machine-btn'><DeleteForeverOutlinedIcon /></button>
                    {!(machine.isEdit) ? <button onClick={() => handleEdit(index)} style={{ float: 'right', margin: '-7px 0px 0 0' }} className='machine-btn'><ModeEditOutlineOutlinedIcon /></button>
                        : <button onClick={() => handleSaveEdit(index)} style={{ float: 'right', margin: '-7px 0px 0 0' }} className='machine-btn'><CheckOutlinedIcon /></button>
                    }
                    <p style={{ fontWeight: 'bold' }}>{machine.machineType} Machine</p>
                </div>
                <div className="machine-details">
                    <div className='flex wrap' style={{ justifyContent: 'space-between' }}>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>Selected Machine Type{(machine.isEdit) && <span style={{ color: 'red' }}>*</span>} </p>
                            {machine.isEdit ? (
                                <Select
                                    value={{ value: machine.machineType, label: machine.machineType }}
                                    options={machineTypeOptions}
                                    onChange={(selectedOption) => handleOptionChange(selectedOption, index, 'machineType')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineType}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>Machine Model{(machine.isEdit) && <span style={{ color: 'red' }}>*</span>}</p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.machineModel}
                                    onChange={(e) => handleInputChange(e, index, 'machineModel')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineModel}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>Machine Name{(machine.isEdit) && <span style={{ color: 'red' }}>*</span>} </p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.machineName}
                                    onChange={(e) => handleInputChange(e, index, 'machineName')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineName}</p>
                            )}
                        </div>
                    </div>
                    <div className='flex wrap' style={{ justifyContent: 'space-between' }}>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>Machine Capacity{(machine.isEdit) && <span style={{ color: 'red' }}>*</span>} </p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.machineCapacity}
                                    onChange={(e) => handleInputChange(e, index, 'machineCapacity')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineCapacity}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>Tolerance{(machine.isEdit) && <span style={{ color: 'red' }}>*</span>} </p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.tolerance}
                                    onChange={(e) => handleInputChange(e, index, 'tolerance')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.tolerance}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>Purchase Year{(machine.isEdit) && <span style={{ color: 'red' }}>*</span>} </p>
                            {machine.isEdit ? (
                                <Select
                                    value={{ value: machine.purchaseYear, label: machine.purchaseYear }}
                                    options={purchaseYearOptions}
                                    onChange={(selectedOption) => handleOptionChange(selectedOption, index, 'purchaseYear')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.purchaseYear}</p>
                            )}
                        </div>
                    </div>
                    <div className='flex wrap' style={{ justifyContent: 'space-between' }}>
                        <div className="input-container" style={{ width: '545px' }}>
                            <p className='label' style={{ margin: '0px' }}>Machine Details </p>
                            {machine.isEdit ? (
                                <textarea
                                    className='input-field'
                                    value={machine.machineDetails}
                                    onChange={(e) => handleInputChange(e, index, 'machineDetails')}
                                />
                            ) : (
                                <p className='input-field' style={{ height: '8%', margin: '10px' }}>{machine.machineDetails}</p>
                            )}
                        </div>
                        <div className="input-container">
                            <p className='label' style={{ margin: '0px' }}>Machine Cost(per hour cost){(machine.isEdit) && <span style={{ color: 'red' }}>*</span>}</p>
                            {machine.isEdit ? (
                                <input
                                    type="text"
                                    className='input-field'
                                    value={machine.machineCost}
                                    onChange={(e) => handleInputChange(e, index, 'machineCost')}
                                />
                            ) : (
                                <p className='input-field' style={{ margin: '10px' }}>{machine.machineCost}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="machine-pictures">
                    <p>Uploaded Machine Pictures</p>
                    {machine.isEdit ?
                        renderMachinePictures(machine.machinePictures, (uploadedPictures) => {
                            const updatedMachines = [...machines];
                            updatedMachines[index] = { ...machine, machinePictures: uploadedPictures };
                            setMachines(updatedMachines);
                        })
                        :
                        machine.machinePictures.map((picture, picIndex) => {
                            if (typeof picture === "string") {
                                return (
                                    <img
                                        key={picIndex}
                                        src={picture}
                                        alt={"MachinePicture"}
                                        style={{ width: '100px', height: '100px', margin: '1%' }}
                                    />
                                )
                            } else {
                                return (
                                    <img
                                        key={picIndex}
                                        src={URL.createObjectURL(picture)}
                                        alt={"MachinePicture"}
                                        style={{ width: '100px', height: '100px', margin: '1%' }}
                                    />
                                )
                            }
                        })}
                </div>
            </div>
        ));
    };


    return (
        <div style={{ display: 'flex wrap', flexDirection: 'column', minHeight: '80vh', justifyContent: 'start' }}>
            {machines.length !== 0 && <h2 style={{ padding: '0 2%' }}>Edit Machine</h2>}
            {renderMachines()}
            <div className='flex edit-save-btn' style={{ background: '#E7EDF2', width: '144%', left: '-43%', justifySelf: 'end', justifyContent: 'end', alignItems: 'center', alignSelf: 'end', position: 'absolute', bottom: '-36px', overflowX: 'hidden' }}>
                <button className="cancel-button btn-outline" style={{ margin: "1%" }} onClick={handleCloseModal}>Cancel</button>
                <button className="save-button btn-outline" style={{ margin: "1%" }} onClick={handleSaveNewChanges}>Save as New Machine</button>
                <button className="save-button btn-fill" style={{ margin: "1%" }} onClick={() => handleSaveChanges(machines[0])}>Save Machine</button>
            </div>
        </div>
    );
};

export default EditMachineModal;
