import React, { useState } from 'react';
import DetailsForm from './DetailsForm';
import './AddDetailsModal.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AddDetailsModal = ({ bg, showModal, setShowModal, setProfile, setChecked }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(new FormData());
  const handleSaveChanges = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      // For personal details
      let personalDetailsFormData = new FormData();
      personalDetailsFormData.append('firstName', formData.get('firstName'));
      personalDetailsFormData.append('lastName', formData.get('lastName'));
      personalDetailsFormData.append('email', formData.get('email'));
      personalDetailsFormData.append('phone', formData.get('phone'));
      personalDetailsFormData.append('profilePicture', formData.get('profilePicture'));

      await axios.post(
        'https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/AddDetails',
        personalDetailsFormData,
        config
      );

      // For company details
      let companyDetailsFormData = new FormData();
      companyDetailsFormData.append('companyLegalName', formData.get('companyLegalName'));
      companyDetailsFormData.append('companyType', formData.get('companyType'));
      companyDetailsFormData.append('year', formData.get('year'));
      companyDetailsFormData.append('industry', formData.get('industry'));
      companyDetailsFormData.append('billingEmail', formData.get('billingEmail'));
      companyDetailsFormData.append('companyPhone', formData.get('companyPhone'));
      companyDetailsFormData.append('companyWebsite', formData.get('companyWebsite'));
      companyDetailsFormData.append('logoImage', formData.get('logoImage'));
      companyDetailsFormData.append('documentFile', formData.get('documentFile'));
      companyDetailsFormData.append('qualityCertification', formData.get('qualityCertification'));
      companyDetailsFormData.append('selectedCountry', formData.get('selectedCountry'));
      companyDetailsFormData.append('selectedState', formData.get('selectedState'));
      companyDetailsFormData.append('selectedCity', formData.get('selectedCity'));
      companyDetailsFormData.append('registeredAddress', formData.get('registeredAddress'));
      companyDetailsFormData.append('postalCode', formData.get('postalCode'));
      companyDetailsFormData.append('coordinatesX', formData.get('coordinatesX'));
      companyDetailsFormData.append('coordinatesY', formData.get('coordinatesY'));

      await axios.post(
        'https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplier',
        companyDetailsFormData,
        config
      );

      setShowModal(false);
      setProfile('supplier')
      setChecked(true);
      navigate('/supplier/dashboard');
    } catch (error) {
      console.error(error);
    }
  };



  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={showModal ? 'AddDetailmodal display-block' : 'AddDetailmodal display-none'}>
      <div className='main-detail-container' style={{ background: bg, position: 'relative', top: '100px' }}>
        <div className='flex' style={{ justifyContent: "space-between" }}>
          <h2 style={{ margin: '3% 0 0 2%', color: '#0D4C7A' }}>Add Personal Details</h2>
          <CloseRoundedIcon onClick={handleCloseModal} />
        </div>
        <DetailsForm formData={formData} setFormData={setFormData} />
        <div className="button-container" style={{ float: 'right' }}>
          <button className="cancel-button btn-outline" onClick={handleCloseModal}>Cancel</button>
          <button className="save-button btn-fill" onClick={handleSaveChanges}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default AddDetailsModal;
