import React, { useState } from 'react';
import EditDetailsModal from '../../components/supplier/EditDetailsModal';
import ProfileDetailsModal from '../../components/supplier/ProfileDetailsModal';


const MyProfile = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className='right-container'  style={{ position: 'relative', width: '80%', height: '100vh', top: '0px', left: '20%' }}>
                {(showModal) ?
                    <EditDetailsModal bg={"#F8F8F8"} showModal={showModal} setShowModal={setShowModal} />
                    : <>
                        <ProfileDetailsModal  setShowModal={setShowModal}/>
                    </>
                }

            </div>
        </>
    )
}

export default MyProfile
