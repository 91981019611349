import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './ShowMachines.css';
import BuyerMachineCard from './BuyerMachineCard';
import MessageModal from '../auth/MessageModal';
import axios from 'axios';

const locationOptions = [
    { value: "02km", label: "02 km" },
    { value: "05km", label: "05 km" },
    { value: "10km", label: "10 km" },
    { value: "15km", label: "15 km" },
];

const accuracyOptions = [
    { value: "0.001", label: "0.001 mm" },
    { value: "0.002", label: "0.002 mm" },
    { value: "0.003", label: "0.003 mm" },
    { value: "0.01", label: "0.01 mm" },
    { value: "0.02", label: "0.02 mm" },
];

const capacityOptions = [
    { value: "100*500*400", label: "100*400*500mm" },
    { value: "200*300*800", label: "200*300*800mm" },
];

const costOptions = [
    { value: "500", label: "Upto Rs 500" },
    { value: "1000", label: "Upto Rs 1000" },
    { value: "5000", label: "Upto Rs 5000" },
    { value: "10000", label: "Upto Rs 10000" },
];

const purchaseYears = [];
const currentYear = new Date().getFullYear();
const startYear = 1900;
for (let i = currentYear; i >= startYear; i--) {
    purchaseYears.push(i);
}

const yearOptions = purchaseYears.map((yearOptions) => ({
    value: yearOptions,
    label: yearOptions.toString()
}));

const durationOptions = [
    { value: "5", label: "5 hrs" },
    { value: "10", label: "10 hrs" },
    { value: "15", label: "15 hrs" },
    { value: "20", label: "20 hrs" },
    { value: "24", label: "24 hrs" },
];

const ShowMachines = ({ allMachines, duration, selectedDate, requiredMachineType, fromTime, toTime, setSelectedDate, setFromTime, setToTime, setDuration, setRequiredMachineType, setSearchClicked }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [filteredMachines, setFilteredMachines] = useState([]);
    const [buyerCoordinates, setBuyerCoordinates] = useState({
        x: null,
        y: null
    });
    const handleSelectChange = (option, selectName) => {
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [selectName]: option,
        }));
    };

    const [buyer, setBuyer] = useState({ id: '', name: '', city: '' });

    useEffect(() => {
        axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/buyerInfo')
            .then(response => {
                setBuyerCoordinates(response.data.coordinates);
                setBuyer({ id: response.data.buyerId, name: response.data.buyerName, city: response.data.buyerLocation })
            })
            .catch(error => {
                console.error(error);
            });
    }, [])

    const CalculateDistance = (supplierCoordinates) => {
        const earthRadiusKm = 6371; // Earth's radius in kilometers
        const lat1 = degreesToRadians(buyerCoordinates.x);
        const lon1 = degreesToRadians(buyerCoordinates.y);
        const lat2 = degreesToRadians(supplierCoordinates.x);
        const lon2 = degreesToRadians(supplierCoordinates.y);

        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadiusKm * c;

        return Math.round(distance);
    };

    const degreesToRadians = (degrees) => {
        return (degrees * Math.PI) / 180;
    };

    const formattedDate = selectedDate[0] && selectedDate[0].toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    useEffect(() => {
        const handleFilterMachines = () => {
            let newFilteredMachines = allMachines;

            //location filter
            if (selectedOptions.location) {
                const maxDistance = Number(selectedOptions.location.value.substring(0, 2));
                newFilteredMachines = newFilteredMachines.filter((machineData) => {
                    if (machineData && machineData.coordinates) {
                        const distance = CalculateDistance(machineData.coordinates);
                        return distance <= maxDistance;
                    }
                    return false;
                });
            }

            //Accuracy filter
            if (selectedOptions.machineAccuracy) {
                const maxMachineAccuracy = Number(selectedOptions.machineAccuracy.value);
                newFilteredMachines = newFilteredMachines.filter((machineData) => {
                    if (machineData && machineData.machine.machineCost) {
                        const tolerance = machineData.machine.tolerance
                        return tolerance <= maxMachineAccuracy;
                    }
                    return false;
                });
            }
            //Cost filter
            if (selectedOptions.machineingCost) {
                const maxCost = Number(selectedOptions.machineingCost.value);
                newFilteredMachines = newFilteredMachines.filter((machineData) => {
                    if (machineData && machineData.machine.machineCost) {
                        const machineCost = machineData.machine.machineCost
                        return machineCost <= maxCost;
                    }
                    return false;
                });
            }

            //Capacity filter
            //Year filter
            if (selectedOptions.establishmentYear) {
                const minYear = Number(selectedOptions.establishmentYear.value);
                newFilteredMachines = newFilteredMachines.filter((machineData) => {
                    if (machineData && machineData.establishmentYear) {
                        const establishmentYear = machineData.establishmentYear
                        return establishmentYear >= minYear;
                    }
                    return false;
                });
            }
            //Duration filter
            if (selectedOptions.duration) {
                const minDuration = Number(selectedOptions.duration.value);
                newFilteredMachines = newFilteredMachines.filter((machineData) => {
                    if (machineData && machineData.machine.totalAvailableHrs) {
                        const availableDuration = machineData.machine.totalAvailableHrs
                        return availableDuration >= minDuration;
                    }
                    return false;
                });
            }
            setFilteredMachines(newFilteredMachines);
        };

        handleFilterMachines();
        // eslint-disable-next-line
    }, [selectedOptions, allMachines]);

    const closeModal = () => {
        setShowModal(false);
    };

    const handleBookMachine = () => {
        const currentTime = Date.now();
        const requests = filteredMachines.map(machineData => ({
            requestId: `${buyer.id}-${currentTime}`,
            dateMade: new Date(),
            fromDate: selectedDate[0],
            toDate: selectedDate[1],
            fromTime: fromTime.getTime(),
            toTime: toTime.getTime(),
            availability: duration,
            rentalCost: duration * machineData.machine.machineCost,
            machine: machineData.machine._id,
            buyerName: buyer.name,
            buyerLocation: buyer.city,
            supplier: machineData.machine.supplier,
            machineName: machineData.machine.machineName,
            machineType: machineData.machine.machineType
        }));

        requests.forEach(request => {
            axios.post('https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/bookingRequests', { buyer: buyer.id, ...request })
                .then(response => {
                    // setFilteredMachines([]);
                    setShowModal(true);
                })
                .catch(error => {
                    console.error(error);
                });
        });
    };

    return (
        <>
            <div style={{ position: 'fixed', left: '0px', zIndex: '1000', top: '0px' }}>
                {showModal &&
                    <MessageModal
                        showModal={showModal}
                        closeModal={closeModal}
                        message={"Successful!"}
                        detail={"Booking Request Sent Successfully"}
                        navigateto={"/buyer/dashboard"}
                        setSelectedDate={setSelectedDate}
                        setFromTime={setFromTime}
                        setToTime={setToTime}
                        setDuration={setDuration}
                        setRequiredMachineType={setRequiredMachineType}
                        setSearchClicked={setSearchClicked}
                    />
                }
            </div>
            <div style={{ paddingBottom: '5%' }}>
                {requiredMachineType && selectedDate && selectedDate.length > 1 && fromTime && toTime ? <> {allMachines.length !== 0 ? <>
                    <p style={{ color: '#585858' }}>
                        Showing Results for <b>{requiredMachineType} machines available on {formattedDate} for {duration} hours</b>
                    </p>

                    <div className="filter-container">
                        <div className="textOnInput">
                            <label htmlFor="select" className="filter-label-text">
                                Sort By
                            </label>
                            <Select
                                options={locationOptions}
                                id="selectLocation"
                                placeholder="Location"
                                className="select-filter-input"
                                value={selectedOptions['location']}
                                onChange={(option) => handleSelectChange(option, 'location')}
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="textOnInput">
                            <Select
                                options={accuracyOptions}
                                id="selectAccuracy"
                                placeholder="Machine Accuracy"
                                className="select-filter-input"
                                value={selectedOptions['machineAccuracy']}
                                onChange={(option) => handleSelectChange(option, 'machineAccuracy')}
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="textOnInput">
                            <Select
                                options={costOptions}
                                id="selectCost"
                                placeholder="Machineing/hr cost"
                                className="select-filter-input"
                                value={selectedOptions['machineingCost']}
                                onChange={(option) => handleSelectChange(option, 'machineingCost')}
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="textOnInput">
                            <Select
                                options={capacityOptions}
                                id="selectCapacity"
                                placeholder="Capacity of Machine"
                                className="select-filter-input"
                                value={selectedOptions['machineCapacity']}
                                onChange={(option) => handleSelectChange(option, 'machineCapacity')}
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="textOnInput">
                            <Select
                                options={yearOptions}
                                id="selectYear"
                                placeholder="Year of Establishment"
                                className="select-filter-input"
                                value={selectedOptions['establishmentYear']}
                                onChange={(option) => handleSelectChange(option, 'establishmentYear')}
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="textOnInput">
                            <Select
                                options={durationOptions}
                                id="selectDuration"
                                placeholder="Duration(hrs)"
                                className="select-filter-input"
                                value={selectedOptions['duration']}
                                onChange={(option) => handleSelectChange(option, 'duration')}
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                    </div>

                    <div className="buyer-machine-card-container flex-wrap">
                        <BuyerMachineCard allMachines={filteredMachines} CalculateDistance={CalculateDistance} />
                    </div>

                    <button className="btn-fill book-machine-btn" onClick={handleBookMachine}>Book Machine</button>
                </>
                    : <p style={{ color: '#585858' }}>
                        Sorry! No <b> {requiredMachineType} </b> Machines avaialable in the provided time Period</p>
                }</> : <p style={{ color: '#585858' }}>
                    Please Select proper <b> Machine Type, fromDate, toDate, toTime and FromTime{selectedDate.length === 0 && <>. <br /> If you have only selected fromDate Please select toDate as well, in case of single date click on same date twice. </>}</b></p>}
            </div>
        </>
    );
};

export default ShowMachines;
