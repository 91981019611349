import React, { useState } from 'react';
import axios from 'axios';
// import G from '../../assets/google.svg';
// import fb from '../../assets/fb.svg';
// import li from '../../assets/li.svg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import OtpModal from './OtpModal';

import './Register.css'
import { Link } from 'react-router-dom';

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [industry, setIndustry] = useState("");
    const [source, setSource] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");
    const [formErrors, setFormErrors] = useState({});

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleCheckboxChange1 = () => {
        setIsChecked1(!isChecked1);
    };

    function isValidPhoneNumber(phone) {
        const regEx = /^[0-9]{10}$/;
        return regEx.test(phone);
    }

    function isValidEmail(email) {
        const regEx = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return regEx.test(email);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formErrors = {};

        if (!firstName) formErrors.firstName = "First Name is required";
        if (!lastName) formErrors.lastName = "Last Name is required";
        if (!companyName) formErrors.companyName = "Company Name is required";
        if (!email) formErrors.email = "Email is required";
        if (!password) formErrors.password = "Password is required";
        if (!phone) formErrors.phone = "Phone Number is required";
        if (!isValidPhoneNumber(phone)) {
            formErrors.phone = 'Phone number must be a 10-digit numeric value';
        }
        if (!isValidEmail(email)) {
            formErrors.email = 'Please enter a valid email';
        }
        setFormErrors(formErrors);

        // Check if formErrors is empty, if it is, proceed with form submission
        if (Object.keys(formErrors).length === 0) {
            try {
                const registerData = {
                    firstName,
                    companyName,
                    phone,
                    password,
                    lastName,
                    email,
                    industry,
                    source,
                };
                await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/", registerData, {
                    withCredentials: true,
                });
                openModal();
            } catch (err) {
                console.error(err);
                if (err.response && err.response.data && err.response.data.errorMessage) {
                    setError(err.response.data.errorMessage);
                }
            }
        }
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (<>
        {showModal &&
            <OtpModal
                showModal={showModal}
                closeModal={closeModal}
                email={email}
                phone={phone}
                password={password}
                firstName={firstName}
                companyName={companyName}
                lastName={lastName}
                industry={industry}
                source={source}
            />
        }
        <div className="register-page">
            <h1 className="heading">Sign Up</h1>

            <form onSubmit={handleSubmit} className='register-form'>
                <div className="column-container" style={{}}>
                    <div className="col">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label className="label">First Name<span style={{ color: "red" }}>*</span></label>
                            <input
                                className='input-field'
                                type='text'
                                placeholder='Text'
                                name='firstName'
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                            />
                            {formErrors.firstName && <div style={{ color: "red" }}>{formErrors.firstName}</div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label className="label">Company Name<span style={{ color: "red" }}>*</span></label>
                            <input
                                className='input-field'
                                type='text'
                                placeholder='Text'
                                name='companyName'
                                onChange={(e) => setCompanyName(e.target.value)}
                                value={companyName}
                                style={{ marginBottom: "30px" }}
                            />
                            {formErrors.companyName && <div style={{ color: "red" }}>{formErrors.companyName}</div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label className="label">Phone Number<span style={{ color: "red" }}>*</span></label>
                            <input
                                className='input-field'
                                type='tel'
                                placeholder='Text'
                                name='phone'
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                            />
                            <button className='icon'>
                                <BsTelephone />
                            </button>
                            {formErrors.phone && <div style={{ color: "red" }}>{formErrors.phone}</div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: '115%' }}>
                            <label className="label" htmlFor='industry'>Industry</label>
                            <select className='input-field' id='industry' name='industry' onChange={(e) => setIndustry(e.target.value)} value={industry}>
                                <option value=''>Select an industry</option>
                                <option value='automotive'>Automotive</option>
                                <option value='automation'>Automation</option>
                                <option value='aerospace'>Aerospace</option>
                                <option value='defense'>Defense</option>
                                <option value='drone'>Drone</option>
                                <option value='chemical'>Chemical</option>
                                <option value='electric-vehicles'>Electric Vehicles</option>
                                <option value='fmcg'>FMCG</option>
                                <option value='medical-technology'>Medical Technology</option>
                                <option value='robotics'>Robotics</option>
                                <option value='energy'>Energy</option>
                                <option value='consumer-goods-services'>Consumer Goods & Services</option>
                                <option value='health'>Health</option>
                                <option value='industrial'>Industrial</option>
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label className="label">Last Name<span style={{ color: "red" }}>*</span></label>
                            <input
                                className='input-field'
                                type='text'
                                placeholder='Text'
                                name='lastName'
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                            />
                            {formErrors.lastName && <div style={{ color: "red" }}>{formErrors.lastName}</div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label className="label">Email Address<span style={{ color: "red" }}>*</span></label>
                            <input
                                className='input-field'
                                type='email'
                                placeholder='Text'
                                name='email'
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            <button className='icon'>
                                <AiOutlineMail />
                            </button>
                            {formErrors.email && <div style={{ color: "red" }}>{formErrors.email}</div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label className="label">Password<span style={{ color: "red" }}>*</span></label>
                            <input
                                className='input-field'
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Text'
                                name='password'
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                            <button type="button" className="password-toggle-btn" onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                            {formErrors.password && <div style={{ color: "red" }}>{formErrors.password}</div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: '115%' }}>
                            <label className="label" htmlFor='source'>Where Did you get to know about us:</label>
                            <select className='input-field' id='source' name='source' onChange={(e) => setSource(e.target.value)} value={source}>
                                <option value=''>Select</option>
                                <option value='finance'>Social Media</option>
                                <option value='technology'>Friend</option>
                                <option value='healthcare'>Adds</option>
                                <option value='retail'>Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex column-container" style={{ justifyContent: 'space-between', width: '90%', flexWrap: 'wrap' }}>
                        <div>
                            <input
                                type="checkbox"
                                id="agreement-checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="agreement-checkbox" className='agreement-checkbox'>
                                By creating an account you agree to the <br />
                                <a target="_blank" rel="noreferrer" href="https://ocular-manufacturing-company-docs.s3.ap-south-1.amazonaws.com/Ocular+-+Terms+%26+Conditions.pdf">Terms & Conditions </a>
                                and our
                                <a target="_blank" rel="noreferrer" href="https://ocular-manufacturing-company-docs.s3.ap-south-1.amazonaws.com/Ocular+-+Privacy+Policy.pdf"> Privacy Policy </a>.
                            </label>
                        </div>
                        <div >
                            <input
                                type="checkbox"
                                id="agreement-checkbox1"
                                checked={isChecked1}
                                onChange={handleCheckboxChange1}
                            />
                            <label htmlFor="agreement-checkbox1" className='agreement-checkbox' >
                                Send new updates from <br /> <a href="#/">Occularmanufacturing.com</a>
                            </label>
                        </div>
                    </div>
                </div>
                {error && <div> {error} </div>}
                <input type='submit' value='Create Account' className='submit-btn' />
            </form>
            {/* <div className="container">
                <div className="line"></div>
                <div className="text">Or</div>
                <div className="line"></div>
            </div>

            <div className="btn-grp">
                <button className="btn-social"> <img src={G} alt="social-icon" /> Google</button>
                <button className="btn-social"> <img src={fb} alt="social-icon" /> Facebook</button>
                <button className="btn-social"> <img src={li} alt="social-icon" /> LinkedIn</button>

            </div> */}

            <div className='agreement-checkbox'>
                Already have an account?  <Link to="/login">Login</Link>
            </div>
        </div>

    </>
    );
}
export default Register;
