import React from 'react';
import m1 from '../../assets/m1.png';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BuyerMachineCard = ({ allMachines, CalculateDistance }) => {
    const carouselSettings = {
        dots: true,
        dotsClass: 'slick-dots',
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className='main-buyer-card-container'>
            {allMachines.map((machineData, index) => {
                const fromDateTimeUTC = new Date(machineData.machine.availableFromDateTime);
                const toDateTimeUTC = new Date(machineData.machine.availableToDateTime);
                const options = { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' };
                const fromDateTimeIST = fromDateTimeUTC.toLocaleString('en-IN', options);
                const toDateTimeIST = toDateTimeUTC.toLocaleString('en-IN', options);

                return (

                    <div className='buyer-machine-card flex-wrap' key={index}>
                        {machineData.machine.machinePictures && machineData.machine.machinePictures.length > 0 ? (
                            <Slider {...carouselSettings}>
                                {machineData.machine.machinePictures.map((picture, pictureIndex) => (
                                    <div className='buyer-card-img-container flex-wrap' key={pictureIndex}>
                                        <img src={picture} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                ))}
                            </Slider>)
                            : (
                                <Slider {...carouselSettings}>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                    <div className='buyer-card-img-container flex-wrap'>
                                        <img src={m1} alt="machineImg" className='buyer-card-machine-img' />
                                    </div>
                                </Slider>
                            )}

                        <div className='buyer-card-detail-container flex-wrap' >
                            <div className='detail-section-1'>
                                <div>
                                    <h4 className='machineSubtype'>{machineData.machine.machineType ? machineData.machine.machineType : 'Machine Type'} ( {machineData.machine.machineName ? machineData.machine.machineName : 'Machine Name'} )</h4>
                                </div>
                            </div>
                            <div className='detail-section-2'>
                                <div className='prop-details'>
                                    <p className='propName'>Tolerance</p>
                                    <p className='propValue'>{machineData.machine.tolerance && machineData.machine.tolerance} mm Tolerance</p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Bed Size</p>
                                    <p className='propValue'>{machineData.machine.machineCapacity && machineData.machine.machineCapacity}mm</p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Available Time</p>
                                    <p className='propValue' > <b>
                                        {fromDateTimeIST} - {toDateTimeIST} {machineData.machine.totalAvailableHrs && `( ${machineData.machine.totalAvailableHrs}hrs )`}</b>
                                    </p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Price</p>
                                    <p className='propValue'>{machineData.machine.machineCost && machineData.machine.machineCost} Rs/hr</p>
                                </div>
                                <div className='prop-details'>
                                    <p className='propName'>Location</p>
                                    <p className='location-distance propValue'>
                                        <PinDropIcon style={{ fill: '#585858' }} />
                                        <p>{CalculateDistance(machineData.coordinates)} km Away {machineData.address}</p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            
        </div>
    );
};

export default BuyerMachineCard;