import React, { useState, useEffect } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import m1 from '../../assets/sample.png';
import axios from 'axios';

const SupplierMachineCard = ({ myMachines, handleDelete, handleEdit }) => {
    const [selectedMachineIndex, setSelectedMachineIndex] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [totalAvailableHrs, setTotalAvailableHrs] = useState([]);
    const [notAvailable, setNotAvailable] = useState([]);
    const [machineAvailability, setMachineAvailability] = useState(
        myMachines.map(() => ({
            fromDate: '',
            toDate: '',
            fromTime: '',
            toTime: '',
        }))
    );
    const [availabilityStatus, setAvailabilityStatus] = useState([]);
    const [error, setError] = useState([]);

    const carouselSettings = {
        dots: true,
        dotsClass: 'slick-dots',
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        const initializeAvailabilityStatus = myMachines.map(() => 'pending');
        setAvailabilityStatus(initializeAvailabilityStatus);

        const initializeTotalAvailableHrs = myMachines.map(() => 0);
        setTotalAvailableHrs(initializeTotalAvailableHrs);

        const initializeNotAvailable = myMachines.map(() => false);
        setNotAvailable(initializeNotAvailable);

        // Set the values based on machine data
        const initializeValuesFromMachine = myMachines.map((machine) => {
            const { availableFromDate, availableToDate, availableFromTime, availableToTime, availabilityStatus } = machine;
            // Check if the date is valid
            let fromDate = '';
            if (availableFromDate && availableFromDate !== 'Invalid date' && availabilityStatus !== 'not Available' && availabilityStatus !== 'pending' ) {
                const parsedFromDate = new Date(availableFromDate);
                if (!isNaN(parsedFromDate.getTime())) {
                    const year = parsedFromDate.getFullYear();
                    const month = String(parsedFromDate.getMonth() + 1).padStart(2, '0');
                    const date = String(parsedFromDate.getDate()).padStart(2, '0');
                    fromDate = `${year}-${month}-${date}`;
                }
            }

            let toDate = '';
            if (availableToDate && availableToDate !== 'Invalid date' && availabilityStatus !== 'not Available'  && availabilityStatus !== 'pending' ) {
                const parsedToDate = new Date(availableToDate);
                if (!isNaN(parsedToDate.getTime())) {
                    const year = parsedToDate.getFullYear();
                    const month = String(parsedToDate.getMonth() + 1).padStart(2, '0');
                    const date = String(parsedToDate.getDate()).padStart(2, '0');
                    toDate = `${year}-${month}-${date}`;
                }
            }

            const fromTime = isValidTime(availableFromTime)  && availabilityStatus !== 'not Available'  && availabilityStatus !== 'pending'  ? availableFromTime : '';
            const toTime = isValidTime(availableToTime)  && availabilityStatus !== 'not Available'  && availabilityStatus !== 'pending'  ? availableToTime : '';
            return {
                fromDate,
                toDate,
                fromTime,
                toTime,
            };
        });

        function isValidTime(time) {
            const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
            return timeRegex.test(time);
        }

        setMachineAvailability(initializeValuesFromMachine);

        const initializeStatusFromMachine = myMachines.map((machine) => machine.availabilityStatus || 'pending');
        setAvailabilityStatus(initializeStatusFromMachine);

        const initializeHrsFromMachine = myMachines.map((machine) => machine.totalAvailableHrs || 0);
        setTotalAvailableHrs(initializeHrsFromMachine);

        const initializeNotAvailableFromMachine = myMachines.map(
            (machine) => !machine.availableFromDate && !machine.availableToDate && !machine.availableFromTime && !machine.availableToTime
        );
        setNotAvailable(initializeNotAvailableFromMachine);
    }, [myMachines]);

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    const calculateMachineAge = (purchaseYear) => {
        const currentYear = getCurrentYear();
        return currentYear - purchaseYear;
    };

    const calculateAvailableHours = (availability) => {

        if (!availability || !availability.fromTime || !availability.toTime || availability.fromTime === 'NaN' || availability.toTime === 'NaN') {
            return 0;
        }
        const { fromTime, toTime } = availability;
        const [fromHour, fromMinute] = fromTime.split(':').map(Number);
        const [toHour, toMinute] = toTime.split(':').map(Number);

        let hours = toHour - fromHour;
        let minutes = toMinute - fromMinute;

        if (hours < 0) {
            hours += 24; // Add 24 hours for overnight intervals
        }

        if (minutes < 0) {
            hours--;
            minutes += 60;
        }

        hours = Math.max(hours, 0); // Ensure positive hours

        return Math.floor(hours + minutes / 60); // Convert to whole number
    };

    const calculateAvailableDays = (availability) => {
        if (!availability || !availability.fromDate || !availability.toDate) {
            return 0;
        }
        const { fromDate, toDate } = availability;
        const fromDateTime = new Date(fromDate);
        const toDateTime = new Date(toDate);
        const timeDiff = Math.abs(toDateTime - fromDateTime);
        const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        return days;
    };

    const calculateRentalCost = (availability, machine) => {
        const hours = calculateAvailableHours(availability);
        const days = calculateAvailableDays(availability);

        if (days > 0) {
            return hours * (days+1) * machine.machineCost;
        } else if (hours > 0) {
            return hours * machine.machineCost;
        }
        else return 0;
    };

    const handleAvailabilityChange = (event, machineIndex) => {
        const { name, value } = event.target;

        setMachineAvailability((prevState) => {
            const updatedAvailability = [...prevState];
            updatedAvailability[machineIndex] = {
                ...updatedAvailability[machineIndex],
                [name]: value,
            };

            if (name === 'fromDate') {
                const fromDate = new Date(value);
                const toDateInput = document.getElementById(`to-date-${machineIndex}`);
                toDateInput.min = fromDate.toISOString().split('T')[0];
            }

            if (name === 'fromDate' || name === 'toDate' || name === 'fromTime') {
                updatedAvailability[machineIndex].toTime = '';
            }

            if (name === 'toTime') {
                const fromTimeValue = updatedAvailability[machineIndex].fromTime;
                if (!fromTimeValue) {
                    alert("set from time first");
                    updatedAvailability[machineIndex].toTime = '';
                }
            }
            return updatedAvailability;
        });
    };

    const calculateTotalAvailableHours = (availability) => {
        const days = calculateAvailableDays(availability);
        const hours = calculateAvailableHours(availability);
        if (days !== 0) return days * hours;
        else return hours;
    };

    const handleSetAvailablilityClick = async (index, fromDateValue, toDateValue, fromTimeValue, toTimeValue) => {
        const formData = new FormData();
        if (!fromDateValue || !toDateValue || !fromTimeValue || !toTimeValue) {
            setError((prevState) => {
                const updatedError = [...prevState];
                updatedError[index] = "Select Date and Time properly";
                return updatedError;
            });
        } else {
            setError((prevState) => {
                const updatedError = [...prevState];
                updatedError[index] = "";
                return updatedError;
            });

            setMachineAvailability((prevState) => {
                const updatedAvailability = [...prevState];
                updatedAvailability[index] = {
                    fromDate: fromDateValue,
                    toDate: toDateValue,
                    fromTime: fromTimeValue,
                    toTime: toTimeValue,
                };
                formData.append('machineIndex', index);
                formData.append('availableToDate', updatedAvailability[index].toDate);
                formData.append('availableFromTime', updatedAvailability[index].fromTime);
                formData.append('availableToTime', updatedAvailability[index].toTime);
                formData.append('availableFromDate', updatedAvailability[index].fromDate);

                setNotAvailable((prevState) => {
                    const updatedNotAvailable = [...prevState];
                    updatedNotAvailable[index] = false;
                    return updatedNotAvailable;
                });

                setTotalAvailableHrs((prevState) => {
                    const updatedTotalAvailableHrs = [...prevState];
                    updatedTotalAvailableHrs[index] = calculateTotalAvailableHours(
                        updatedAvailability[index]
                    );
                    formData.append('totalAvailableHrs', updatedTotalAvailableHrs[index]);
                    return updatedTotalAvailableHrs;
                });

                setAvailabilityStatus((prevState) => {
                    const updatedAvailabilityStatus = [...prevState];
                    updatedAvailabilityStatus[index] = "Available";
                    formData.append('availabilityStatus', updatedAvailabilityStatus[index]);
                    return updatedAvailabilityStatus;
                });

                setCurrentIndex(index)
                return updatedAvailability;
            });
        }
    };

    useEffect(() => {
        if (currentIndex !== null && machineAvailability[currentIndex] && notAvailable[currentIndex] !== undefined && availabilityStatus[currentIndex] === 'Available' &&
            totalAvailableHrs[currentIndex] && availabilityStatus[currentIndex]) {
            const formData = new FormData();

            formData.append('machineIndex', currentIndex);
            formData.append('availableToDate', machineAvailability[currentIndex].toDate);
            formData.append('availableFromTime', machineAvailability[currentIndex].fromTime);
            formData.append('availableToTime', machineAvailability[currentIndex].toTime);
            formData.append('availableFromDate', machineAvailability[currentIndex].fromDate);
            formData.append('totalAvailableHrs', totalAvailableHrs[currentIndex]);
            formData.append('availabilityStatus', availabilityStatus[currentIndex]);
            handleApiRequest(formData);
            setCurrentIndex(null)
        }
    }, [currentIndex, machineAvailability, notAvailable, totalAvailableHrs, availabilityStatus]);

    const handleApiRequest = async (formData) => {
        try {
            const apiUrl = 'https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/machines';

            await axios.put(apiUrl, formData);
        } catch (error) {
            console.error('Failed to save machines:', error);
        }
    }

    const handleNotAvailableClick = (index) => {
        const formData = new FormData();

        setError((prevState) => {
            const updatedError = [...prevState];
            updatedError[index] = "";
            return updatedError;
        });
        setMachineAvailability((prevState) => {
            const updatedAvailability = [...prevState];
            updatedAvailability[index] = {
                fromDate: '',
                toDate: '',
                fromTime: '',
                toTime: '',
            };
            formData.append('machineIndex', index);
            formData.append('availableToDate', updatedAvailability[index].toDate);
            formData.append('availableFromTime', updatedAvailability[index].fromTime);
            formData.append('availableToTime', updatedAvailability[index].toTime);
            formData.append('availableFromDate', updatedAvailability[index].fromDate);

            setNotAvailable((prevState) => {
                const updatedNotAvailable = [...prevState];
                updatedNotAvailable[index] = true;
                return updatedNotAvailable;
            });

            setTotalAvailableHrs((prevState) => {
                const updatedTotalAvailableHrs = [...prevState];
                updatedTotalAvailableHrs[index] = 0;
                formData.append('totalAvailableHrs', updatedTotalAvailableHrs[index]);
                return updatedTotalAvailableHrs;
            });

            setAvailabilityStatus((prevState) => {
                const updatedAvailabilityStatus = [...prevState];
                updatedAvailabilityStatus[index] = "not Available";
                formData.append('availabilityStatus', updatedAvailabilityStatus[index]);
                return updatedAvailabilityStatus;
            });
            setCurrentIndex(index)
            return updatedAvailability;
        });
    }

    const handleSetAvailableClick = (index) => {
        const formData = new FormData();

        setMachineAvailability((prevState) => {
            const updatedAvailability = [...prevState];
            updatedAvailability[index] = {
                fromDate: '',
                toDate: '',
                fromTime: '',
                toTime: '',
            };
            formData.append('machineIndex', index);
            formData.append('availableToDate', updatedAvailability[index].toDate);
            formData.append('availableFromTime', updatedAvailability[index].fromTime);
            formData.append('availableToTime', updatedAvailability[index].toTime);
            formData.append('availableFromDate', updatedAvailability[index].fromDate);

            setNotAvailable((prevState) => {
                const updatedNotAvailable = [...prevState];
                updatedNotAvailable[index] = false;
                return updatedNotAvailable;
            });

            setTotalAvailableHrs((prevState) => {
                const updatedTotalAvailableHrs = [...prevState];
                updatedTotalAvailableHrs[index] = 0;
                formData.append('totalAvailableHrs', updatedTotalAvailableHrs[index]);
                return updatedTotalAvailableHrs;
            });

            setAvailabilityStatus((prevState) => {
                const updatedAvailabilityStatus = [...prevState];
                updatedAvailabilityStatus[index] = "pending";
                formData.append('availabilityStatus', updatedAvailabilityStatus[index]);
                return updatedAvailabilityStatus;
            });
            setCurrentIndex(index)
            return updatedAvailability;
        });
    }

    useEffect(() => {
        if (notAvailable[currentIndex] !== undefined && (availabilityStatus[currentIndex] === 'pending' || availabilityStatus[currentIndex] === 'not Available')) {
            const formData = new FormData();

            formData.append('machineIndex', currentIndex);
            formData.append('availableToDate', '');
            formData.append('availableFromTime', '');
            formData.append('availableToTime', '');
            formData.append('availableFromDate', '');
            formData.append('totalAvailableHrs', 0);
            formData.append('availabilityStatus', availabilityStatus[currentIndex]);
            handleApiRequest(formData);
            setCurrentIndex(null)
        }
    }, [currentIndex, machineAvailability, notAvailable, totalAvailableHrs, availabilityStatus]);

    const determineAvailabilityButtons = (index) => {

        if (availabilityStatus[index] === 'Available') {
            return (
                <>
                    <button className="btn-outline" onClick={() => handleSetAvailablilityClick(index, machineAvailability[index].fromDate, machineAvailability[index].toDate, machineAvailability[index].fromTime, machineAvailability[index].toTime)} style={{ margin: '15px 0' }} >
                        Change Availability
                    </button>
                    <div>
                        <hr style={{ width: '250px', opacity: '0.4' }} />
                    </div>
                    <button className="btn-outline" onClick={() => handleNotAvailableClick(index)} style={{ margin: '15px 0' }} >
                        Not Available
                    </button>
                </>
            );
        } else if (availabilityStatus[index] === 'pending') {
            return (
                <>
                    <button className="btn-outline" onClick={() => handleSetAvailablilityClick(index, machineAvailability[index].fromDate, machineAvailability[index].toDate, machineAvailability[index].fromTime, machineAvailability[index].toTime)} style={{ margin: '15px 0' }} >
                        Set Availabille
                    </button>
                    <div>
                        <hr style={{ width: '250px', opacity: '0.4' }} />
                    </div>
                    <button className="btn-outline" onClick={() => handleNotAvailableClick(index)} style={{ margin: '15px 0' }} >
                        Not Available
                    </button>

                </>
            );
        }
        else {
            return (
                <>
                    <button className="btn-fill" style={{ margin: '15px 0' }} onClick={() => handleSetAvailableClick(index)}>
                        Set Availability
                    </button>
                </>
            );
        }
    };

    const handleDropdownToggle = (index) => {
        setSelectedMachineIndex(index);
        setShowDropdown(!showDropdown);
    };



    return (
        <div className="main-card-container">
            {myMachines.map((machine, index) => (
                <div key={index} className="machine-card">
                    <section
                        className="card-availability"
                        style={{
                            background:
                                (availabilityStatus[index] === 'Available')
                                    ? '#0F9D58'
                                    : (availabilityStatus[index] === 'not Available')
                                        ? '#CD0000'
                                        : '#0D4C7A',
                        }}
                    >
                        {(availabilityStatus[index] === 'Available')
                            ? (
                                <>Available Time {totalAvailableHrs[index]} hrs</>
                            ) : (availabilityStatus[index] === 'not Available')
                                ? (
                                    <>Not Available</>
                                ) : (
                                    <>Set Available Time</>
                                )}
                    </section>
                    <section
                        className="card-section1"
                        style={{
                            background:
                                (availabilityStatus[index] === 'Available')
                                    ? 'rgb(15, 157, 88, 0.1)'
                                    : (availabilityStatus[index] === 'not Available')
                                        ? 'rgba(205, 0, 0, 0.1)'
                                        : 'rgba(13, 76, 122, 0.1)',
                        }}
                    >
                        <div className="card-sub-section1">
                            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{machine.machineType}</p>
                            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{machine.machineName}</p>
                            <p>{machine.machineModel}</p>
                        </div>
                        <div className='flex' style={{ alignItems: 'start' }}>
                            {showDropdown && selectedMachineIndex === index && (
                                <div className="dropdown-menu" style={{ backgroundColor: 'white', padding: '5px 30px', marginTop: '20px', marginRight: '-20px', zIndex: '2' }}>
                                    <button className='flex btn' style={{ justifyContent: 'start', marginTop: '10px' }} onClick={() => handleEdit(machine)}><ModeEditOutlineOutlinedIcon /> Edit</button>
                                    <button className='flex btn' style={{ justifyContent: 'start', marginTop: '10px' }} onClick={() => handleDelete(machine)}><DeleteForeverOutlinedIcon /> Delete</button>
                                </div>
                            )}
                            <MoreVertOutlinedIcon onClick={() => handleDropdownToggle(index)} />
                        </div>
                    </section>

                    <section className="card-section2">
                        {myMachines[index].machinePictures && myMachines[index].machinePictures.length > 0 ? (
                            <Slider {...carouselSettings}>
                                {myMachines[index].machinePictures.map((picture, pictureIndex) => (
                                    <div key={pictureIndex} className="carousel-image">
                                        <img src={picture} alt="machineImg" width={'300px'} height={'150px'} />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <Slider {...carouselSettings}>
                                <div className="carousel-image">
                                    <img src={m1} alt="machineImg" width={'300px'} height={'150px'} />
                                </div>
                                <div className="carousel-image">
                                    <img src={m1} alt="machineImg" width={'300px'} height={'150px'} />
                                </div>
                                <div className="carousel-image">
                                    <img src={m1} alt="machineImg" width={'300px'} height={'150px'} />
                                </div>
                                <div className="carousel-image">
                                    <img src={m1} alt="machineImg" width={'300px'} height={'150px'} />
                                </div>
                            </Slider>
                        )}

                        <div className="card-sub-section2">
                            <div className="flex" style={{ justifyContent: 'space-between', marginTop: '15%' }}>
                                <div className="machine-sub-details">
                                    <p style={{ fontWeight: 'bold' }}>{machine.machineCapacity}</p>
                                    <p style={{ color: '#585858' }}>({machine.tolerance})</p>
                                </div>
                                <div className="machine-sub-details">
                                    <p style={{ fontWeight: 'bold' }}>{machine.machineCost} Rs/Hr</p>
                                    <p style={{ color: '#585858' }}>{calculateMachineAge(machine.purchaseYear)} Years Old</p>
                                </div>
                            </div>
                        </div>
                        <hr style={{ opacity: '0.4', marginTop: '5%' }} />
                        <div>
                            <div className="flex card-sub-section3" style={{ justifyContent: 'space-between', marginTop: '5%' }}>
                                <p>Availability</p>
                                <p>
                                    {calculateAvailableHours(machineAvailability[index])}Hrs/{calculateAvailableDays(machineAvailability[index])}Night
                                </p>
                            </div>
                            <div className="set-available-dateTime">
                                <div className="flex card-sub-section3" style={{ justifyContent: 'space-between', marginTop: '1%' }}>
                                    <div>
                                        <label className="datetime-label" htmlFor={`from-date-${index}`}>
                                            From Date:
                                        </label>
                                        <input
                                            type="date"
                                            id={`from-date-${index}`}
                                            name="fromDate"
                                            value={machineAvailability[index].fromDate}
                                            onChange={(event) => handleAvailabilityChange(event, index)}
                                            disabled={availabilityStatus[index] === 'not Available' ? true : false}
                                            min={new Date().toISOString().split('T')[0]} // Set the minimum date to the current date
                                            style={{ borderRight: '1px solid #D9D9D9' }}
                                            className="datetime-input"
                                        />
                                    </div>
                                    <div>
                                        <label className="datetime-label" htmlFor={`to-date-${index}`}>
                                            To Date:
                                        </label>
                                        <input
                                            type="date"
                                            id={`to-date-${index}`}
                                            name="toDate"
                                            value={machineAvailability[index].toDate}
                                            onChange={(event) => handleAvailabilityChange(event, index)}
                                            disabled={availabilityStatus[index] === 'not Available' ? true : false}
                                            className="datetime-input"
                                        />
                                    </div>
                                </div>
                                <div className="flex card-sub-section3" style={{ justifyContent: 'space-between', marginTop: '1%' }}>
                                    <div>
                                        <label className="datetime-label" htmlFor={`from-time-${index}`}>
                                            From Time:
                                        </label>
                                        <input
                                            type="time"
                                            id={`from-time-${index}`}
                                            name="fromTime"
                                            className="datetime-input"
                                            value={machineAvailability[index].fromTime}
                                            onChange={(event) => handleAvailabilityChange(event, index)}
                                            disabled={availabilityStatus[index] === 'not Available' ? true : false}
                                            style={{ borderRight: '1px solid #D9D9D9', padding: '9% 15%' }}
                                        />
                                    </div>
                                    <div>
                                        <label className="datetime-label" htmlFor={`to-time-${index}`}>
                                            To Time:
                                        </label>
                                        <input
                                            type="time"
                                            id={`to-time-${index}`}
                                            name="toTime"
                                            value={machineAvailability[index].toTime}
                                            onChange={(event) => handleAvailabilityChange(event, index)}
                                            disabled={availabilityStatus[index] === 'not Available' ? true : false}
                                            className="datetime-input"
                                            style={{ padding: '9% 15%' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex" style={{ justifyContent: 'space-between', marginTop: '5%', fontSize: '12px' }}>
                                    <p style={{ color: '#8A8894' }}>Rental Cost:</p>
                                    <p style={{ fontWeight: 'bold' }}>{calculateRentalCost(machineAvailability[index], machine)} / - Rs</p>
                                </div>
                                {error[index] && <p style={{ color: 'red', fontSize: '12px' }}>{error[index]}!!!</p>}
                                <div className="flex card-sub-section3" style={{ flexDirection: 'column', alignItems: 'center', margin: '1%' }}>
                                    {determineAvailabilityButtons(index)}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ))}
        </div>
    );
};

export default SupplierMachineCard;