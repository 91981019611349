import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import DeleteOtpModal from './DeleteOtpModal';
import './ProfileDetailsModal.css'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const ProfileDetailsModal = ({ setShowModal }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyLegalName, setCompanyLegalName] = useState("");
  const [industry, setIndustry] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [qualityCertification, setQualityCertification] = useState("");
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [logoImage, setLogoImage] = useState(null)

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/otp-generate", {
        emailOrPhone: email,
      });
      const { message } = response.data;
      if (message === 'OTP sent') {
        localStorage.setItem("emailOrPhone", email);
        openModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = () => {
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/details');
        const user = response.data;
        if (user) {
          setEmail(user.email);
          setPhone(user.phone);
          setFirstName(user.firstName);
          setLastName(user.lastName);
          if (user.profilePicture) {
            setProfilePicture(user.profilePicture)
          }
        }

        const supplier_response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplierDetails');
        const supplier = supplier_response.data;
        if (supplier) {
          setCompanyLegalName(supplier.companyLegalName);
          setIndustry(supplier.industry);
          setBillingEmail(supplier.billingEmail);
          setCompanyPhone(supplier.companyPhone);
          setCompanyWebsite(supplier.companyWebsite);
          setLogoImage(supplier.logoImage);
          setDocumentFile(supplier.documentFile);
          setQualityCertification(supplier.qualityCertification);
          setRegisteredAddress(supplier.registeredAddress);
          setPostalCode(supplier.postalCode);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserData();
  }, []);

  const handleEdit = () => {
    setShowModal(true);
  }

  return (
    <>
      {showDeleteModal &&
        <DeleteOtpModal
          showModal={openModal}
          closeModal={closeModal}
          email={email}
          phone={phone}
        />
      }
      <div className='SupplierProfileDetails' style={{ background: "#F8F8F8", width: '80%', margin: '2%', padding: '1% 3%' }}>
        <button onClick={handleEdit} className='edit-btn'>Edit</button>
        <div className="personal-info ">
          <h3>Personal information</h3>
          <div className="flex wrap" style={{ justifyContent: 'space-between' }}>
            <div className="flex wrap" style={{ gap: '20px' }}>
              <Stack direction="row" spacing={2}>
                {(profilePicture) ? <>
                  <Avatar src={profilePicture} style={{ background: '#0D4C7A', height: '60px', width: '60px' }} />
                </> :
                  <>
                    <Avatar src="/broken-image.jpg" sx={{ bgcolor: "#" }} size="large" />
                  </>
                }
              </Stack>
              <div className='field'>
                <p className='fieldName'>Full Name</p>
                <p className='fieldValue'>{firstName} {lastName}</p>
              </div>
            </div>
            <div className='field'>
              <p className='fieldName'>Email</p>
              <p className='fieldValue'>{email}</p>
            </div>

            <div className='field'>
              <p className='fieldName'>Phone</p>
              <p className='fieldValue'>{phone}</p>
            </div>
          </div>
        </div>
        <hr className="divider" />

        <div className="company-info">
          <h3>Company information</h3>
          <div className="flex wrap" style={{ justifyContent: 'space-between', alignItems: 'start' }}>
            <div className="flex wrap" style={{ gap: '20px', alignItems: 'start' }}>
              <Stack direction="row" spacing={2}>
                {(logoImage) ? <>
                  <Avatar
                    src={logoImage} style={{ background: '#0D4C7A', height: '60px', width: '60px' }}>
                  </Avatar>
                </> :
                  <>
                    <Avatar src="/broken-image.jpg" sx={{ bgcolor: "#" }} />
                  </>
                }
              </Stack>
              <div className="flex wrap" style={{ flexDirection: 'column', alignItems: 'start' }}>
                <div className='field'>
                  <p className='fieldName'>Organization's Legal Name</p>
                  <p className='fieldValue'>{companyLegalName}</p>
                  <div className='field'>
                    <p className='fieldName'>Website</p>
                    <p className='fieldValue'>{companyWebsite}</p>
                  </div>
                  <div className='field'>
                    <p className='fieldName'>Quality Certifications</p>
                    <p className='fieldValue'>{qualityCertification}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex wrap" style={{ flexDirection: 'column', alignItems: 'start' }}>
              <div className='field'>
                <p className='fieldName'>Billing Email ID</p>
                <p className='fieldValue'>{billingEmail}</p>
              </div>
              <div className='field'>
                <p className='fieldName'>Industry</p>
                <p className='fieldValue'>{industry}</p>
              </div>
            </div>

            <div className="flex wrap" style={{ flexDirection: 'column', alignItems: 'start' }}>
              <div className='field'>
                <p className='fieldName'>Company Number</p>
                <p className='fieldValue'>{companyPhone}</p>
              </div>
              <div className='field'>
                <p className='fieldName'>Company’s Profile / Certifications Document</p>
                {documentFile && documentFile.map((file, index) => (
                  <p key={index}>
                    <a className='flex' href={file.url} download target="_blank" rel="noopener noreferrer" style={{ justifyContent: 'space-between' }}> <span>{file.name}</span> <FileDownloadOutlinedIcon /></a>
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>

        <hr className="divider" />


        <div className="company-address">
          <h3>Company Address</h3>
          <div className='field'>
            <p className='fieldName'>Registered Office Address</p>
            <p className='fieldValue'>{registeredAddress},{postalCode}</p>
          </div>
        </div>


        <div className='btn-container' style={{ margin: '0 0% 5% 2%' }}>
          <button className="cancel-button btn-danger-outline" onClick={handleDeleteAccount} style={{ margin: '0 10px 0 0' }}>Delete Account</button>
          <Link to={`/password-reset?previous=${encodeURIComponent(window.location.pathname)}`}><button className="save-button btn-outline">Reset Password</button></Link>
        </div>
      </div>
    </>
  );
};

export default ProfileDetailsModal