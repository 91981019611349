import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BuyerNotifications = () => {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/buyerNotification`);
                const notifications = response.data;
                setNotifications(notifications);

            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();

        const intervalId = setInterval(fetchNotifications, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const handleNotificationClick = async (id) => {
        try {
            await axios.put(`https://ocular-manufacturing-server-production-d57e.up.railway.app/bookingAuth/notifications/${id}/read`);
            navigate('/buyer/buyerBooking')
        } catch (error) {
            console.error('Error updating notification status:', error);
        }
    };

    return (
        <div className='right-container' style={{ position: 'relative', width: '75%', top: '0px', left: '22%' }}>
            <h2>Notifications</h2>
            {notifications.length !== 0 ? notifications.map((notification, index) => (
                <div className='notification-container' key={index} onClick={() => handleNotificationClick(notification._id)}>
                    <p className='notification-date' >{new Date(notification.date).toLocaleDateString('en-GB')}</p>
                    <div className={notification.read ? 'notifications' : 'unread notifications'}>
                        <p >{notification.message}</p>
                        <p style={{ float: 'right', fontSize: '12px', color: 'gray' }}>
                            {new Date(notification.date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                        </p>
                    </div>
                </div>
            ))
                :
                <p>You Dont Have any Notifications</p>
            }
        </div>
    );
};

export default BuyerNotifications;
