import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DetailsForm.css';
import Select from "react-select";
import { Country, State, City } from 'country-state-city';
import attach from '../../assets/attach.svg'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

const DetailsForm = ({ formData, setFormData }) => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyLegalName, setCompanyLegalName] = useState("");
    const [companyType, setCompanyType] = useState("Select Company Type");
    const [year, setYear] = useState("");
    const [industry, setIndustry] = useState("Select Industry");
    const [billingEmail, setBillingEmail] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [qualityCertification, setQualityCertification] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCountryName, setSelectedCountryName] = useState("");
    const [selectedStateName, setSelectedStateName] = useState("");
    const [selectedCityName, setSelectedCityName] = useState("");
    const [registeredAddress, setRegisteredAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [profilePictureURL, setProfilePictureURL] = useState(null);
    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [logoImageURL, setLogoImageURL] = useState(null);
    const [logoImageFile, setLogoImageFile] = useState(null);
    const [documentFile, setDocumentFile] = useState([]);
    const [documentFileNames, setDocumentFileNames] = useState([]);
    const [fileUploadError, setFileuploadError] = useState("");
    const [profilePictureName, setProfilePictureName] = useState("");
    const [logoImageName, setLogoImageName] = useState("");
    const [coordinates, setCoordinates] = useState({
        x: null,
        y: null
    });

    useEffect(() => {
        setSelectedCountryName(selectedCountry ? selectedCountry.name : "")
        setSelectedStateName(selectedState ? selectedState.name : "")
        setSelectedCityName(selectedCity ? selectedCity.name : "")
    }, [selectedCountry, selectedState, selectedCity])

    useEffect(() => {
        const token = 'AAPK101e9adcf0414ca9a8ac6d925c9da9a1jdKh1b2xQxYananZQV00x2uWkvsuiNY_Z64pA_Zb7d5Qb5DqW9RIoGeKv-DD1KzU'
        axios.get(`https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=pjson&singleLine=${registeredAddress}&token=${token}`)
            .then(response => {
                const location = response.data.candidates[0].location;
                setCoordinates({
                    x: location.x,
                    y: location.y
                });
            })
            .catch(error => {
                console.error(error);
            });

    }, [registeredAddress])

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/details');
                const user = response.data;
                if (user) {
                    setEmail(user.email || "");
                    setPhone(user.phone || "");
                    setFirstName(user.firstName || "");
                    setLastName(user.lastName || "");
                    setProfilePictureURL(user.profilePicture || null);
                }

                const supplier_response = await axios.get('https://ocular-manufacturing-server-production-d57e.up.railway.app/supplierAuth/supplierDetails');
                const supplier = supplier_response.data;
                if (supplier) {
                    setCompanyLegalName(supplier.companyLegalName);
                    setCompanyType(supplier.companyType);
                    setYear(supplier.year);
                    setIndustry(supplier.industry);
                    setBillingEmail(supplier.billingEmail);
                    setCompanyPhone(supplier.companyPhone);
                    setCompanyWebsite(supplier.companyWebsite);
                    setLogoImageURL(supplier.logoImage || null);
                    setDocumentFile(supplier.documentFile.map(file => file.url));
                    setDocumentFileNames(supplier.documentFile.map(file => file.name));
                    setSelectedCountryName(supplier.selectedCountry);
                    setSelectedStateName(supplier.selectedState);
                    setSelectedCityName(supplier.selectedCity);

                    const allCountries = Country.getAllCountries();
                    const countryObject = allCountries.find(country => country.name === supplier.selectedCountry);

                    const allStates = State.getStatesOfCountry(countryObject?.isoCode);
                    const stateObject = allStates.find(state => state.name === supplier.selectedState);

                    const allCities = City.getCitiesOfState(stateObject?.countryCode, stateObject?.isoCode);
                    const cityObject = allCities.find(city => city.name === supplier.selectedCity);

                    setSelectedCountry(countryObject);
                    setSelectedState(stateObject);
                    setSelectedCity(cityObject);
                    setQualityCertification(supplier.qualityCertification);
                    setRegisteredAddress(supplier.registeredAddress);
                    setPostalCode(supplier.postalCode);
                    setCoordinates({
                        x: supplier.coordinates.x,
                        y: supplier.coordinates.y
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('companyLegalName', companyLegalName);
        formData.append('companyType', companyType);
        formData.append('year', year);
        formData.append('industry', industry);
        formData.append('billingEmail', billingEmail);
        formData.append('companyPhone', companyPhone);
        formData.append('companyWebsite', companyWebsite);
        formData.append('qualityCertification', qualityCertification);
        formData.append('selectedCountry', selectedCountryName);
        formData.append('selectedState', selectedStateName);
        formData.append('selectedCity', selectedCityName);
        formData.append('registeredAddress', registeredAddress);
        formData.append('coordinatesX', coordinates.x);
        formData.append('coordinatesY', coordinates.y);
        formData.append('postalCode', postalCode);
        if (profilePictureFile !== null) {
            formData.append('profilePicture', profilePictureFile);
        } else {
            formData.append('ExistingProfilePicture', profilePictureURL);
        }

        if (logoImageFile !== null) {
            formData.append('logoImage', logoImageFile);
        } else {
            formData.append('ExistingLogoImage', logoImageURL);
        }

        documentFile.forEach((file, index) => {
            if (typeof file !== "string") {  // New File
                formData.append('documentFile', file);
            } else {  // Existing File
                formData.append(`existingDocumentFile[${index}].url`, documentFile[index]);
                formData.append(`existingDocumentFile[${index}].name`, documentFileNames[index]);
            }
        });


        setFormData(formData);
        // eslint-disable-next-line
    }, [email, phone, firstName, lastName, companyLegalName, companyType, year, industry, billingEmail, companyPhone, companyWebsite, qualityCertification, selectedCountry, selectedState, selectedCity, registeredAddress, postalCode, logoImageFile, documentFile, profilePictureFile, documentFileNames, profilePictureURL, logoImageURL, selectedCountryName, selectedStateName, selectedCityName, coordinates]);

    const years = [];
    const currentYear = new Date().getFullYear();
    const startYear = 1900; // Set the start year as per your requirement
    for (let i = currentYear; i >= startYear; i--) {
        years.push(i);
    }

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            if (file.size <= 2 * 1024 * 1024) {
                setProfilePictureFile(file);
                setProfilePictureName(file.name);
            } else {
                // File size exceeds the limit
                setProfilePictureName("File size should be less than 2MB.");
            }
        } else {
            setProfilePictureFile(null);
            setProfilePictureName("");
        }
    };


    const handleLogoImageChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            if (file.size <= 2 * 1024 * 1024) {
                setLogoImageFile(file);
                setLogoImageName(file.name);
            }
            else {
                setLogoImageName("File size should be less than 2MB.");
            }
        } else {
            setLogoImageFile(null);
            setLogoImageName("");
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDropProfile = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            if (file.size <= 2 * 1024 * 1024) {
                setProfilePictureFile(file);
                setProfilePictureName(file.name);
            } else {
                // File size exceeds the limit
                setProfilePictureName("File size should be less than 2MB.");
            }
        } else {
            setProfilePictureFile(null);
            setProfilePictureName("");
        }
    };

    const handleDroplogo = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            if (file.size <= 2 * 1024 * 1024) {
                setLogoImageFile(file);
                setLogoImageName(file.name);
            }
            else {
                setLogoImageName("File size should be less than 2MB.");
            }
        } else {
            setLogoImageFile(null);
            setLogoImageName("");
        }
    };

    const handleDocumentChange = (e) => {
        const files = e.target.files;
        const updatedFiles = Array.from(files).filter(file => file.size <= 2 * 1024 * 1024);
        if (updatedFiles.length === files.length) {
            setDocumentFile(updatedFiles);
            setFileuploadError("");
        } else {
            setDocumentFile([]);
            setFileuploadError("File should be less than 2MB");
        }
    };

    const handleDropDocument = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        const updatedFiles = Array.from(files).filter(file => file.size <= 2 * 1024 * 1024);
        if (updatedFiles.length === files.length) {
            setDocumentFile(updatedFiles);
            setFileuploadError("");
        } else {
            setDocumentFile([]);
            setFileuploadError("File should be less than 2MB");
        }
    };

    const handleRemoveDocument = (index) => {
        if (typeof documentFile[index] === 'string') {

            const updatedFiles = [...documentFile];
            updatedFiles.splice(index, 1);
            setDocumentFile(updatedFiles);

            const updatedNames = [...documentFileNames];
            updatedNames.splice(index, 1);
            setDocumentFileNames(updatedNames);

        } else {
            const updatedFiles = [...documentFile];
            updatedFiles.splice(index, 1);
            setDocumentFile(updatedFiles);
        }
    };


    const companyTypeOptions = [
        { value: companyType, label: companyType },
        { value: 'Private Limited Company', label: 'Private Limited Company' },
        { value: 'Public Limited Company', label: 'Public Limited Company' },
        { value: 'Partnerships', label: 'Partnerships' },
        { value: 'Limited Liability Partnership', label: 'Limited Liability Partnership' },
        { value: 'One Person Company', label: 'One Person Company' },
        { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
        { value: 'Section 8 Company', label: 'Section 8 Company' },
    ];

    const industryOptions = [
        { value: industry, label: industry },
        { value: 'automotive', label: 'Automotive' },
        { value: 'automation', label: 'Automation' },
        { value: 'aerospace', label: 'Aerospace' },
        { value: 'defense', label: 'Defense' },
        { value: 'drone', label: 'Drone' },
        { value: 'chemical', label: 'Chemical' },
        { value: 'electric-vehicles', label: 'Electric Vehicles' },
        { value: 'fmcg', label: 'FMCG' },
        { value: 'medical-technology', label: 'Medical Technology' },
        { value: 'robotics', label: 'Robotics' },
        { value: 'energy', label: 'Energy' },
        { value: 'consumer-goods-services', label: 'Consumer Goods & Services' },
        { value: 'health', label: 'Health' },
        { value: 'industrial', label: 'Industrial' },
    ];

    const yearOptions = years.map((yearOption) => ({
        value: yearOption,
        label: yearOption.toString()
    }));

    return (
        <form className='detailsComponent' method="post" encType="multipart/form-data">
            <div className="personal-info">
                <h3>Personal information</h3>
                <div className="flex detail-row">
                    <div className="input-container">
                        <label className="label">First Name <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='firstName'
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                        />
                    </div>
                    <div className="input-container">
                        <label className="label">Last Name <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='lastName'
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                        />
                    </div>
                    <div className="input-container">
                        <label className="label">Email Id <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='email'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                </div>
                <div className="flex detail-row">
                    <div className="input-container">
                        <label className="label">Phone <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='phone'
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                        />
                    </div>
                    <div className="input-container">
                        <label className="label">Profile Picture </label>
                        <div className="profile-picture-container">
                            <input
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={handleProfilePictureChange}
                                id="profile-picture-input"
                                className="profile-picture-input"
                            />
                            <label
                                htmlFor="profile-picture-input"
                                onDragOver={handleDragOver}
                                onDrop={handleDropProfile}
                                className="profile-picture-label"
                            >
                                {profilePictureURL !== null && typeof profilePictureURL === "string" ?
                                    (
                                        <img
                                            src={profilePictureURL}
                                            alt={profilePictureName ? profilePictureName : "Profile picture"}
                                            className="profile-picture-preview"
                                        />

                                    ) : (
                                        <span className="profile-picture-placeholder flex">
                                            <img src={attach} alt="Attach" />
                                            <p>Or</p>
                                            <p>Drag & Drop image (Png, Jpeg) here</p>
                                        </span>
                                    )}
                            </label>
                        </div>
                        {profilePictureName && (
                            <p className="file-name">{profilePictureName}</p>
                        )}
                    </div>
                </div>
            </div>
            <hr className="divider" />

            <div className="company-info">
                <h3>Company information</h3>
                <div className="flex detail-row">
                    <div className="input-container">
                        <label className="label">Organization's Legal Name <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='companyLegalName'
                            onChange={(e) => setCompanyLegalName(e.target.value)}
                            value={companyLegalName}
                        />
                    </div>
                    <div className="input-container selectReact">
                        <label className="label" htmlFor='companyType'>Type of Company <span style={{ color: 'red' }}>*</span></label>
                        <Select
                            className='select'
                            id='companyType'
                            name='companyType'
                            options={companyTypeOptions}
                            onChange={(selectedOption) => setCompanyType(selectedOption.value)}
                            value={companyTypeOptions.find((option) => option.value === companyType)}
                            placeholder={companyTypeOptions[0].label}
                        />

                    </div>
                    <div className="input-container selectReact">
                        <label className="label">Year of Establishment <span style={{ color: 'red' }}>*</span></label>
                        <Select
                            className='select'
                            name='year'
                            options={yearOptions}
                            onChange={(selectedOption) => setYear(selectedOption.value)}
                            value={yearOptions.find((option) => option.value === year)}
                        />
                    </div>
                </div>
                <div className="flex detail-row">
                    <div className="input-container selectReact">
                        <label className="label" htmlFor='industry'>Industry <span style={{ color: 'red' }}>*</span></label>
                        <Select
                            className='select'
                            id='industry'
                            name='industry'
                            options={industryOptions}
                            onChange={(selectedOption) => setIndustry(selectedOption.value)}
                            value={industryOptions.find((option) => option.value === industry)}
                            placeholder={industryOptions[0].label}
                        />
                    </div>
                    <div className="input-container">
                        <label className="label">Billing Email  <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='billingEmail'
                            onChange={(e) => setBillingEmail(e.target.value)}
                            value={billingEmail}
                        />
                    </div>
                    <div className="input-container">
                        <label className="label">Company Contact Number <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='companyPhone'
                            onChange={(e) => setCompanyPhone(e.target.value)}
                            value={companyPhone}
                        />
                    </div>
                </div>
                <div className="flex detail-row " style={{ alignItems: "start" }}>
                    <div className='flex' style={{ flexDirection: "column", alignItems: "start" }}>
                        <div className="input-container">
                            <label className="label">Company Website </label>
                            <input
                                className='input-field'
                                type='text'
                                placeholder='Text'
                                name='companyWebsite'
                                onChange={(e) => setCompanyWebsite(e.target.value)}
                                value={companyWebsite}
                            />
                        </div>
                        <div className="input-container">
                            <label className="label">Quality Certification  <span style={{ color: 'red' }}>*</span></label>
                            <input
                                className='input-field'
                                type='text'
                                placeholder='Text'
                                name='qualityCertification'
                                onChange={(e) => setQualityCertification(e.target.value)}
                                value={qualityCertification}
                            />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className="input-container">
                            <label className="label">Logo</label>
                            <div className="profile-picture-container">
                                <input
                                    type="file"
                                    accept=".png,.jpg,.jpeg"
                                    onChange={handleLogoImageChange}
                                    id="logo-image-input"
                                    className="profile-picture-input"
                                />
                                <label
                                    htmlFor="logo-image-input"
                                    onDragOver={handleDragOver}
                                    onDrop={handleDroplogo}
                                    className="profile-picture-label"
                                >
                                    {logoImageURL !== null && typeof logoImageURL === "string" ?
                                        (<img
                                            src={logoImageURL}
                                            alt={logoImageName ? logoImageName : "logo"}
                                            className="profile-picture-preview"
                                        />
                                        ) : (
                                            <span className="profile-picture-placeholder flex">
                                                <img src={attach} alt="Attach" />
                                                <p>Or</p>
                                                <p>Drag & Drop image (Png, Jpeg) here</p>
                                            </span>
                                        )}
                                </label>
                            </div>
                            {logoImageName && (
                                <p className="file-name">{logoImageName}</p>
                            )}
                        </div>
                    </div>

                    <div className="input-container certificate" style={{ marginLeft: "11px", width: "207px" }}>
                        <label className="label">Company’s Profile / Certifications </label>
                        <div className="document-upload-container">
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                onChange={handleDocumentChange}
                                id="document-input"
                                className="document-upload-input"
                                name='documentFile'
                                multiple
                            />
                            <label
                                htmlFor="document-input"
                                onDragOver={handleDragOver}
                                onDrop={handleDropDocument}
                                className="profile-picture-label"
                            >
                                <span className="profile-picture-placeholder flex" style={{ flexDirection: "column", gap: "0px" }}>
                                    <CloudDownloadOutlinedIcon style={{ fill: "#0D4C7A" }} fontSize='large' />
                                    <p style={{ lineHeight: '25px', color: "#1A141F" }}>Drag&Drop File here <br /> <span>or</span>
                                        <span className='flex choose'>
                                            <AttachmentOutlinedIcon fontSize='small' style={{ transform: "rotate(135deg)", color: "#fff", marginRight: "5px" }} />
                                            Choose File</span>
                                        <span>Maximum upload size 2MB</span>
                                    </p>
                                </span>

                            </label>
                        </div>
                        {
                            [...documentFile].length > 0 ? (
                                [...documentFile].map((fileOrURL, index) => (
                                    <div className="document-selected" key={index}>
                                        <AttachmentOutlinedIcon fontSize="small" style={{ transform: "rotate(135deg)", color: "#4B3A5A", marginRight: "5px" }} />
                                        <p className='fieldValue'>{fileUploadError ? fileUploadError : typeof fileOrURL === 'string' ? documentFileNames[index] : fileOrURL.name}</p>
                                        <button className="remove-document" onClick={() => handleRemoveDocument(index)}> <CloseOutlinedIcon /></button>
                                    </div>
                                ))
                            ) : (
                                <p>{fileUploadError ? fileUploadError : 'No files'}</p>
                            )
                        }

                    </div>
                </div>
            </div>
            <hr className="divider" />

            <div className="company-address">
                <h3>Company Address</h3>
                <div className="flex detail-row" style={{ width: "100%", marginBottom: "15px" }}>
                    <div className="input-container select-react">
                        <label className="label" htmlFor='Country'>Country</label>
                        <Select
                            options={Country.getAllCountries()}
                            getOptionLabel={(options) => {
                                return options["name"];
                            }}
                            getOptionValue={(options) => {
                                return options["name"];
                            }}
                            value={selectedCountry}
                            onChange={(item) => {
                                setSelectedCountry(item);
                            }}
                        />
                    </div>
                    <div className="input-container select-react">
                        <label className="label" htmlFor='State'>State</label>
                        <Select
                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                            getOptionLabel={(options) => {
                                return options["name"];
                            }}
                            getOptionValue={(options) => {
                                return options["name"];
                            }}
                            value={selectedState}
                            onChange={(item) => {
                                setSelectedState(item);
                            }}
                        />
                    </div>
                    <div className="input-container select-react">
                        <label className="label" htmlFor='City'>City</label>
                        <Select
                            options={City.getCitiesOfState(
                                selectedState?.countryCode,
                                selectedState?.isoCode
                            )}
                            getOptionLabel={(options) => {
                                return options["name"];
                            }}
                            getOptionValue={(options) => {
                                return options["name"];
                            }}
                            value={selectedCity}
                            onChange={(item) => {
                                setSelectedCity(item);
                            }}
                        />
                    </div>
                </div>
                <div className="flex detail-row">
                    <div className="input-container double-size">
                        <label className="label">Registered Address  <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='registeredAddress'
                            onChange={(e) => setRegisteredAddress(e.target.value)}
                            value={registeredAddress}
                        />
                    </div>
                    <div className="input-container">
                        <label className="label">Postal Code <span style={{ color: 'red' }}>*</span></label>
                        <input
                            className='input-field'
                            type='text'
                            placeholder='Text'
                            name='postalCode'
                            onChange={(e) => setPostalCode(e.target.value)}
                            value={postalCode}
                        />
                    </div>

                </div>
            </div>
        </form>
    );
};

export default DetailsForm;
