import React, { useState } from 'react';
import m1 from '../../assets/m1.png';
import DualMessageModal from '../helper/DualMessageModal';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

const SupplierBookingCard = ({ bookingRequests, Tab }) => {
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [currentBooking, setCurrentBooking] = useState({})
    const closeAcceptModal = () => {
        setShowAcceptModal(false);
    };
    const closeDeclineModal = () => {
        setShowDeclineModal(false);
    };

    const formatDate = (date) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            weekday: 'short',
        };
        const formattedDate = new Date(date).toLocaleString('en-IN', options);

        const dayStyle = { fontSize: '10px', color: '#585858' };

        return (
            <div>
                {formattedDate.slice(4, -5)}{' '}
                <span style={dayStyle}>{formattedDate.slice(0, 3)}</span>{' '}
                <span style={dayStyle}>{formattedDate.slice(-4)}</span>
            </div>
        );
    };

    const formatTime = (time) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Kolkata',
        };
        return new Date(time).toLocaleTimeString('en-US', options);
    };

    const handleAcceptBooking = (booking) => {
        setCurrentBooking(booking);
        setShowAcceptModal(true);
    };

    const handleDeclineBooking = (booking) => {
        setCurrentBooking(booking);
        setShowDeclineModal(true);
    };

    const calculateNumberOfDays = (fromDate, toDate) => {
        const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const start = new Date(fromDate);
        const end = new Date(toDate);
        const timeDifference = Math.abs(end - start);
        const numberOfDays = Math.ceil(timeDifference / oneDay);
        return numberOfDays === 0 ? 1 : numberOfDays;
    }


    const calculateNumberOfHours = (duration, fromDate, toDate) => {
        const days = calculateNumberOfDays(fromDate, toDate);
        const Totalhours = days * duration;
        return Totalhours;
    }

    const calculateRent = (cost, duration, fromDate, toDate) => {
        const hours = calculateNumberOfHours(duration, fromDate, toDate);
        const TotalRent = hours * cost;
        return TotalRent;
    }

    return (
        <>
            {showAcceptModal && (
                <div style={{ position: 'fixed', left: '0px', zIndex: '1000', top: '0px' }}>
                    <DualMessageModal
                        showModal={showAcceptModal}
                        closeModal={closeAcceptModal}
                        iconName={<BsFillCheckCircleFill fill="#238A27" size={40} />}
                        message={'Accept Request'}
                        detail={'Do you really want to accept machine booking request ?'}
                        navigateto={'/supplier/Bookings'}
                        Booking={currentBooking}
                    />
                </div>
            )}
            {showDeclineModal && (
                <div style={{ position: 'fixed', left: '0px', zIndex: '1000', top: '0px' }}>
                    <DualMessageModal
                        showModal={showDeclineModal}
                        closeModal={closeDeclineModal}
                        iconName={<ErrorOutlinedIcon style={{ fill: '#FF6534', fontSize: '40px' }} />}
                        message={'Decline Request'}
                        detail={'Do you really want to decline machine request ?'}
                        navigateto={'/supplier/Bookings'}
                        Booking={currentBooking}
                    />
                </div>
            )}
            <div className="main-suplier-booking-card-container flex-wrap">
                {bookingRequests &&
                    bookingRequests.map((booking) => (
                        <div key={booking._id} className="supplier-booking-card">
                            <div className="supplier-booking-section-1">
                                <img
                                    className="supplier-booking-machine-picture"
                                    src={m1}
                                    alt="m1"
                                    style={{ width: '40px', height: '40px', borderRadius: '100%' }}
                                />
                                <div className="supplier-booking-machine-name">
                                    <p>{booking.machineType}</p>
                                    <p>{booking.machineName}</p>
                                </div>
                                {Tab === 'tab2' &&  <div style={{ marginLeft: '65px', fontSize:'20px' }}>
                                   {booking.bookingStatus === 'completed' && <BsFillCheckCircleFill fill="#238A27" />} 
                                   {booking.bookingStatus === 'rejected' && <CancelIcon style={{ fill: '#CD0000', fontSize: '25px' }} />}
                                </div>}
                            </div>

                            <div className="supplier-booking-section-2">
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Requested By</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{booking.buyerName}</p>
                                        </div>
                                    </div>
                                    <div >
                                        <div className="supplier-booking-prop">
                                            <p>Location</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{booking.buyerLocation}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Request Date</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatDate(booking.dateMade)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ width: '90%', opacity: '0.3' }} />

                            <div className="supplier-booking-section-2">
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>From Date</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatDate(booking.fromDate)}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>To Date</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatDate(booking.toDate)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>From Time</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatTime(booking.fromTime)}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>To Time</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{formatTime(booking.toTime)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="supplier-booking-prop-details">
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Duration</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{calculateNumberOfHours(booking.availability, booking.fromDate, booking.toDate)}Hrs
                                                <br /> <span style={{ fontSize: '11px' }}> ({booking.availability} hrs/ {calculateNumberOfDays(booking.fromDate, booking.toDate)}days)</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className="supplier-booking-prop">
                                            <p>Rental Cost</p>
                                        </div>
                                        <div className="supplier-booking-value">
                                            <p>{calculateRent(booking.rentalCost, booking.availability, booking.fromDate, booking.toDate)} /- Rs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ width: '90%', opacity: '0.3' }} />
                            {Tab === 'tab1' ?
                                <div className="supplier-booking-section-3">
                                    <button className="booking-btn" onClick={() => handleDeclineBooking(booking)}>
                                        Decline
                                    </button>
                                    <button className="booking-btn" onClick={() => handleAcceptBooking(booking)}>
                                        Accept-booking
                                    </button>
                                </div>
                                : <div></div>}
                        </div>
                    ))}
            </div>
        </>
    );
};

export default SupplierBookingCard;
