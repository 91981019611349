import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import paid from '../../assets/paymentDone.svg'

const PaymentComplete = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/buyer/buyerBooking", { state: { activeTab: 1 } });
    }, 1000);
    

    return () => clearTimeout(timer);
     // eslint-disable-next-line
  }, []);  

  return (
    <div className='flex payment-done' style={{ height: '100vh', flexDirection: 'column' }}>
      <img src={paid} alt={'paid'} />
      <p style={{ color: '#747474' }}>Your machine has been booked ! </p>
    </div>
  )
}

export default PaymentComplete
