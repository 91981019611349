import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RxCross1 } from 'react-icons/rx';


const OtpModal = ({ showModal, closeModal, email, password, firstName, companyName, phone, lastName, industry, source }) => {
    const navigate = useNavigate();

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState("");

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/verify', { otp, email, password, firstName, companyName, phone, lastName, industry, source }, {
                withCredentials: true,
            });
            const { message } = response.data;
            if (message === 'OTP verified') {
                closeModal();
                // Redirect user to the next page or perform desired actions
                navigate('/selectProfile');
            }
        } catch (error) {
            console.error(error);
            setOtpError('Invalid OTP, please try again.');
        }
    };

    return (
        <div className={showModal ? 'modal display-block' : 'modal display-none'}>
            <div className='modal-content'>
                <div onClick={closeModal} type='button' style={{ float: 'right' }} className='cancel-btn'>
                    <RxCross1 />
                </div>
                <div style={{ padding: '5%' }}>
                    <h3 style={{ textAlign: 'center' }}>OTP Verification</h3>
                    <p style={{ fontSize: '13px', textAlign: 'center', color: '#0D4C7A' }}>
                        We've sent a verification code to your<br />
                        email - {email} <br />
                        {/* Phone No - +91 {phone} */}
                    </p>
                    <form onSubmit={(e) => handleOtpSubmit(e, email)}>
                        <label className="label">Enter Verification Code</label>
                        <input
                            className='input-field'
                            type='text'
                            value={otp}
                            onChange={(event) => setOtp(event.target.value)}
                            placeholder='Enter OTP here'
                            style={{ width: '80%' }}
                            required
                        />
                        <div className='flex' >
                            <button type='submit' className='submit-btn'>
                                Confirm
                            </button>
                        </div>
                        {otpError && <p>{otpError}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OtpModal;
