import React from 'react'
import p1 from '../../assets/p1.png'
import p2 from '../../assets/p2.png'
import p3 from '../../assets/p3.png'
import './requestManufacturing.css'

const RequestManufacturing = () => {
    return (
        <div className='requestManufacturing'>
            <h3>Request for Manufacturing</h3>
            <h5>Tell us about your manufacturing needs!</h5>
            <div className="processes">
                <div className="process">
                    <div className='circular-div'>1</div>
                    <img src={p1} alt="avatar" style={{ width: "150px" }} />
                    <p>Send Request for <br /> Manufacturing</p>
                </div>
                <div className="process">
                    <div className='circular-div'>2</div>
                    <img src={p2} alt="avatar" style={{ width: "150px" }} />
                    <p>Get 3 Best <br />  Quotations</p>
                </div>
                <div className="process">
                    <div className='circular-div'>3</div>
                    <img src={p3} alt="avatar" style={{ width: "150px" }} />
                    <p>Accept Quotation <br /> & Get your product</p>
                </div>
            </div>
            <button>Upload Your Requirement</button>
        </div>
    )
}

export default RequestManufacturing
