import React, { useEffect, useState } from 'react';
import './BookMyMachineHome.css';
import axios from 'axios';
import Footer from '../components/layout/Footer';

const AboutUsPage = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        axios.get("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/loggedIn", { withCredentials: true })
            .then(res => {
                setLoggedIn(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.firstName.trim()) {
            errors.firstName = 'First Name is required';
        }
        if (!formData.lastName.trim()) {
            errors.lastName = 'Last Name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone Number is required';
        } else if (!isValidPhone(formData.phone)) {
            errors.phone = 'Invalid phone number';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            try {
                await axios.post("https://ocular-manufacturing-server-production-d57e.up.railway.app/auth/contact-submit-form", formData);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setErrors({});
            } catch (error) {
                console.error('Error submitting form:', error);
                alert('Error submitting form. Please try again later.');
            }

        } else {
            console.log('Form validation failed');
        }
    };


    return (
        <div className='home'>
            <div style={{ position: 'relative', width: '90%', height: '100%', top: '40px', left: '10%', bottom: '200px', marginBottom: '200px' }}>
                <div className="home-head-container" style={{ position: 'relative', left: '-10%', width: '110%' }}>
                    <h1 style={{ marginLeft: '10w', color: '#0D4C7A' }}>Revolutionizing Manufacturing with Innovative Digital Solutions</h1>
                </div>

                <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px' }}>
                    <h2>Introduction</h2>
                    <div style={{ width: '80%' }}>
                        <p>
                            At Ocular Manufacturing, we are on a mission to transform the manufacturing industry with cutting-edge digital solutions. Our vision is to be the leading provider of digital manufacturing services, empowering businesses of all sizes to thrive in today's dynamic and fast-paced manufacturing landscape.
                        </p>
                    </div>
                </div>

                <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px' }}>
                    <h2>Mission :- Streamlining Manufacturing, Empowering Growth</h2>
                    <div style={{ width: '80%' }}>
                        <p>
                            Our mission is to revolutionize the manufacturing industry by providing innovative digital solutions that streamline the manufacturing process, improve efficiency, and enhance the customer experience. We are committed to empowering manufacturers with the tools they need to compete and succeed in the ever-changing manufacturing landscape.
                            We take pride in delivering top-notch services, including on-demand manufacturing, contract manufacturing, machine booking, and industrial spare parts sales. Our unwavering commitment to quality and exceptional customer service sets us apart as a reliable partner to our clients.
                        </p>
                    </div>
                </div>


                <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px' }}>
                    <h2>Vision:- Leading the Digital Manufacturing Revolution</h2>
                    <div style={{ width: '80%' }}>
                        <p>
                            Our vision is to become the leading provider of digital manufacturing solutions in the industry. By harnessing the power of cutting-edge technologies like AI and Industry 4.0, we aim to create a smarter, more efficient, and more connected manufacturing ecosystem.
                            We envision a future where manufacturers around the world can optimize their operations, reduce costs, and increase productivity through our innovative solutions. As a dedicated partner, we are committed to continuously improving our services and expanding our offerings to meet the evolving needs of our clients.
                        </p>

                        <p>
                            <li>Cutting-edge digital solutions for manufacturing efficiency</li>
                            <li>Empowering manufacturers of all sizes with streamlined processes</li>
                            <li>Exceptional customer service and commitment to quality</li>
                            <li>Utilizing AI and Industry 4.0 technologies for smarter manufacturing</li>
                            <li>A trusted partner for businesses seeking long-term growth and success</li>
                        </p>

                        <p>
                            Discover the Ocular Manufacturing difference today and take your manufacturing operations to new heights. Contact us to explore our services, request a quote, or learn more about how we can help your business succeed.
                        </p>
                    </div>
                </div>

                <div className='bookMyMachine-home-subHeader' style={{ marginTop: '50px' }}>
                    <h2>Schedule A Demo</h2>
                    <p>Our friendly team would love to hear from you!</p>
                </div>

                <div className='bookMyMachine-home-subContainer' style={{ marginTop: '50px' }}>
                    <form onSubmit={handleSubmit} className='contactUs-form'>
                        <div className="contactUs-flex">
                            <div className='input-container'>
                                <label className="label" htmlFor="firstName">
                                    First Name<span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    className='input-field'
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.firstName && <p>{errors.firstName}</p>}
                            </div>
                            <div className='input-container'>
                                <label className="label" htmlFor="lastName">
                                    Last Name<span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    className='input-field'
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.lastName && <p>{errors.lastName}</p>}
                            </div>
                        </div>

                        <div className="contactUs-flex">
                            <div className='input-container'>
                                <label className="label" htmlFor="email">
                                    Email<span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    className='input-field'
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.email && <p>{errors.email}</p>}
                            </div>

                            <div className='input-container'>
                                <label className="label" htmlFor="phone">
                                    Phone Number<span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    className='input-field'
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    placeholder="Phone Number"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.phone && <p>{errors.phone}</p>}
                            </div>
                        </div>


                        <div className='input-container' style={{ width: '102%' }}>
                            <label className="label" htmlFor="message">Message</label>
                            <textarea
                                className='input-message input-field '
                                id="message"
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </div>

                        <button className='btn-fill' style={{ marginLeft: '0' }} type="submit">Submit</button>
                    </form>
                </div >

            </div>

            <Footer loggedIn={loggedIn} />
        </div>
    )
}

export default AboutUsPage
